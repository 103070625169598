import { Component, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef, HostListener } from '@angular/core'; // OnInit
import { ChartOptions, ChartDataSets, ChartType } from '../../../../node_modules/chart.js/dist/Chart'
import { CustomViewWidgetComponent } from '../custom-view-widget.component';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { Configuration } from './strip-chart-configuration.model';
import { PointCollection } from './point-collection.model';
import { DataOneShotService } from '../../services/data-one-shot.service';
import { BaseChartDirective } from 'ng2-charts';
import { FullWidthCustomViewWidgetComponent } from '../full-width-custom-view-widget/full-width-custom-view-widget.component';
import { interval } from 'rxjs';

/**
 * A Strip Chart can plot 2 different values with a left and (optional) right axis.
 */
@Component({
  selector: 'app-strip-chart',
  templateUrl: './strip-chart.component.html',
  styleUrls: ['./strip-chart.component.css'],
  host: { // If the host window resizes, call setWidth()
    '(window:resize)': 'setWidth()'
  },
})
export class StripChartComponent extends FullWidthCustomViewWidgetComponent implements AfterViewInit, OnDestroy
{
  // reference to the chart.js in the DOM so we can call update.
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  public scatterChartOptions: ChartOptions = {
    responsive: true,
    animation: {
      duration: 0,
    },
    scales: {
      xAxes: [{
        type: 'time',
        display: true,
        bounds: 'ticks',
        time: {
          round: "second",
        },
        gridLines: {
          display: true,

          color: "#555",
          drawBorder: true,
          drawOnChartArea: true,

        },
      }],
      yAxes: [],
    },
  };

  public defaultChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    scales: {
      xAxes: [{
        type: 'time',
        display: true,
        bounds: 'ticks',
        ticks: {
          maxTicksLimit: 11,
        },
        time: {
          round: "second",
        },
        gridLines: {
          display: true,

          color: "#555",
          drawBorder: true,
          drawOnChartArea: true,

        },
      }],
      yAxes: [],
    },
  };

  public scatterChartData: ChartDataSets[] = [];

  public scatterChartType: ChartType = 'scatter';

  private pointCollections: PointCollection[] = [];

  private myConfiguration: Configuration;

  ///////////////////////////////////////////////////////////////////////////
  // constructor
  //
  // The parameters need to be optional, otherwise we can't pass in the type
  // to the CustomViewPropertyComponent to use it in the factory.
  ///////////////////////////////////////////////////////////////////////////
  constructor(
    protected ds?: DataSubscriptionService,
    private oneshot?: DataOneShotService,
    protected cdRef?: ChangeDetectorRef,
  )
  {
    super(ds, cdRef);
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange()
  {
    this.checkHiddenDocument();
  }

  checkHiddenDocument()
  {
    // if the page became visible...
    if (!document.hidden)
    {
      // refetch the lines
      this.getChartLines();
    }

  }

  private oneSecondPeriod; // handle to the observable so we can stop it on destroy.
  /**
   * After the view has initialized, start a timer to udpate the charts. 
   */
  ngAfterViewInit()
  {
    // start 1 second timer to shift the plot.
    this.oneSecondPeriod = interval(1000).subscribe(() => { this.TimeShiftDate(); });
  }

  /**
   * Closing the component
   */
  ngOnDestroy()
  {
    super.ngOnDestroy();
    this.pointCollections.forEach(pc =>
    {
      pc.ngOnDestroy();
    })
    this.oneSecondPeriod.unsubscribe();
  }

  ///////////////////////////////////////////////////////////////////////////
  // TimeShiftDate
  //
  // Tell each line to shift its data.  Then redraw the chart.
  ///////////////////////////////////////////////////////////////////////////
  TimeShiftDate()
  {
    this.pointCollections.forEach((p) =>
    {
      p.TimeShiftDate();
    });

    this.chart.chart.update();
  }

  ///////////////////////////////////////////////////////////////////////////
  // GetType
  ///////////////////////////////////////////////////////////////////////////
  static GetType(): string { return "Strip Chart"; }

  ///////////////////////////////////////////////////////////////////////////
  // Configure
  //
  // Overrides the CustomViewWidgetComponent to configure the angular meter
  // specific parameters.  Then call the CustomViewWidgetComponent's Configure.
  ///////////////////////////////////////////////////////////////////////////
  Configure(obj: Configuration): CustomViewWidgetComponent
  {
    this.pointCollections.length = 0;

    super.Configure(obj);

    // remove the old configuration
    this.scatterChartData.length = 0;

    this.myConfiguration = obj;
    this.getChartLines();

    return this;
  }

  getChartLines()
  {
    let chartOptions = JSON.parse(JSON.stringify(this.defaultChartOptions));

    // remove any previous chart data sets.
    this.scatterChartData = [];

    // unsubscribe from all the lines
    this.pointCollections.forEach(pc =>
    {
      pc.ngOnDestroy();
    })

    // remove the lines
    this.pointCollections = [];

    this.myConfiguration.lines.forEach((d, n) =>
    {
      let lineCfg: ChartDataSets =
      {
        duration: 0, // no animation
        pointRadius: 0,
        showLine: true,
        fill: false,
        cubicInterpolationMode: "monotone", // the  default undershoots or overshoots.  This does not.
      };

      lineCfg.label = d.label;
      lineCfg.borderColor = lineCfg.backgroundColor = d.color;
      lineCfg.yAxisID = 'axis-' + n;

      // create the point collection that will get the points.
      let pc = new PointCollection(this.ds, this.oneshot, this.myConfiguration.timePeriod, d.unitId, d.channelType, d.channel);
      this.pointCollections.push(pc);
      // assign the point collection's data to the lineCfg's data.
      lineCfg.data = pc.data;

      if (d.unitId != null && +d.unitId > 0)
      {
        this.scatterChartData.push(lineCfg);
      }

      let axesCfg: any = {
        ticks: {
        },
        scaleLabel: {
          display: true,
        },
        gridLines: {
          display: true,
          drawBorder: true,
          drawOnChartArea: false,
        },
      };

      axesCfg.position = d.yAxisPosition;
      axesCfg.type = 'linear';
      axesCfg.id = 'axis-' + n;

      if (d.autoScaleYAxis != null)
      {
        if (!d.autoScaleYAxis)
        {
          // Bug 2967:
          // set the axis min to the minimum of the 2 values.
          axesCfg.ticks.min = Math.min(d.yAxisMin, d.yAxisMax);
          // set the axis max to the maximum of the 2 values.
          axesCfg.ticks.max = Math.max(d.yAxisMin, d.yAxisMax);
        }
      }

      axesCfg.ticks.fontColor = d.color;
      axesCfg.scaleLabel.fontColor = d.color;
      axesCfg.scaleLabel.labelString = d.labelString;
      axesCfg.gridLines.color = this.myConfiguration.gridLineColor;

      axesCfg.gridLines.drawOnChartArea = (n == 0);
      axesCfg.gridLines.display = true;

      // if (d.enable)
      if (d.unitId != null && +d.unitId > 0)
      {
        chartOptions.scales.yAxes.push(axesCfg);
      }
    });
    chartOptions.scales.xAxes[0].gridLines.color = this.myConfiguration.gridLineColor;
    chartOptions.scales.xAxes[0].ticks.fontColor = this.myConfiguration.gridLineColor;
    chartOptions.scales.xAxes[0].ticks.maxTicksLimit = this.myConfiguration.maxTicksLimit;

    chartOptions.legend = {};
    chartOptions.legend.labels = {};
    chartOptions.legend.labels.fontColor = this.myConfiguration.gridLineColor;


    this.scatterChartOptions = chartOptions;
  }
}
