import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpInterceptor, HttpHandler, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { RequestCache } from './request-cache';
import { share } from 'rxjs/operators'
import { tap } from 'rxjs/operators';

@Injectable()
export class CachingInterceptor implements HttpInterceptor
{
	// private cache: Map<string, any> = new Map<string, any>();

	constructor(
		private cache: RequestCache,
	) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
	{
		// https://medium.com/@vikeshm/data-caching-angular-http-interceptor-2d87f95e2340

		// if this is not a GET, don't cache
		if (req.method !== "GET")
		{
			return next.handle(req);
		}

		// check if this URI is cached.
		const lastResponse = this.cache.get(req.urlWithParams);
		if (lastResponse)
		{
			// if the last response is an observable, then return that.
			// if it is not an observable, then it is the response data, so return that.
			if (lastResponse instanceof Observable)
			{
				// console.log("cache found request: " + req.urlWithParams);
				return lastResponse;
			}
			else 
			{
				// console.log("cache found response: " + req.urlWithParams);
				return of(lastResponse.clone());
			}
		}

		// create an observable from the http request, but add a follow-up function to add the data to the cache.
		const requestHandle = next.handle(req).pipe(tap((stateEvent) =>//do((stateEvent) =>
		{
			if (stateEvent instanceof HttpResponse)
			{
				// console.log("cache updated to response: " + req.urlWithParams);

				// we got a response of the request.  Set the cache to the response instead of the Request.
				this.cache.set(req.urlWithParams, stateEvent.clone());
			}
		}), share());

		// console.log("cache set to request: " + req.urlWithParams);

		// add this observable to the cache
		this.cache.set(req.urlWithParams, requestHandle);

		return requestHandle;
	}

}