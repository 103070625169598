import { Injectable } from "@angular/core";
import { HttpRequest, HttpInterceptor, HttpEvent, HttpHandler, HttpErrorResponse } from "@angular/common/http";
import { AccountService } from "../services/account.service";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take, tap } from "rxjs/operators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor
{
	constructor(
		private accountService: AccountService,
	) { }

	// intercept 401 errors and handle them.
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
	{
		return next.handle(request).pipe(catchError(err =>
		{
			// unauthorized
			if (err instanceof HttpErrorResponse && err.status === 401 && this.accountService.isLoggedIn)
			{
				// console.log("unauthorized.");
				return this.handle401Error(request, next);
			}
			else
			{
				// get either the error message or the status text and rethrow the error.
				const error = err.error || err.statusText;
				return throwError(error);
			}
		}))
	}


	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	// https://dev-academy.com/angular-jwt/ 
	private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
	{
		// if we got a 401 and we are not refreshing the JWT...
		if (!this.isRefreshing)
		{
			// console.log("starting refresh cycle...");
			// mark that we are refreshing the JWT.
			this.isRefreshing = true;

			// toss a null into the refreshed token pipeline.  This is removed with the "filter(token => token != null)" below.
			this.refreshTokenSubject.next(null);

			return this.accountService.refreshToken().pipe(
				// switch to a new observable (https://www.learnrxjs.io/learn-rxjs/operators/transformation/switchmap)
				switchMap((token: any) =>
				{
					// console.log("ending refresh cycle...");
					// console.log("token: " + token.token);
					this.isRefreshing = false; // we got the refresh token.  this refresh cycle is done.
					this.refreshTokenSubject.next(token.token); // add in the JWT.  This is taken out with the "take(1)"
					return next.handle(this.addToken(request, token.token));
				}));

		}
		else // we started a token refresh cycle.  return the observable subject that is storing up requests
		{
			// console.log("buffering request: " + request.url);
			return this.refreshTokenSubject.pipe(
				// tap(() => console.log("buffering 1.")),
				filter(token => token != null), // filter out the null that was inserted above
				// tap(() => console.log("buffering 2.")),
				take(1),  // take the first good value and complete the stream
				// tap(() => console.log("buffering 3.")),
				switchMap(jwt =>
				{
					// console.log("remapped the request..." + jwt);

					// add the new jwt to the HTTP request that had a 401 response, but the refresh cycle had started.
					return next.handle(this.addToken(request, jwt));
				}));
		}
	}

	/**
	 * Add the Authorization token to the HTTP header.
	 * @param request 
	 * @param token 
	 * @returns 
	 */
	private addToken(request: HttpRequest<any>, token: string): HttpRequest<any>
	{
		// console.log("addToken to buffered request " + request.url);
		// console.log("Token: " + token);

		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`
			}
		});
	}

}