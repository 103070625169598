import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { CustomViewWidgetData, CustomViewWidgetComponent } from '../custom-view-widget.component';
import { FullWidthCustomViewWidgetComponent } from '../full-width-custom-view-widget/full-width-custom-view-widget.component';

export class Configuration extends CustomViewWidgetData
{
  label: string;
}

@Component({
  selector: 'app-label-full-width',
  templateUrl: './label-full-width.component.html',
  styleUrls: ['./label-full-width.component.css'],
  host: { // If the host window resizes, call setWidth()
    '(window:resize)': 'setWidth()'
  },
})
export class LabelFullWidthComponent extends FullWidthCustomViewWidgetComponent
{
  @ViewChild('label1', { static: false }) label1: ElementRef;
  @ViewChild('labelouter', { static: false }) labelouter: ElementRef;

  private label: string;
  private myTooltip = "";

  ///////////////////////////////////////////////////////////////////////////
  // constructor
  ///////////////////////////////////////////////////////////////////////////
  constructor(protected ds?: DataSubscriptionService,
    protected cdRef?: ChangeDetectorRef,
  ) 
  {
    super(ds, cdRef);
  }

  ///////////////////////////////////////////////////////////////////////////
  // GetType
  ///////////////////////////////////////////////////////////////////////////
  static GetType(): string { return "Label Full Width"; }

  ///////////////////////////////////////////////////////////////////////////
  // Configure
  ///////////////////////////////////////////////////////////////////////////
  Configure(obj: Configuration): CustomViewWidgetComponent
  {
    this.label = obj.label;
    setTimeout(() => this.myTooltip = this.TooltipText(this.labelouter, this.label1), 0);

    if (typeof obj.fontSize === "undefined")
    {
      obj.fontSize = 16;
    }
    this.fontSize = obj.fontSize;

    this.numOfLines = Math.floor((obj.height - 10) / this.fontSize);

    super.Configure(obj);

    return this;
  }

  ///////////////////////////////////////////////////////////////////////////
  // TooltipText
  ///////////////////////////////////////////////////////////////////////////
  private TooltipText(outer: ElementRef, inner: ElementRef): string
  {
    // at the beginning of the render, the element is not assigned yet.  Check for null to just return.
    if (inner == null)
    {
      return "";
    }

    // if the outer element's height is less than the inner element's height, then it is clipped.  Show the tooltip.
    if (outer.nativeElement.clientHeight < inner.nativeElement.clientHeight)
    {
      return this.label;
    }

    // the element is big enough to fit everything, there is no tooltip.  Return a blank label.
    return "";
  }

}
