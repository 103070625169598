import { Component, ChangeDetectorRef } from '@angular/core';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { TileSize } from '../../size-selector/size-selector.component';
import { FullWidthCustomViewWidgetComponent } from '../full-width-custom-view-widget/full-width-custom-view-widget.component';

@Component({
  selector: 'app-meter-status-command-full-row-header',
  templateUrl: './meter-status-command-full-row-header.component.html',
  styleUrls: ['./meter-status-command-full-row-header.component.css',
    '../meter-status-command-full-row/meter-status-command-full-row.component.css'],
  host: { // If the host window resizes, call setWidth()
    '(window:resize)': 'setWidth()'
  },
})
export class MeterStatusCommandFullRowHeaderComponent extends FullWidthCustomViewWidgetComponent 
{
  constructor(
    protected ds?: DataSubscriptionService,
    protected cdRef?: ChangeDetectorRef,
  )
  {
    super(ds, cdRef);
  }

  ///////////////////////////////////////////////////////////////
  // GetType
  ///////////////////////////////////////////////////////////////
  static GetType(): string { return "Full Row Header"; }

  ///////////////////////////////////////////////////////////////
  // GetSizes
  //
  // Get a list of the valid sizes for the tile. (no sizes)
  // Only full width.
  ///////////////////////////////////////////////////////////////
  GetSizes(): TileSize[]
  {
    return [];
  }
}
