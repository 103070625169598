import { Component, OnInit, Inject, ComponentFactoryResolver } from '@angular/core';
import { SitePropertyComponent } from '../../site-property/site-property.component';
import { ClearAlarmsWidgetComponent, Configuration } from '../clear-alarms-widget.component';
import { CommandButtonWidgetComponent } from '../../command-button-widget/command-button-widget.component';
import { TileSize, DoubleWide_SingleHeight, FourxWide_DoubleHeight, EightxWide_FourxHeight, SingleWide_HalfHeight } from '../../../size-selector/size-selector.component';

@Component({
  selector: 'app-clear-alarms-property',
  templateUrl: './clear-alarms-property.component.html',
  styleUrls: ['./clear-alarms-property.component.css']
})
export class ClearAlarmsPropertyComponent extends SitePropertyComponent<ClearAlarmsWidgetComponent>
{

  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(ClearAlarmsWidgetComponent, factoryResolver);

    let cfg = new Configuration();

    cfg.type = ClearAlarmsWidgetComponent.GetType();

    this.Configure(cfg);
  }

  /**
   * Return the valid sizes of this type of Tile
   */
  readonly sizes: TileSize[] =
    [
      SingleWide_HalfHeight,
      DoubleWide_SingleHeight,
      FourxWide_DoubleHeight,
      EightxWide_FourxHeight,
    ];

  /**
   * Return the index of the array of sizes returned from GetSizes() that is 
   * the default size.
   */
  GetDefaultSize(): number { return 1; }


  /**
   * a new size has been selected.
   * @param $event 
   */
  onNewSizeSelected($event: TileSize)
  {
    super.onNewSizeSelected($event);

    if (this.myConfiguration.height >= 310)
    {
      this.myConfiguration.fontSize = 65;
    }
    else if (this.myConfiguration.height >= 150)
    {
      this.myConfiguration.fontSize = 40;
    }
    else if (this.myConfiguration.height >= 70)
    {
      this.myConfiguration.fontSize = 20;
    }
    else
    {
      this.myConfiguration.fontSize = 8;
    }

    this.onChange();
  }
}
