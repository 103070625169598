import { Injectable } from '@angular/core';

/**
 * Get a unique, monotonically increasing number.
 * 
 * Usefull for radio buttons in a component that need to refer
 * to the same 'name', but another instance of the
 * component needs a different name.
 */
@Injectable({
  providedIn: 'root'
})
export class MonotonicService
{

  private value: number = 0;

  constructor() { }

  get(): number
  {
    this.value++;
    return this.value;
  }
}
