import { DataSubscriptionService } from "../../services/data-subscription.service";
import { Input, Component } from "@angular/core";
import { AccordianTabData, MatrixData } from "../../services/group-panel.service";
import { DataOneShotService } from "../../services/data-one-shot.service";
import { AlarmCounter } from "./alarm-counter";

@Component({
	selector: 'app-tab-alarm-counter',
	template: '',
})
export class TabAlarmCounter extends AlarmCounter
{
	@Input() data: AccordianTabData = new AccordianTabData();

	public constructor(
		protected ds: DataSubscriptionService,
		protected oneshot: DataOneShotService,
	)
	{
		super(ds, oneshot);
	}

	/**
	 * Recurse through all the tabs and matrix views to find all the aliases.
	 * @param aliases push the aliases to this array.
	 */
	protected GetAllAliases(aliases: number[])
	{
		this.GetAliasesFromAccordianTab(aliases, this.data);
	}

	/**
	 * Get the aliases for an accordian tab.
	 * @param aliases 
	 * @param tab 
	 */
	GetAliasesFromAccordianTab(aliases: number[], tab: AccordianTabData)
	{
		// for each tab, get its aliases
		tab.Pages.forEach((t) =>
		{
			this.GetAliasesFromAccordianTab(aliases, t);
		});

		// for each matrix, get its aliases
		tab.Groups.forEach((m) =>
		{
			this.GetAliasesFromMatrix(aliases, m);
		});
	}

	/**
	 * Get the aliases for a matrix.
	 * @param aliases 
	 * @param m 
	 */
	GetAliasesFromMatrix(aliases: number[], m: MatrixData)
	{
		aliases.push(+m.alias); // convert site string to number

		// if this has a set of tiles...
		if (m.view.widgets)
		{
			m.view.widgets.forEach((w) =>
			{
				if (w.site)
				{
					aliases.push(+w.site); // convert site string to number
				}
			});
		}
	}
}