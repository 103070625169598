import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatrixData } from '../services/group-panel.service';

@Component({
  selector: 'app-edit-site-view-modal',
  templateUrl: './edit-site-view-modal.component.html',
  styleUrls: ['./edit-site-view-modal.component.css']
})
export class EditSiteViewModalComponent implements OnInit
{

  @Input() inputNode: MatrixData;

  @Output() save = new EventEmitter<MatrixData>();
  @Output() close = new EventEmitter();

  newNode: MatrixData;

  constructor() { }

  ngOnInit()
  {
    // make a deep clone of the inputNode
    if (this.inputNode != null)
    {
      this.newNode = JSON.parse(JSON.stringify(this.inputNode));
    }
    else
    {
      this.newNode = new MatrixData();
    }
  }

  onClose(ok: boolean)
  {
    if (this.newNode != null)
    {
      // if we click the OK button...
      if (ok || confirm("Do you want to save?"))
      {
        if (this.newNode.name.length == 0)
        {
          alert("The name cannot be empty.");
          return;
        }

        this.save.emit(this.newNode);
      }
    }
    this.close.emit();
  }

  onAddSiteSelected(alias: number)
  {
    this.newNode.alias = alias;
  }

}
