import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'color'
})
export class ColorPipe implements PipeTransform
{
	///////////////////////////////////////////////////////////////////////////
	// colorArray
	//
	// copied from the ARC Plus Web Interface to convert a color number to RGB.
	///////////////////////////////////////////////////////////////////////////
	private colorArray: number[][] =
		[
			[0x00, 0x33, 0x66],
			[0x33, 0x66, 0x99],
			[0x33, 0x66, 0xCC],
			[0x00, 0x33, 0x99],
			[0x00, 0x00, 0x99],
			[0x00, 0x00, 0xCC],
			[0x00, 0x00, 0x66],
			[0x00, 0x66, 0x66],
			[0x00, 0x66, 0x99],
			[0x00, 0x99, 0xCC],
			[0x00, 0x66, 0xCC],
			[0x00, 0x33, 0xCC],
			[0x00, 0x00, 0xFF],
			[0x33, 0x33, 0xFF],
			[0x33, 0x33, 0x99],
			[0x66, 0x99, 0x99],
			[0x00, 0x99, 0x99],
			[0x33, 0xCC, 0xCC],
			[0x00, 0xCC, 0xFF],
			[0x00, 0x99, 0xFF],
			[0x00, 0x66, 0xFF],
			[0x33, 0x66, 0xFF],
			[0x33, 0x33, 0xCC],
			[0x66, 0x66, 0x99],
			[0x33, 0x99, 0x66],
			[0x00, 0xCC, 0x99],
			[0x00, 0xFF, 0xCC],
			[0x00, 0xFF, 0xFF],
			[0x33, 0xCC, 0xFF],
			[0x33, 0x99, 0xFF],
			[0x66, 0x99, 0xFF],
			[0x66, 0x66, 0xFF],
			[0x66, 0x00, 0xFF],
			[0x66, 0x00, 0xCC],
			[0x33, 0x99, 0x33],
			[0x00, 0xCC, 0x66],
			[0x00, 0xFF, 0x99],
			[0x66, 0xFF, 0xCC],
			[0x66, 0xFF, 0xFF],
			[0x66, 0xCC, 0xFF],
			[0x99, 0xCC, 0xFF],
			[0x99, 0x99, 0xFF],
			[0x99, 0x66, 0xFF],
			[0x99, 0x33, 0xFF],
			[0x99, 0x00, 0xFF],
			[0x00, 0x66, 0x00],
			[0x00, 0xCC, 0x00],
			[0x00, 0xFF, 0x00],
			[0x66, 0xFF, 0x99],
			[0x99, 0xFF, 0xCC],
			[0xCC, 0xFF, 0xFF],
			[0xCC, 0xCC, 0xFF],
			[0xCC, 0x99, 0xFF],
			[0xCC, 0x66, 0xFF],
			[0xCC, 0x33, 0xFF],
			[0xCC, 0x00, 0xFF],
			[0x99, 0x00, 0xCC],
			[0x00, 0x33, 0x00],
			[0x00, 0x99, 0x33],
			[0x33, 0xCC, 0x33],
			[0x66, 0xFF, 0x66],
			[0x99, 0xFF, 0x99],
			[0xCC, 0xFF, 0xCC],
			[0xFF, 0xFF, 0xFF],
			[0xFF, 0xCC, 0xFF],
			[0xFF, 0x99, 0xFF],
			[0xFF, 0x66, 0xFF],
			[0xFF, 0x00, 0xFF],
			[0xCC, 0x00, 0xCC],
			[0x66, 0x00, 0x66],
			[0x33, 0x66, 0x00],
			[0x00, 0x99, 0x00],
			[0x0E, 0xB3, 0x00], // Axia Green //[0x66, 0xFF, 0x33], // Lime Green
			[0x99, 0xFF, 0x66],
			[0xCC, 0xFF, 0x99],
			[0xFF, 0xFF, 0xCC],
			[0xFF, 0xCC, 0xCC],
			[0xFF, 0x99, 0xCC],
			[0xFF, 0x66, 0xCC],
			[0xFF, 0x33, 0xCC],
			[0xCC, 0x00, 0x99],
			[0x99, 0x33, 0x99],
			[0x33, 0x33, 0x00],
			[0x66, 0x99, 0x00],
			[0x99, 0xFF, 0x33],
			[0xCC, 0xFF, 0x66],
			[0xFF, 0xFF, 0x99],
			[0xFF, 0xCC, 0x99],
			[0xFF, 0x99, 0x99],
			[0xFF, 0x66, 0x99],
			[0xFF, 0x33, 0x99],
			[0xCC, 0x33, 0x99],
			[0x99, 0x00, 0x99],
			[0x66, 0x66, 0x33],
			[0x99, 0xCC, 0x00],
			[0xCC, 0xFF, 0x33],
			[0xFF, 0xFF, 0x66],
			[0xFF, 0xCC, 0x66],
			[0xFF, 0x99, 0x66],
			[0xFF, 0x66, 0x66],
			[0xFF, 0x00, 0x66],
			[0xCC, 0x66, 0x99],
			[0x99, 0x33, 0x66],
			[0x99, 0x99, 0x66],
			[0xCC, 0xCC, 0x00],
			[0xFF, 0xFF, 0x00],
			[0xFF, 0xCC, 0x00],
			[0xFF, 0x99, 0x33],
			[0xFF, 0x66, 0x00],
			[0xFF, 0x50, 0x50],
			[0xCC, 0x00, 0x66],
			[0x66, 0x00, 0x33],
			[0x99, 0x66, 0x33],
			[0xCC, 0x99, 0x00],
			[0xFF, 0x99, 0x00],
			[0xCC, 0x66, 0x00],
			[0xFF, 0x33, 0x00],
			[0xFF, 0x00, 0x00],
			[0xCC, 0x00, 0x00],
			[0x99, 0x00, 0x33],
			[0x66, 0x33, 0x00],
			[0x99, 0x66, 0x00],
			[0xCC, 0x33, 0x00],
			[0x99, 0x33, 0x00],
			[0x99, 0x00, 0x00],
			[0x80, 0x00, 0x00],
			[0x99, 0x33, 0x33],
			[0xFF, 0xFF, 0xFF],
			[0xCC, 0xCC, 0xCC],
			[0xC0, 0xC0, 0xC0],
			[0x99, 0x99, 0x99],
			[0x80, 0x80, 0x80],
			[0x66, 0x66, 0x66],
			[0x33, 0x33, 0x33],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00],
			[0x00, 0x00, 0x00]
		];

	///////////////////////////////////////////////////////////////////////////
	// transform
	//
	// Convert a color pallet number to "#RRGGBB"
	///////////////////////////////////////////////////////////////////////////
	transform(value: number, online: boolean): string 
	{
		if (online == null)
		{
			online = true;
		}

		if (value == null)
		{
			return "#808080";
		}
		if (value >= this.colorArray.length)
		{
			return "#808080";
		}

		// convert to hex string with leading 0.
		let red = ("00" + this.colorArray[value][0].toString(16)).substr(-2);
		let green = ("00" + this.colorArray[value][1].toString(16)).substr(-2);
		let blue = ("00" + this.colorArray[value][2].toString(16)).substr(-2);

		if (!online)
		{
			return "#404040";
		}

		return "#" + red + green + blue;
	}
}
