import { Component, Inject, ComponentFactoryResolver } from '@angular/core';
import { ImageWidgetComponent } from '../image-widget.component';
import { Configuration } from '../image-widget.component';
import { CustomViewPropertyComponent } from '../../custom-view-property.component';
import { TileSize, DoubleWide_SingleHeight, FourxWide_DoubleHeight, EightxWide_FourxHeight, DoubleWide_HalfHeight, FourxWide_HalfHeight, SingleWide_SingleHeight, DoubleWide_DoubleHeight, FourxWide_FourxHeight } from '../../../size-selector/size-selector.component';

@Component({
  selector: 'app-image-property',
  templateUrl: './image-property.component.html',
  styleUrls: ['./image-property.component.css']
})
export class ImagePropertyComponent extends CustomViewPropertyComponent<ImageWidgetComponent>
{

  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(ImageWidgetComponent, factoryResolver);

    let cfg = new Configuration();

    cfg.type = ImageWidgetComponent.GetType();
    cfg.imageSrc = "https://";

    this.Configure(cfg);
  }

  /**
     * Return the valid sizes of this type of Tile
     */
  readonly sizes: TileSize[] =
    [
      DoubleWide_HalfHeight,
      FourxWide_HalfHeight,
      SingleWide_SingleHeight,
      DoubleWide_SingleHeight,
      DoubleWide_DoubleHeight,
      FourxWide_DoubleHeight,
      FourxWide_FourxHeight,
      EightxWide_FourxHeight,
    ];


  /**
   * Return the index of the array of sizes returned from GetSizes() that is 
   * the default size.
   */
  GetDefaultSize(): number { return 4; }
}
