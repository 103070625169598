import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localDateTime'
})
export class LocalDateTimePipe implements PipeTransform
{

  transform(value: string, args?: any): string
  {

    var localDate = new Date(value);
    return localDate.toString();
  }

}
