import { Pipe } from "@angular/core";

@Pipe({
  name: 'secondsToTime'
})
export class SecondsToTimePipe
{
  times = {
    year: 31557600,
    month: 2629746,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1
  }

  transform(seconds: number, showZero?: boolean): string
  {
    let time_string: string = '';

    let timeOutput: any = {};

    // if seconds are < 0, show nothing
    if (seconds < 0)
    {
      return "";
    }
    // if seconds is 0 and we are not to show 0, return nothing
    if (seconds == 0 && !showZero)
    {
      return "";
    }

    for (var key in this.times)
    {
      timeOutput[key] = 0;

      if (Math.floor(seconds / this.times[key]) > 0)
      {
        timeOutput[key] = Math.floor(seconds / this.times[key]);

        seconds = seconds - this.times[key] * Math.floor(seconds / this.times[key]);
      }
    }
    time_string = "";
    if (timeOutput.day)
    {
      time_string += timeOutput.day + " days "
    }

    time_string += timeOutput.hour + ":" + this.pad(timeOutput.minute, 2) + ":" + this.pad(timeOutput.second, 2);

    return time_string;
  }

  pad(num: number, size: number): string
  {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
}