import { Component, Inject, ComponentFactoryResolver } from '@angular/core';
import { StatusLabelWidgetComponent } from '../status-label-widget.component';
import { StatusLabelConfiguration } from '../status-label-widget.component';
import { TileSize, DoubleWide_SingleHeight, FourxWide_DoubleHeight, EightxWide_FourxHeight, FourxWide_SingleHeight, DoubleWide_HalfHeight, SingleWide_HalfHeight, SingleWide_SingleHeight, DoubleWide_DoubleHeight } from '../../../size-selector/size-selector.component';
import { ChannelPropertyComponent } from '../../channel-property/channel-property.component';

@Component({
  selector: 'app-status-label-property',
  templateUrl: './status-label-property.component.html',
  styleUrls: ['./status-label-property.component.css']
})
export class StatusLabelPropertyComponent extends ChannelPropertyComponent<StatusLabelWidgetComponent>
{
  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(StatusLabelWidgetComponent, factoryResolver);

    let cfg = new StatusLabelConfiguration();

    cfg.type = StatusLabelWidgetComponent.GetType();

    this.Configure(cfg);
  }

  /**
   * Return the valid sizes of this type of Tile
   */
  readonly sizes: TileSize[] =
    [
      SingleWide_HalfHeight,
      SingleWide_SingleHeight,
      DoubleWide_HalfHeight,
      DoubleWide_SingleHeight,
      DoubleWide_DoubleHeight,
      FourxWide_SingleHeight,
      FourxWide_DoubleHeight,
      EightxWide_FourxHeight
    ];

  /**
   * Return the index of the array of sizes returned from GetSizes() that is 
   * the default size.
   */
  GetDefaultSize(): number { return 1; }

  /**
   * The Status Adaptor Changed.
   * @param $event object with type, site, and channel array.
   */
  onStatusAdaptorChange($event)
  {
    // forward the information that the channels changed.
    this.onSelectedChannels($event.channels);
  }
}
