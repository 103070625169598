import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef, OnChanges, AfterViewChecked } from '@angular/core';
import { DataOneShotService } from '../../services/data-one-shot.service';
import { CustomViewWidgetComponent } from '../custom-view-widget.component';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { SiteWidgetComponent, SiteConfiguration } from '../site-widget/site-widget.component';

enum ShowPage
{
  ChannelsPage,
  MacrosPage,
  AlarmsPage,
  EventsPage,
}

export class DefaultSiteConfiguration extends SiteConfiguration
{
  commandConfirm: boolean;
}

@Component({
  selector: 'app-default-site-widget',
  templateUrl: './default-site-widget.component.html',
  styleUrls: ['./default-site-widget.component.css'],
  host: {
    '(window:resize)': 'setWidth()'
  },
})
export class DefaultSiteWidgetComponent extends SiteWidgetComponent implements OnInit, OnChanges, AfterViewChecked
{
  // channel adaptor
  @Input() site: string; // TODO: alias?
  @Input() commandConfirm: boolean;

  private name: string;
  private currentBank: number = 0;
  private channels: number[] = [];
  private showPage: ShowPage = ShowPage.ChannelsPage;
  ShowPage = ShowPage; // variable so the template can see the enum

  readonly ChannelsPerBank = 16;
  private MaxChannels: number = 0;

  @ViewChild('fullWidthBox') fullWidthBox: ElementRef;

  constructor(protected oneshot?: DataOneShotService,
    protected ds?: DataSubscriptionService,
    private cdRef?: ChangeDetectorRef,
  ) 
  {
    super(ds, oneshot);
  }

  ngOnInit()
  {
    //this.setWidth();
    this.GetData();
    this.StartSubscription();
  }

  ngOnChanges()
  {
    // this.setWidth();
    this.GetData();
    this.StartSubscription();
  }

  ngAfterViewChecked()
  {
    // this.setWidth();
  }

  static GetType(): string { return "Default Site"; }

  /**
   * 
   */
  setWidth()
  {
    // this allows the view to redraw so fullWidthBox is assigned.
    this.cdRef.detectChanges();

    if (this.fullWidthBox != null)
    {
      let width = this.fullWidthBox.nativeElement.getBoundingClientRect().width;

      // the first element is 70 pixels.  Then 10 pixel gap, then another element of 70.
      // so the series of widths is 70, 150, 230, 310, 390...
      // the series is 70 + n*80
      // 
      let n = Math.floor((width - 70) / 80);
      this.width = 70 + n * 80;
    }
  }

  ////////////////////////////////////////////////
  // LoadChannels
  //
  // Load all the channels for the current bank.
  ////////////////////////////////////////////////
  LoadChannels()
  {
    let arr = [];

    let startChannel = this.ChannelsPerBank * this.currentBank;
    let endingChannel = startChannel + this.ChannelsPerBank;

    for (let i = startChannel; i < endingChannel; i++)
    {
      arr.push(i);
    }

    this.channels = arr;
  }

  ////////////////////////////////////////////////
  // onPrev
  ////////////////////////////////////////////////
  onPrev()
  {
    this.currentBank -= 1;
    this.LoadChannels();
  }

  ////////////////////////////////////////////////
  // onNext
  ////////////////////////////////////////////////
  onNext()
  {
    this.currentBank += 1;
    this.LoadChannels();
  }

  ///////////////////////////////////////////////////////////////////////////
  // Configure
  //
  // Overrides the CustomViewWidgetComponent to configure the default site
  // specific parameters.
  ///////////////////////////////////////////////////////////////////////////
  Configure(obj: DefaultSiteConfiguration): CustomViewWidgetComponent
  {
    super.Configure(obj);

    if (obj.commandConfirm)
    {
      this.commandConfirm = true;
    }

    this.GetData();

    return this;
  }

  GetData()
  {
    const channelTypes = ["Meter", "Status", "Command"];
    //console.log("Default Site: site: " + this.site);

    // get the maximum number of channels
    for (let t of channelTypes)
    {
      this.oneshot.getNumberOfChannels(this.site, t).subscribe((maxChannels) =>
      {
        if (maxChannels > this.MaxChannels)
        {
          this.MaxChannels = maxChannels;
        }
      },
        (error) =>
        {
          console.log("Error getting number of channels: " + error);
        });
    }

    this.oneshot.getPidParameters(this.site, "Site", 0,
      [
        "SiteName",
      ])
      .subscribe((res) =>
      {
        for (let p of res)
        {
          //console.log(p.parameter + ": " + p.value);
          switch (p.parameter)
          {
            case "SiteName":
              this.name = p.value;
              break;
          }
        }
      });
  }

  ///////////////////////////////////////////////////////////////////////////
  // Subscribe
  //
  // Call the datasubscription service to add the subscriptions.
  ///////////////////////////////////////////////////////////////////////////
  protected Subscribe()
  {
    super.Subscribe();
    this.LoadChannels();
  }

  onChannelButton()
  {
    this.showPage = ShowPage.ChannelsPage;
  }

  onMacroButton()
  {
    this.showPage = ShowPage.MacrosPage;
  }

  onAlarmButton()
  {
    this.showPage = ShowPage.AlarmsPage;
  }

  onEventsButton()
  {
    this.showPage = ShowPage.EventsPage;
  }
}
