import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';



export class EventData
{
  dateTime: string;
  siteId: number;
  siteName: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class EventService
{

  ///////////////////////////////////////////////////////////////////////////
  // constructor
  ///////////////////////////////////////////////////////////////////////////
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string)
  { }


  ///////////////////////////////////////////////////////////////////////////
  // getPage
  //
  // Get 1 page of alarms.
  ///////////////////////////////////////////////////////////////////////////
  getSite(siteId: number): Observable<EventData[]>
  {
    let httpParams = new HttpParams();

    httpParams = httpParams.append("site", "" + siteId);

    return this.http.get<EventData[]>(this.baseUrl + 'api/Event/GetSite', { params: httpParams });
  }

  ///////////////////////////////////////////////////////////////////////////
  // getEvents
  //
  // Get the events for a list of sites
  ///////////////////////////////////////////////////////////////////////////
  getEvents(sites?: string[] | string): Observable<EventData[]>
  {
    let httpParams = new HttpParams();

    let siteList = [];

    // create the array of sites
    if (sites instanceof Array)
    {
      siteList.push(...sites);
    }
    else
    {
      siteList.push(sites);
    }

    // create the parameters for each site
    siteList.forEach((value, i) =>
    {
      httpParams = httpParams.append("unit[" + i + "]", value);
    });

    return this.http.get<EventData[]>(this.baseUrl + 'api/Event/Units', { params: httpParams });
  }
}
