import { Injectable } from '@angular/core';
import { HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

const maxAge = 1000; // 1 seconds

class CacheData
{
	startTime: number;
	obs: any;
}

@Injectable()
export class RequestCache
{
	cache: Map<string, CacheData> = new Map<string, CacheData>();

	get(key: string): any
	{
		const url = key;
		const cached = this.cache.get(url);

		// if the url is not in the cache, return null
		if (!cached)
		{
			// console.log("Missed: " + url);
			return null;
		}

		// if this item has expired...
		if (Date.now() > cached.startTime + maxAge)
		{
			// console.log("Cache Timeout: " + url);
			this.cache.delete(key);
			return null;
		}

		// console.log("Cache hit: " + url);

		return cached.obs;
	}

	/**
	 * Add a key-value with a timeout to the cache.
	 * @param key 
	 * @param value 
	 */
	set(key: string, value: any)
	{
		const data = new CacheData();
		data.startTime = Date.now();
		data.obs = value;

		// add the {url, data} to the cache
		this.cache.set(key, data);
	}

}