import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export class ClipboardObj
{
  type: string;
  obj: any;
}

@Injectable({
  providedIn: 'root'
})
export class ClipboardService
{
  // TODO: extend this to have a <key, value> so multiple components can use this to copy/paste
  private myData = new ClipboardObj();

  private typeSubject = new BehaviorSubject<string>("none");

  constructor() { }

  /////////////////////////////////////////////////////////////
  // Save
  //
  // Save a copy of the data
  /////////////////////////////////////////////////////////////
  public Save(type: string, obj: any)
  {
    this.myData.type = type;
    this.myData.obj = this.clone(obj);

    this.typeSubject.next(type);
  }

  /**
   * Return the types of objects in the clipboard.
   * @returns 
   */
  public getClipboardType(): Observable<string>
  {
    return this.typeSubject.asObservable();
  }

  /////////////////////////////////////////////////////////////
  // Restore
  //
  // Return a copy of the data
  /////////////////////////////////////////////////////////////
  public Restore(): ClipboardObj
  {
    return this.clone(this.myData);
  }

  /////////////////////////////////////////////////////////////
  // clone
  //
  // Make a copy of an object
  /////////////////////////////////////////////////////////////
  private clone(obj: any): any
  {
    return JSON.parse(JSON.stringify(obj));
  }

}
