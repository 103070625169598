import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalAlarmDialogComponent } from '../modal-alarm-dialog/modal-alarm-dialog.component';
import { ModalEventDialogComponent } from '../modal-event-dialog/modal-event-dialog.component';
import { ModalMacroDialogComponent } from '../modal-macro-dialog/modal-macro-dialog.component';
import $ from 'jquery';
import { DataSubscriptionService } from '../services/data-subscription.service';
import { RealTimeUpdate } from '../custom-view-widget/custom-view-widget.component';

@Component({
  selector: 'app-alarm-event-macro-button',
  templateUrl: './alarm-event-macro-button.component.html',
  styleUrls: ['./alarm-event-macro-button.component.css']
})
export class AlarmEventMacroButtonComponent implements OnInit, OnChanges, RealTimeUpdate, OnDestroy
{

  @Input() sites: string[];

  private showAlarms: boolean;
  private showEvents: boolean;
  private showMacros: boolean;

  warningAlarmCounts: Map<string, number> = new Map<string, number>(); // a map of an alias/severity to a count of alarms
  criticalAlarmCounts: Map<string, number> = new Map<string, number>(); // a map of an alias/severity to a count of alarms

  showWarningAlarm: boolean;
  showCriticalAlarm: boolean;

  /**
   * 
   * @param dialog 
   */
  constructor(
    private dialog: MatDialog,
    private ds: DataSubscriptionService,
  ) { }

  ngOnInit()
  {
    if (this.sites == null) return;

    // this.sites.forEach(v => { console.log("Sites: " + v); });
  }

  /**
   * Life cycle hook when the inputs change.
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges): void
  {
    // remove any previous subscriptions
    this.ds.Remove(this);

    // start fresh on the counts.
    this.warningAlarmCounts = new Map<string, number>();
    this.criticalAlarmCounts = new Map<string, number>();

    this.sites.forEach((site) =>
    {
      this.ds.Add(this, [
        {
          "site": site,
          "block": "AlarmStats",
          "channel": 0,
          "pid": "UnclearedWarningAlarms"
        },
        {
          "site": site,
          "block": "AlarmStats",
          "channel": 0,
          "pid": "UnclearedCriticalAlarms"
        },
      ]);
    });
  }

  ngOnDestroy(): void
  {
    this.ds.Remove(this);
  }

  /**
   * A Real-time update is received.
   * @param site 
   * @param pid 
   * @param channel 
   * @param value 
   */
  Update(site: string, pid: string, channel: number, value: string)
  {
    switch (pid)
    {
      case "UnclearedWarningAlarms":
        this.warningAlarmCounts.set(site, +value);
        break;
      case "UnclearedCriticalAlarms":
        this.criticalAlarmCounts.set(site, +value);
        break;
    }

    this.showWarningAlarm = false;
    this.warningAlarmCounts.forEach((i) =>
    {
      if (i > 0)
      {
        this.showWarningAlarm = true;
      }
    });
    this.showCriticalAlarm = false;
    this.criticalAlarmCounts.forEach((i) =>
    {
      if (i > 0)
      {
        this.showCriticalAlarm = true;
      }
    });
  }

  /**
   * Click the Alarms button.
   */
  onAlarms(event: Event)
  {
    this.showAlarms = !this.showAlarms;
    this.showEvents = false;
    this.showMacros = false;

    event.stopPropagation();
  }
  /**
   * Click the Events button.
   */
  onEvents(event: Event)
  {
    this.showAlarms = false;
    this.showEvents = !this.showEvents;
    this.showMacros = false;

    event.stopPropagation();
  }

  /**
   * Click the Macros button.
   */
  onMacros(event: Event)
  {
    this.showAlarms = false;
    this.showEvents = false;
    this.showMacros = !this.showMacros;

    event.stopPropagation();
  }

  private showButtonBar: boolean;
  toggleCollapse(event: Event)
  {
    event.stopPropagation();

    this.showButtonBar = !this.showButtonBar
    return false;
  }

  /**
   * The Alarm Table fired a close event.  Close the alarm table.
   */
  onCloseAlarms()
  {
    this.showAlarms = false;
  }

  /**
   * The Events Table fired a close event.  Close the event table.
   */
  onCloseEvents()
  {
    this.showEvents = false;
  }

  /**
   * The Macros Table fired a close event.  Close the macro table.
   */
  onCloseMacros()
  {
    this.showMacros = false;
  }

}
