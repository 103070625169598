import { Component, OnInit, Input } from '@angular/core';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { SitesService, SiteData } from '../services/sites.service';
import { SiteSelectorDialogComponent, SiteCheckbox } from '../site-selector-dialog/site-selector-dialog.component';
import { Subject } from 'rxjs';

/**
 * This component looks like a link that shows:
 * 13 of 87 Units Selected
 * 
 * Clicking on the text will open a dialog to select
 * a set of units to display.
 * 
 * By default, show all the units.
 */
@Component({
  selector: 'app-site-selection-xof-y',
  templateUrl: './site-selection-xof-y.component.html',
  styleUrls: ['./site-selection-xof-y.component.css']
})
export class SiteSelectionXofYComponent implements OnInit
{
  // pass in the Subject so that this selector can feed values out
  @Input() selectedSites: Subject<SiteData[]> = new Subject<SiteData[]>();

  private allSites: SiteData[];
  private checkedSites: SiteData[];

  // Variables bound to the template
  private numOfSelected: number = 0;
  private numOfTotal: number = 0;

  //////////////////////////////////////////////////////////////
  // constructor
  //////////////////////////////////////////////////////////////
  constructor(
    private dialog: MatDialog,
    private siteService: SitesService
  ) { }

  //////////////////////////////////////////////////////////////
  // ngOnInit
  //
  // Initialize the all sites and checked sites.
  //////////////////////////////////////////////////////////////
  ngOnInit()
  {
    // get all the sites...
    this.siteService.getSites().subscribe(sites =>
    {
      this.allSites = sites;
      this.checkedSites = sites;

      this.UpdateSelectedSites();
    });
  }

  //////////////////////////////////////////////////////////////
  // UpdateSelectedSites
  //
  // Gather up the selected sites and feed it into the selectedSites
  // Subject
  //////////////////////////////////////////////////////////////
  UpdateSelectedSites()
  {
    this.numOfSelected = this.checkedSites.length;
    this.numOfTotal = this.allSites.length;

    this.selectedSites.next(this.checkedSites);
  }

  //////////////////////////////////////////////////////////////
  // onClick
  //
  // The user clicked the link.  Open the dialog.
  //////////////////////////////////////////////////////////////
  onClick()
  {
    const dialogRef = this.dialog.open(SiteSelectorDialogComponent, {
      width: '100%',
      data: { allSites: this.allSites, checkedSites: this.checkedSites }
    });

    dialogRef.afterClosed().subscribe(selection =>
    {
      // if we clicked the OK button...
      if (selection)
      {
        this.checkedSites = [];

        selection.forEach(element =>
        {
          if (element.checked)
          {
            this.checkedSites.push(element.data);
          }
        });

        this.UpdateSelectedSites();
      }
    });
  }

}
