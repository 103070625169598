import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { GroupPanelService, AccordianTabData } from '../services/group-panel.service';

@Component({
  selector: 'app-global-tab-section',
  templateUrl: './global-tab-section.component.html',
  styleUrls: ['./global-tab-section.component.css']
})
export class GlobalTabSectionComponent implements OnInit
{
  private groupPanelData: AccordianTabData[];
  private allowEdit: boolean = false;
  private globalPanelDataChanged = new Subject<AccordianTabData[]>();

  constructor(
    private groupPanelService: GroupPanelService,
  )
  {
    // listen for changes to the global tabs...
    this.globalPanelDataChanged.subscribe(() => 
    {
      // send the changes to the server to be saved.
      this.groupPanelService.updateGlobal(this.groupPanelData).subscribe();
    });

  }

  ngOnInit()
  {
    this.groupPanelService.getGlobal().subscribe((data) =>
    {
      this.groupPanelData = data;
    });

    this.groupPanelService.getGlobalWritable().subscribe((data) =>
    {
      this.allowEdit = data;
    });

    // make sure the web server can write to the files.
    this.groupPanelService.canWriteUserFile().subscribe((data) =>
    {
      // if it cannot...
      if (!data)
      {
        alert("The web server account does not have sufficient privileges to write to files.  Please check with your network administrator.");
      }
    });

  }

}
