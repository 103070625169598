import { Component, ComponentFactoryResolver, Inject } from '@angular/core';
import { IframeWidgetComponent, Configuration } from '../iframe-widget.component';
import { CustomViewPropertyComponent } from '../../custom-view-property.component';
import { TileSize, DoubleWide_SingleHeight, FourxWide_DoubleHeight, DoubleWide_HalfHeight, FourxWide_HalfHeight, SingleWide_SingleHeight, DoubleWide_DoubleHeight, FourxWide_FourxHeight, EightxWide_FourxHeight, FullWidth_DoubleHeight, FullWidth_FourxHeight, FullWidth_EightxHeight } from '../../../size-selector/size-selector.component';

@Component({
  selector: 'app-iframe-property',
  templateUrl: './iframe-property.component.html',
  styleUrls: ['./iframe-property.component.css']
})
export class IframePropertyComponent extends CustomViewPropertyComponent<IframeWidgetComponent>
{
  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(IframeWidgetComponent, factoryResolver);

    let cfg = new Configuration();

    cfg.type = IframeWidgetComponent.GetType();

    this.Configure(cfg);
  }

  readonly sizes: TileSize[] =
    [
      DoubleWide_HalfHeight,
      FourxWide_HalfHeight,
      SingleWide_SingleHeight,
      DoubleWide_SingleHeight,
      DoubleWide_DoubleHeight,
      FourxWide_DoubleHeight,
      FourxWide_FourxHeight,
      EightxWide_FourxHeight,
      // FullWidth_DoubleHeight,
      // FullWidth_FourxHeight,
      // FullWidth_EightxHeight,
    ];

  GetDefaultSize(): number { return 1; }
}
