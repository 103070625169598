import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-alarm-dialog',
  templateUrl: './modal-alarm-dialog.component.html',
  styleUrls: ['./modal-alarm-dialog.component.css']
})
export class ModalAlarmDialogComponent implements OnInit
{

  constructor(@Inject(MAT_DIALOG_DATA) public data: string[]) { }

  ngOnInit()
  {
  }

}
