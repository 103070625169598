import { Injectable } from '@angular/core';
import { Alert, AlertType } from '../models/Alert';
import { Subject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlertService
{
  private subject = new Subject<Alert>();
  private defautId = 'default';

  onAlert(id = this.defautId): Observable<Alert>
  {
    return this.subject.asObservable().pipe(filter((x) => x && x.id === id));
  }

  success(message: string, options?: any)
  {
    this.alert(new Alert({ ...options, type: AlertType.Success, message }));
  }

  error(message: string, options?: any)
  {
    this.alert(new Alert({ ...options, type: AlertType.Error, message }));
  }

  info(message: string, options?: any)
  {
    this.alert(new Alert({ ...options, type: AlertType.Info, message }));
  }

  warn(message: string, options?: any)
  {
    this.alert(new Alert({ ...options, type: AlertType.Warning, message }));
  }

  alert(alert: Alert)
  {
    alert.id = alert.id || this.defautId;
    this.subject.next(alert);
  }

  clear(id = this.defautId)
  {
    // sending an alert with no message clears the alerts
    this.subject.next(new Alert({ id }));
  }
}
