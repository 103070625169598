import { Component, OnInit } from '@angular/core';
import { EditModeService } from '../services/edit-mode.service';
import { SitesService } from '../services/sites.service';

@Component({
  selector: 'app-all-sites-tab',
  templateUrl: './all-sites-tab.component.html',
  styleUrls: ['./all-sites-tab.component.css',
    '../appStyles.css',
    '../contextMenu.css']
})
export class AllSitesTabComponent implements OnInit
{
  private edit = false;
  private commandConfirm = true;
  private expanded: boolean = false;

  constructor(
    private sitesService: SitesService,
    private editService: EditModeService,
  ) { }

  ngOnInit(): void
  {
    this.editService.get().subscribe(b => 
    {
      this.edit = b;
    });

    this.sitesService.getAllSiteCommandConfirm().subscribe((data) =>
    {
      this.commandConfirm = data.commandConfirm;
    });

  }

  onCommandConfirmChanged()
  {
    this.sitesService.setAllSiteCommandConfirm(this.commandConfirm).subscribe();
  }

  clickExpandCollapse()
  {
    this.expanded = !this.expanded;
  }

}
