import { Point } from './point.model';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { DataOneShotService, PidUpdate } from '../../services/data-one-shot.service';
import { RealTimeUpdate } from '../custom-view-widget.component';
import { Observable } from 'rxjs';
//import 'rxjs/add/observable/interval';
import { ScaledValuePipe } from '../../pipes/scale-value.pipe';
import { ViewChild, OnDestroy } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';

/////////////////////////////////////////////////////////////////////
// PointCollection
//
// A collection of points.
/////////////////////////////////////////////////////////////////////
// TODO: Add Angular decorator.
export class PointCollection implements RealTimeUpdate, OnDestroy
{
	private pointCollection: Point[] = []; // new date are at the head (shift, unshift).  Old dates are at the tail (push, pop)
	private historyPage: number = 0;
	private block: string = "";
	private rtParam: string = "";
	private decimal: number = 0;
	private units: string = "";
	private scaledValuePipe = new ScaledValuePipe();
	private newestPoint: number = null;
	private doneLoading: boolean = false;;

	///////////////////////////////////////////////////////////////////////////////
	// data (get)
	//
	// Get a reference to the array of points to plot.
	///////////////////////////////////////////////////////////////////////////////
	get data(): Point[]
	{
		return this.pointCollection;
	}

	///////////////////////////////////////////////////////////////////////////////
	// constructor
	///////////////////////////////////////////////////////////////////////////////
	constructor(
		private ds: DataSubscriptionService,
		private oneshot: DataOneShotService,
		private timePeriodSec: number, // number of seconds
		private site: string,
		private type: string,
		private channel: number,
	)
	{
		// set the PID block and parameter based on the channel type.
		this.block = (type == "Meter") ? "Analog" : "Status";
		this.rtParam = (type == "Meter") ? "AnalogScaled" : "StatusStatus";

		if (type == "Meter")
		{
			this.GetMeterPids();
		}
		else
		{
			this.GetStatusPids();
		}
	}

	///////////////////////////////////////////////////////////////////////////////
	// GetStatusPids
	//
	// Get any additional PIDs for the status plot and then get the history page.
	///////////////////////////////////////////////////////////////////////////////
	GetStatusPids()
	{
		this.GetHistoryPage();
	}

	///////////////////////////////////////////////////////////////////////////////
	// GetMeterPids
	//
	// Get any additional PIDs for the meter plot and then get the history page.
	///////////////////////////////////////////////////////////////////////////////
	GetMeterPids()
	{
		this.oneshot.getPidParameters(this.site, this.block, this.channel, ["AnalogDecimalPoint", "AnalogUnitsLabel"])
			.subscribe((data: PidUpdate[]) =>
			{
				data.forEach((d) =>
				{
					switch (d.parameter)
					{
						case "AnalogDecimalPoint":
							this.decimal = +d.value;
							break;
						case "AnalogUnitsLabel":
							this.units = d.value;
							break;
					}
				});

				this.GetHistoryPage();
			});
	}

	///////////////////////////////////////////////////////////////////////////////
	// GetHistoryPage
	///////////////////////////////////////////////////////////////////////////////
	GetHistoryPage()
	{

		if (this.site == null) return;

		let now = new Date();
		let oldestTime = new Date(now.getTime() - this.timePeriodSec * 1000);

		// get the history of this plot
		this.oneshot.getHistoryRange(this.site, this.type, this.channel, this.timePeriodSec).subscribe(points =>
		{
			//console.log("Number of Points: " + points.length);
			if (points.length > 0)
			{
				// loop through the points.
				points.forEach((dp) =>
				{
					let p = new Point();
					p.x = new Date(dp.x * 1000);
					p.y = dp.y;

					// console.log("Point: " + p.x + ", " + p.y);

					//let xDate = new Date(p.x * 1000);

					// if this is the first point...
					if (this.pointCollection.length == 0)
					{
						// push it onto the tail
						this.pointCollection.push(p);
					}
					// if the times are different
					else if (p.x.getTime() / 1000 < this.pointCollection[this.pointCollection.length - 1].x.getTime() / 1000)
					{
						let lastPoint = this.pointCollection[this.pointCollection.length - 1];

						// if the new point is not near the last point in time, add another point just before the new point with the same value as the last point.
						// If we don't, then it looks like we had a long period of gradual change, when in reality the value was steady and then jumped.
						if (lastPoint.x.getTime() / 1000 - p.x.getTime() / 1000 > 2) // if the difference is more than 2 seconds...
						{
							let transitionPoint = new Point({ x: new Date(lastPoint.x.getTime() - 1000), y: p.y });
							this.pointCollection.push(transitionPoint);
						}

						// push it onto the tail
						this.pointCollection.push(p);

						// console.log("time:" + p.x + ", value: " + p.y);
					}
				});

				// // if we didn't read any data that is too old...
				// if (this.pointCollection[this.pointCollection.length - 1].x.getTime() > oldestTime.getTime())
				// {
				// 	// get the next page.
				// 	this.historyPage++;
				// 	this.GetHistoryPage();
				// 	return;
				// }
			}

			this.RemoveOldItems(oldestTime);

			// if there are no points...
			if (this.pointCollection.length == 0)
			{
				// add a null point at the extreme history
				let newP = new Point({ x: oldestTime, y: null });
				this.pointCollection.push(newP);
			}

			// duplicate the newest point to Now
			this.DuplicateNewestPoint(now);

			// duplicate the oldest point to Now - TimePeriod
			this.DuplicateOldestPoint(oldestTime);

			// console.log("Subscribe");
			this.newestPoint = this.pointCollection[0].y;

			// subscribe to the real-time updates
			this.ds.Add(this, [
				{
					"site": "" + this.site,
					"block": this.block,
					"channel": this.channel,
					"pid": this.rtParam
				},
			]);

			this.doneLoading = true;
		});
	}

	///////////////////////////////////////////////////////////////////////////
	// ParseHistory
	//
	// break the data into an array of Points.
	// The data is in the format:
	// Time 0x31 Value 0x30 Time 0x31 Value 0x30 Time 0x31 Value 0x30 Time 0x31 Value 
	///////////////////////////////////////////////////////////////////////////
	ParseHistory(data: string): Point[]
	{
		let points = [];

		// first break by 0x1e
		let records = data.split("\x1e");
		records.forEach(function (rec)
		{
			// now break by 0x1f
			let parts = rec.split("\x1f");
			if (parts.length == 2)
			{
				let p = new Point();
				p.x = new Date(+parts[0] * 1000); // convert to milliseconds
				p.y = +parts[1];
				points.push(p);

				//console.log("Point " + p.x + ": " + p.y);
			}
		});

		return points;
	}

	/**
	 * Save this data until we time shift and then plot it as the newest value.
	 * @param site 
	 * @param pid 
	 * @param channel 
	 * @param value 
	 */
	Update(site: string, pid: string, channel: number, value: string)
	{
		// console.log("Point Update" + value);

		let now = new Date();
		let scaledValue = +this.scaledValuePipe.transform(+value, this.decimal);
		this.newestPoint = scaledValue; // remember the newest point for when the 1 second timer shifts the data
	}

	/**
	 * Called when destroying the object.
	 */
	ngOnDestroy(): void
	{
		this.ds.Remove(this);
	}

	/**
	 * Called each second from the StripChartComponent.  Shift the data down.  Remove the old data.  Shift in the newest value.
	 */
	TimeShiftDate() 
	{
		//console.log("TimeShiftDate");
		if (!this.doneLoading) 
		{
			// console.log("loading...");
			return;
		}

		let now = new Date();

		// remove all the 'too old' items from the tail
		let oldestTime = new Date(now.getTime() - this.timePeriodSec * 1000);
		this.RemoveOldItems(oldestTime);

		// push a new item on as {now, prevValue}
		this.pointCollection.unshift(new Point({ x: now, y: this.newestPoint }));

		this.DuplicateOldestPoint(oldestTime);

		// console.log("Newest Time: " + now);
	}

	/**
	 * Remove items that are too old.
	 * @param oldestTime the limit of the chart on the left-hand side.
	 */
	RemoveOldItems(oldestTime: Date)
	{
		// let oldestTime = new Date(now.getTime() - this.timePeriod * 1000);
		while (this.pointCollection.length > 1 &&
			this.pointCollection[this.pointCollection.length - 1].x.getTime() < oldestTime.getTime())
		{
			this.pointCollection.pop();
		}
	}

	///////////////////////////////////////////////////////////////////////////
	// DuplicateNewestPoint
	///////////////////////////////////////////////////////////////////////////
	DuplicateNewestPoint(now: Date)
	{
		let item = this.pointCollection[0];
		let newP = new Point({ x: now, y: item.y });
		this.pointCollection.unshift(newP);
	}

	///////////////////////////////////////////////////////////////////////////
	// DuplicateOldestPoint
	///////////////////////////////////////////////////////////////////////////
	DuplicateOldestPoint(oldestTime: Date)
	{
		// console.log("Oldest Time: " + oldestTime);

		let item = this.pointCollection[this.pointCollection.length - 1];
		let newP = new Point({ x: oldestTime, y: item.y });

		// only add a point if it has a different time than the last point.
		if (item.x.getTime() / 1000 != newP.x.getTime() / 1000)
		{
			this.pointCollection.push(newP);
		}
	}

}
