import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alarmType'
})
export class AlarmTypePipe implements PipeTransform
{
  ///////////////////////////////////////////////////////////////////////////
  // transform
  //
  // Convert an Alarm Type value to a friendly string.
  ///////////////////////////////////////////////////////////////////////////
  transform(value: string): string
  {
    switch (value)
    {
      default: return "Unknown";
      case "0": return "High Limit";
      case "1": return "Low Limit";
      case "2": return "Status";
      case "3": return "System";
      case "4": return "User";
    }
  }
}
