import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { CustomViewWidgetComponent, CustomViewWidgetData } from '../custom-view-widget.component';

export class Configuration extends CustomViewWidgetData
{
  label: string;
  url: string;
}

@Component({
  selector: 'app-url-widget',
  templateUrl: './url-widget.component.html',
  styleUrls: ['./url-widget.component.css']
})
export class UrlWidgetComponent extends CustomViewWidgetComponent implements OnInit
{
  private label: string;
  private url: string;
  private newWindow: boolean
  private myTooltip: string;

  @ViewChild('label1', { static: false }) labelElementRef: ElementRef;

  constructor(
    protected ds?: DataSubscriptionService
  )
  {
    super()
  }
  static GetType(): string { return "URL"; }

  Configure(obj: Configuration): CustomViewWidgetComponent
  {
    super.Configure(obj);

    this.label = obj.label;
    this.url = obj.url;

    this.UpdateToolTips()

    return this;
  }

  ngOnInit()
  {
  }


  ///////////////////////////////////////////////////////////////////////////
  // UpdateToolTips
  ///////////////////////////////////////////////////////////////////////////
  UpdateToolTips()
  {
    setTimeout(() =>
    {
      if (this.label != null)
      {
        this.myTooltip = this.TooltipText(this.labelElementRef);
      }
    }, 0);
  }

  TooltipTextLabel1(): string
  {
    return this.TooltipText(this.labelElementRef);
  }

  TooltipText(el: ElementRef): string
  {
    // at the beginning of the render, the element is not assigned yet.  Check for null to just return.
    if (el == null)
    {
      return "";
    }
    // if the width of the item is less than the scroll ing width, it has an ellipsis.  Return the label.
    if (el.nativeElement.offsetWidth < el.nativeElement.scrollWidth)
    {
      return this.label;
    }

    // the element is wide enough to fit everything, there is no tooltip.  Return a blank label.
    return "";
  }
}
