import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationStart } from '@angular/router';
import { Alert } from '../models/Alert';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit, OnDestroy
{
  @Input() id = 'default';

  alerts: Alert[] = [];
  alertSubscription: Subscription;
  routeSubscription: Subscription;

  constructor(
    private router: Router,
    private alertService: AlertService
  ) { }

  ngOnInit()
  {
    this.alertSubscription = this.alertService.onAlert(this.id).subscribe(alert =>
    {
      // clear alerts when an empty alert is received
      if (!alert.message)
      {
        // reduce the alerts to only the ones with keepAfterRouteChange.
        this.alerts = this.alerts.filter(x => x.keepAfterRouteChange);

        // remove the keepAfterRouteChange field.
        this.alerts.forEach(x => delete x.keepAfterRouteChange);
        return;
      }

      // add this alert.
      this.alerts.push(alert);

      if (alert.autoClose)
      {
        // remove this alert after 3 seconds.
        setTimeout(() => this.removeAlert(alert), 3000);
      }
    });
    this.routeSubscription = this.router.events.subscribe(event =>
    {
      if (event instanceof NavigationStart)
      {
        this.alertService.clear(this.id);
      }
    });
  }

  ngOnDestroy()
  {
    this.alertSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  removeAlert(alert: Alert)
  {
    if (!this.alerts.includes(alert)) return;

    this.alerts = this.alerts.filter(x => x !== alert);
  }
}
