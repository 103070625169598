import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User } from '../models/User';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountService
{
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  public isLoggedIn: boolean;

  constructor(
    private router: Router,
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
  )
  {
    this.userSubject = new BehaviorSubject<User>(null);
    this.user = this.userSubject.asObservable();

    this.user.subscribe((user) =>
    {
      this.isLoggedIn = (user && user.username) ? true : false;
    });
  }

  public get userValue(): User
  {
    return this.userSubject.value;
  }

  login(username, password): Observable<User>
  {
    return this.http.post<User>(this.baseUrl + "api/LogIn/Login", { username, password })
      .pipe(map(user =>
      {
        //console.log("new JWT assigned.");
        // also send it to the subject so all observers see that we are logged in.
        this.storeJwtToken(user);
        // don't modify the user we received.
        return user;
      }));
  }

  logout(): Observable<boolean>
  {
    return this.http.post<boolean>(this.baseUrl + "api/LogIn/LogOut", {})
      .pipe(tap(() =>
      {
        this.userSubject.next(null);
      }));
  }

  refreshToken()
  {
    //console.log("requesting new refreshToken...");
    return this.http.post<any>(this.baseUrl + "api/LogIn/Refresh", {}).pipe(tap((user: User) =>
    {
      //console.log("refreshToken done.");
      this.storeJwtToken(user);
    }),
      catchError(err =>
      {
        // unauthorized
        if (err === "Unauthorized") //instanceof HttpErrorResponse && err.status === 401)
        {
          // console.log("unauthorized.");
          this.userSubject.next(null);
          this.router.navigate(['/login']);
        }
        else
        {
          // get either the error message or the status text and rethrow the error.
          const error = err.error || err.statusText;
          return throwError(error);
        }
      }));
  }

  private storeJwtToken(user: User)
  {
    this.userSubject.next(user);
  }
}

//https://jasonwatmore.com/post/2020/04/28/angular-9-user-registration-and-login-example-tutorial#account-login-component-html
