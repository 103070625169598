import { Component, OnInit } from '@angular/core';
import { ShowNavBarService } from '../services/show-nav-bar.service';
import { DataSubscriptionService } from '../services/data-subscription.service';

/**
 * Unused
 */
@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
  styleUrls: ['./quick-view.component.css']
})
export class QuickViewComponent implements OnInit
{
  view: any = {};
  myData =
    {
      "widgets": [
        {
          "type": "Status Label",
          "backgroundColor": "lightgray",
          "width": 150,
          "height": 70,
          "condition": 0,
          "fontSize": 16,
          "site": "65",
          "channel": 110
        },
        {
          "type": "Status Label",
          "backgroundColor": "lightgray",
          "width": 150,
          "height": 70,
          "condition": 0,
          "fontSize": 16,
          "site": "65",
          "channel": 111
        },
        {
          "type": "Status Label",
          "backgroundColor": "lightgray",
          "width": 150,
          "height": 70,
          "condition": 0,
          "fontSize": 16,
          "site": "65",
          "channel": 112
        },
        {
          "type": "Status Label",
          "backgroundColor": "lightgray",
          "width": 150,
          "height": 70,
          "condition": 0,
          "fontSize": 16,
          "site": "65",
          "channel": 113
        },
        {
          "type": "Status Label",
          "backgroundColor": "lightgray",
          "width": 150,
          "height": 70,
          "fontSize": 16,
          "condition": 0,
          "site": "65",
          "channel": 114
        },
        {
          "type": "Status Label",
          "backgroundColor": "lightgray",
          "width": 150,
          "height": 70,
          "fontSize": 16,
          "condition": 0,
          "site": "65",
          "channel": 115
        }
      ],
      "name": "Quick View",
      "backgroundColor": "#404145",
      "maxheight": ""
    };

  constructor(private showNavbarService: ShowNavBarService,
    private ds: DataSubscriptionService) 
  {
    showNavbarService.get().next(false);
  }

  ngOnInit()
  {
    // need to wait until the web socket has a chance to connect before we subscribe.
    this.ds.WebSocketEvents.subscribe((str) =>
    {
      switch (str)
      {
        case "unconnected":
          break;

        case "opened":
        case "error":
          this.view = this.myData;
          break;
      }
    });
  }

}
