import { Component, Inject, ComponentFactoryResolver } from '@angular/core';
import { MeterStatusCommandFullRowComponent, Configuration } from '../meter-status-command-full-row.component';
import { ChannelPropertyComponent } from '../../channel-property/channel-property.component';

@Component({
  selector: 'app-meter-status-command-full-row-property',
  templateUrl: './meter-status-command-full-row-property.component.html',
  styleUrls: ['./meter-status-command-full-row-property.component.css']
})
export class MeterStatusCommandFullRowPropertyComponent extends ChannelPropertyComponent<MeterStatusCommandFullRowComponent>
{
  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(MeterStatusCommandFullRowComponent, factoryResolver);

    let cfg = new Configuration();

    cfg.type = MeterStatusCommandFullRowComponent.GetType();
    cfg.fullWidth = true;

    this.Configure(cfg);
  }

}
