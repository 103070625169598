import { Component, OnInit, Inject, ComponentFactoryResolver } from '@angular/core';
import { CustomViewPropertyComponent } from '../../custom-view-property.component';
import { LabelWidgetComponent } from '../label-widget.component';
import { Configuration } from '../label-widget.component';
import { TileSize, DoubleWide_HalfHeight, FourxWide_HalfHeight, SingleWide_SingleHeight, DoubleWide_SingleHeight, DoubleWide_DoubleHeight, FourxWide_DoubleHeight, FourxWide_FourxHeight, EightxWide_FourxHeight } from '../../../size-selector/size-selector.component';

@Component({
  selector: 'app-label-property',
  templateUrl: './label-property.component.html',
  styleUrls: ['./label-property.component.css']
})
export class LabelPropertyComponent extends CustomViewPropertyComponent<LabelWidgetComponent>
{

  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(LabelWidgetComponent, factoryResolver);

    let cfg = new Configuration();

    cfg.type = LabelWidgetComponent.GetType();

    this.Configure(cfg);
  }

  /**
     * Return the valid sizes of this type of Tile
     */
  readonly sizes: TileSize[] =
    [
      DoubleWide_HalfHeight,
      SingleWide_SingleHeight,
      DoubleWide_SingleHeight,
      DoubleWide_DoubleHeight,
      FourxWide_DoubleHeight
    ];


  /**
   * Return the index of the array of sizes returned from GetSizes() that is 
   * the default size.
   */
  GetDefaultSize(): number { return 4; }
}
