import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export class Username
{
  username: string;
}

@Injectable({
  providedIn: 'root'
})
export class UsernameService
{
  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private http: HttpClient)
  { }

  public get(): Observable<Username>
  {
    return this.http.get<Username>(this.baseUrl + 'api/Pages/GetUser');
  }
}
