import { Component, OnInit, Input, HostBinding, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { RealTimeUpdate } from '../custom-view-widget/custom-view-widget.component';
import { DataSubscriptionService } from '../services/data-subscription.service';
import { AccordianTabData, MatrixData } from '../services/group-panel.service';
import { DataOneShotService } from '../services/data-one-shot.service';
import { EditModeService } from '../services/edit-mode.service';
import { TabAlarmCounter } from '../custom-view-widget/group-summary/tab-alarm-counter';

@Component({
  selector: 'app-tab-summary',
  templateUrl: './tab-summary.component.html',
  styleUrls: ['./tab-summary.component.css']
})
export class TabSummaryComponent extends TabAlarmCounter implements OnInit
{
  @Input() clickable: boolean = true;
  @Input() nameEdit = false;

  @Output() nameChange = new EventEmitter<string>();
  @Output() changeDraggable = new EventEmitter();

  private edit: boolean;

  @HostBinding('class.FullWidthHost') showAccordion: boolean = false;

  constructor(protected ds: DataSubscriptionService,
    protected oneshot: DataOneShotService,
    private editModeService: EditModeService,
  ) 
  {
    super(ds, oneshot);
  }

  ///////////////////////////////////////////////////////////////////////////
  // ngOnInit
  ///////////////////////////////////////////////////////////////////////////
  ngOnInit() 
  {
    this.editModeService.get().subscribe((data) =>
    {
      this.edit = data;
    });
  }

  ///////////////////////////////////////////////////////////////////////////
  // onNameChange
  //
  // When the name is edited, signal the parent to save the new name.
  ///////////////////////////////////////////////////////////////////////////
  onNameChange()
  {
    if (this.data == null) return;

    this.nameChange.emit(this.data.name);
  }

  onNameFocus()
  {
    // console.log("focus");
    this.changeDraggable.emit(false);
  }
  onNameBlur()
  {
    // console.log("blur");
    this.changeDraggable.emit(true);
  }

  onNameClick(event)
  {
    event.stopPropagation();
    event.preventDefault()
    return false;
  }
}
