import { Component, OnInit, OnChanges, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';
import { SitesService } from '../services/sites.service';
import { OptionDef } from '../OptionDef';

@Component({
  selector: 'app-site-selector',
  templateUrl: './site-selector.component.html',
  styleUrls: ['./site-selector.component.css']
})
export class SiteSelectorComponent implements OnInit, OnChanges
{
  @Input() currentSite: number;
  @Output() newSiteSelected = new EventEmitter<number>();
  @Output() newSiteName = new EventEmitter<string>();

  private siteOptions: OptionDef[] = [];

  constructor(private siteService: SitesService)
  {
    this.currentSite = -1;
  }

  ngOnInit()
  {
    this.siteService.getSites().subscribe((sites) =>
    {
      this.siteOptions = [];

      sites.forEach((data, i) =>
      {
        this.siteOptions.push(new OptionDef("" + data.alias, data.name));
      });

    });
  }

  ngOnChanges(changes: SimpleChanges): void
  {
  }

  onChange(newValue: number, name: string)
  {
    this.currentSite = newValue;
    this.newSiteSelected.emit(newValue);

    // find the label and emit it.
    this.siteOptions.forEach((ele) => 
    {
      if (+ele.value == newValue)
      {
        this.newSiteName.emit(ele.label);
      }
    });
  }
}
