import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { CommandData, CommandService } from '../../../services/command.service';
import { DataOneShotService } from '../../../services/data-one-shot.service';
import { DataSubscriptionService } from '../../../services/data-subscription.service';
import { ChannelHelper } from '../../../services/status-condition-types.service';
import { RealTimeUpdate } from '../../custom-view-widget.component';
import { SiteWidgetComponent } from '../../site-widget/site-widget.component';

@Component({
  selector: 'app-command-button-horizontal',
  templateUrl: './command-button-horizontal.component.html',
  styleUrls: ['./command-button-horizontal.component.css']
})
export class CommandButtonHorizontalComponent extends SiteWidgetComponent implements OnChanges, RealTimeUpdate, OnDestroy
{
  @Input() online: boolean;
  @Input() site: string;
  @Input() channel: number;
  @Input() confirmation: boolean;
  @Input() preventClicks: boolean;
  @Input() siteName: string;
  @Input() backgroundColor: string;

  private outputRaiseLabel: string;
  private outputLowerLabel: string;
  private outputRaiseColor: number = (62 - 15); // green
  private outputLowerColor: number = (132 - 15); // red
  private outputRelayState: number;
  public outputOnline: boolean;
  private outputPaired: boolean = true;
  private outputLatched: boolean = false;
  private outputVisible: boolean = false;

  constructor(
    protected oneshot?: DataOneShotService,
    protected ds?: DataSubscriptionService,
    private cs?: CommandService,
  )
  {
    super(ds, oneshot);
  }

  ngOnChanges()
  {
    this.GetData();
    this.Subscribe();
    this.CalculateBackgroundColor();
  }

  ngOnDestroy()
  {
    this.ds.Remove(this);
  }

  GetData()
  {
    this.oneshot.getPidParameter(this.site, "Output", this.channel, "OutputLcdRaiseLabel").subscribe(p => 
    {
      if (p.value != null)
      {
        this.outputRaiseLabel = p.value.trim();
      }
    });

    this.oneshot.getPidParameter(this.site, "Output", this.channel, "OutputLcdLowerLabel").subscribe(p => 
    {
      if (p.value != null)
      {
        this.outputLowerLabel = p.value.trim();
      }
    });

    this.oneshot.getPidParameter(this.site, "Output", this.channel, "OutputLcdRaiseBacklight").subscribe(p => 
    {
      if (p.value != null)
      {
        this.outputRaiseColor = +p.value;
      }
    });
    this.oneshot.getPidParameter(this.site, "Output", this.channel, "OutputLcdLowerBacklight").subscribe(p => 
    {
      if (p.value != null)
      {
        this.outputLowerColor = +p.value;
      }
    });

    this.oneshot.getPidParameter(this.site, "Output", this.channel, "OutputPaired").subscribe(p => 
    {
      if (p.value != null)
      {
        this.outputPaired = !!+p.value;
      }
    });

    this.oneshot.getPidParameter(this.site, "Output", this.channel, "OutputLatched").subscribe(p => 
    {
      if (p.value != null)
      {
        this.outputLatched = !!+p.value;
      }
    });

    this.oneshot.getPidParameter(this.site, "Output", this.channel, "OutputVisible").subscribe(p => 
    {
      if (p.value != null)
      {
        this.outputVisible = !!+p.value;
      }
    });
  }

  Subscribe()
  {
    // remove the previous subscriptions.
    this.ds.Remove(this);

    this.ds.Add(this, [
      {
        "site": this.site,
        "block": "Output",
        "channel": this.channel,
        "pid": "OutputRelayState"
      },
      {
        "site": this.site,
        "block": "Output",
        "channel": this.channel,
        "pid": "OutputState"
      },
    ]);
  }

  ///////////////////////////////////////////////////////////////////////////
  // Update
  //
  // A real-time update was received.  Update the meter or status value.
  ///////////////////////////////////////////////////////////////////////////
  Update(site: string, pid: string, channel: number, value: string): void
  {
    switch (pid)
    {
      case "OutputState":
        this.outputOnline = ChannelHelper.convertChannelStateToOnline(value);
        break;
      case "OutputRelayState":
        this.outputRelayState = +value;
        break;
    }

  }

  ///////////////////////////////////////////////////////////////////////////
  // onRaise
  ///////////////////////////////////////////////////////////////////////////
  onRaise() { this.sendCommand(true); }

  ///////////////////////////////////////////////////////////////////////////
  // onLower
  ///////////////////////////////////////////////////////////////////////////
  onLower() { this.sendCommand(false); }

  ///////////////////////////////////////////////////////////////////////////
  // sendCommand
  //
  // If the preventClicks is set (Edit Page), then don't do command.
  // If the confirmation is set, then ask the user.
  // Send the command to the web server.
  ///////////////////////////////////////////////////////////////////////////
  sendCommand(b: boolean)
  {
    let cmd = new CommandData();

    cmd.site = this.site;
    cmd.channel = this.channel;
    cmd.raiseLower = b;

    if (!this.preventClicks)
    {
      let str = ((b) ? this.outputRaiseLabel : this.outputLowerLabel);
      str = str.replace(/\0/g, '').trim(); // remove any NULL characters from the string.

      // if we are not confirming the command, do it
      // or if we did confirm the command, do it.
      if (!this.confirmation || confirm(str + "?"))
      {
        this.cs.command(cmd).subscribe();
      }
    }
  }

  get commandChannelOfflineMsg(): string
  {
    return this.calcCommandChannelOfflineMsg(this.outputRaiseLabel, this.outputLowerLabel, this.channel);
  }
}
