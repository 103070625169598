import { CustomViewWidgetComponent } from "./custom-view-widget/custom-view-widget.component";

export class OptionDef
{
	value: string;
	label: string;
	// component: CustomViewWidgetComponent;

	constructor(value: string, label: string)
	// component: CustomViewWidgetComponent)
	{
		this.value = value;
		this.label = label;
		// this.component = component;
	}
}