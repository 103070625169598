import { ChannelType } from "./status-condition-types.service";

/**
 * Container for the type, site, and channel of a status channel adaptor in the Widget Component.
 * Able to generated the One Shot PIDs, subscription pid, and static data for a status channel.
 */
export class StatusChannelAdaptor
{
	public condition: number;
	public channel: number;
	public site: string;

	constructor(configuration: any)
	{
		this.condition = configuration?.condition;
		this.channel = configuration?.channel;
		this.site = configuration?.site;
	}

}
