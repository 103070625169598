import { Component, OnInit, Input, Output, ViewChild, ViewChildren, OnChanges, EventEmitter, OnDestroy, SimpleChange, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { AlarmData, AlarmService } from '../services/alarm.service';
import { DataSubscriptionService, ArcPlusPid } from '../services/data-subscription.service';
import { RealTimeUpdate } from '../custom-view-widget/custom-view-widget.component';
import { ModalSiteLinkComponent } from '../modal-site-link/modal-site-link.component';
import { MaterialModule } from '../modules/material.module';

@Component({
  selector: 'app-alarm-table',
  templateUrl: './alarm-table.component.html',
  styleUrls: ['./alarm-table.component.css']
})
export class AlarmTableComponent implements OnInit, RealTimeUpdate, OnChanges, OnDestroy
{
  @Input() sites: string[] = [];
  @Input() pageSize: string[];

  @Output() close = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<AlarmData>;

  private dataSource: MatTableDataSource<AlarmData>;
  private columnsToDisplay: string[] = [
    "siteName", "startTime", "duration", "severity", "type", "message", "clearedTime", "clearAlarm"
  ];
  private loading: boolean = true;

  constructor(private alarmService: AlarmService,
    private dataSubcription: DataSubscriptionService) { }

  ngOnInit()
  {
  }

  ngOnChanges(changes: SimpleChanges)
  {
    this.UpdateSites();
  }
  UpdateSites()
  {
    if (this.sites == null || this.sites.length == 0)
      return;

    this.loading = true;

    // get the alarms for this site
    this.alarmService.getAlarms(this.sites).subscribe((d) =>
    {
      this.loading = false;

      // for each element in the array, calculate the other alarm fields.
      d.map((val) =>
      {
        this.calculateAlarmFields(val);
      })

      this.dataSource = new MatTableDataSource<AlarmData>(d);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      if (this.sites)
      {
        this.sites.forEach((site) =>
        {
          this.dataSubcription.Add(this, [
            {
              "site": site,
              "block": "Alarm",
              "channel": 0,
              "pid": "UpdateTime"
            },
          ]);
        });
      }
    });
  }

  ngOnDestroy()
  {
    this.dataSubcription.Remove(this);
  }

  calculateAlarmFields(val: AlarmData)
  {
    // calculate the duration property
    val.duration = (new Date(val.inactiveTime).valueOf() - new Date(val.startTime).valueOf()) / 1000;

    // if it is less than 0 seconds or the inactive time is after the year 2100, make it -1.
    // this alarm is not inactive
    if (val.duration < 0 || new Date(val.inactiveTime).getFullYear() > 2100)
    {
      val.duration = -1;
    }

    // clean up the site name
    if (val.siteName)
    {
      val.siteName = val.siteName.replace(/\0/g, '');
    }

  }

  //////////////////////////////////////////////////////////////////////////////
  // onClearAlarm
  //
  // Clicked the clear link on an alarm.
  //////////////////////////////////////////////////////////////////////////////
  onClearAlarm(alarm: AlarmData)
  {
    if (confirm("Are you sure you want to clear this alarm?"))
    {
      this.alarmService.ClearAlarm(alarm).subscribe();
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // Update
  //////////////////////////////////////////////////////////////////////////////
  Update(site: string, pid: string, channel: number, value: string)
  {
    //console.log("" + site + pid + channel + value);

    switch (pid)
    {
      case "UpdateTime":
        this.alarmService.getAlarms(this.sites).subscribe((d) =>
        {
          // for each element in the array, calculate the other alarm fields.
          d.map((val) =>
          {
            this.calculateAlarmFields(val);
          })

          this.dataSource.data = d;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
        break;
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // CreateAlarmData
  //
  // Given a string from the web server, break it apart and create an AlarmData
  // object.
  //////////////////////////////////////////////////////////////////////////////
  CreateAlarmData(str: string): AlarmData
  {
    let alarm = new AlarmData();
    let split = str.split('~');

    alarm.siteId = "" + split[0];
    alarm.alarmId = +split[1];
    alarm.startTime = split[2];
    alarm.inactiveTime = split[3];
    alarm.clearedTime = split[4];
    alarm.channel = +split[5];
    alarm.type = split[6];
    alarm.severity = split[7];
    alarm.message = split[8];
    alarm.siteName = split[9];

    this.calculateAlarmFields(alarm);

    return alarm;
  }

  //////////////////////////////////////////////////////////////////////////////
  // FindAlarm
  //
  // Given an alarm id, find the index of that alarm in the dataSource.  If it
  // is not found, return -1.
  //////////////////////////////////////////////////////////////////////////////
  FindAlarm(id: number): number
  {
    let index = -1;

    let a = this.dataSource.data.find((obj, i) =>
    {
      if (obj.alarmId == id)
      {
        index = i;
      }
      return obj.alarmId == id;
    });

    return index;
  }

  /**
   * 
   * @param $event 
   */
  onClick($event: Event)
  {
    event.stopPropagation();
  }

  onClearAll()
  {
    if (!confirm("Are you sure you want to clear all alarms?"))
    {
      return;
    }

    //get the current set of rows displayed in the table.
    let rows = this.dataSource.data;

    let sites = new Set<number>();
    rows.forEach(alarm =>
    {
      sites.add(+alarm.siteId);
    });
    var siteArray = Array.from(sites);
    this.alarmService.ClearAllAlarms(siteArray).subscribe();
  }

  /**
   * User clicked the close button.
   */
  onClose()
  {
    this.close.emit();
  }
}
