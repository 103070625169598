import { Component, Inject, ComponentFactoryResolver } from '@angular/core';
import { SitePropertyComponent } from '../../site-property/site-property.component';
import { DefaultSiteWidgetComponent } from '../default-site-widget.component';
import { SiteConfiguration } from '../../site-widget/site-widget.component';

@Component({
  selector: 'app-default-site-property',
  templateUrl: './default-site-property.component.html',
  styleUrls: ['./default-site-property.component.css']
})
export class DefaultSitePropertyComponent extends SitePropertyComponent<DefaultSiteWidgetComponent>
{
  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(DefaultSiteWidgetComponent, factoryResolver);

    let cfg = new SiteConfiguration();

    cfg.type = DefaultSiteWidgetComponent.GetType();
    cfg.fullWidth = true;

    this.Configure(cfg);
  }

}
