import { Pipe, PipeTransform } from '@angular/core';

export interface IConvertToTernary
{
  GetTernary(value: number)
}

export enum Ternary
{
  Off,
  On,
  Unknown, // unknown, error, offline, etc.
}

@Pipe({
  name: 'macroStatus'
})
export class MacroStatusPipe implements PipeTransform, IConvertToTernary
{
  static macroStatusMap = [
    "", // Off = 0,
    "",// Delay1 = 1,
    "",// Delay2 = 2,		// This may be obsolete
    "Running",// On = 3,
    "Running",// InDelay = 4,
    "Running",// TimeWait = 5,
    "Running",// ValueWait = 6,
    "Running",// StatusWait = 7,
    "Running",// ValueTimeWait = 8,
    "Running",// StatusTimeWait = 9,
    "Running",// AlarmWait = 10,
    "Running",// MaintWait = 11,
    "Done",// MacroDone = 12,
    "Error",// Error = 13,
    "Error",// Uninitialized = 0xFF
  ];

  ///////////////////////////////////////////////////////////////////////////
  // transform
  //
  // Transform a macro status number to a string.
  ///////////////////////////////////////////////////////////////////////////
  transform(value: number): string
  {
    // if the value is out of bounds...
    if (value >= MacroStatusPipe.macroStatusMap.length ||
      value < 0 ||
      isNaN(value))
    {
      return "error";
    }

    return MacroStatusPipe.macroStatusMap[value];
  }

  /**
   * Given a CurrentMacroStatus, convert to Stopped, Running, Error.
   * @param value 
   */
  GetTernary(value: number): Ternary
  {
    if (value >= MacroStatusPipe.macroStatusMap.length ||
      value < 0 ||
      isNaN(value))
    {
      return Ternary.Unknown;
    }

    switch (MacroStatusPipe.macroStatusMap[value])
    {
      case "":
      case "Done":
        return Ternary.Off;
      case "Running":
        return Ternary.On;
      default:
        return Ternary.Unknown;
    }
  }

}
