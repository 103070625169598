import { Component, Input, OnInit } from '@angular/core';
import { SitesService, SiteData } from '../services/sites.service';
import { MatrixData } from '../services/group-panel.service';

@Component({
  selector: 'app-all-sites-page',
  templateUrl: './all-sites-page.component.html',
  styleUrls: ['./all-sites-page.component.css']
})
export class AllSitesPageComponent implements OnInit
{
  @Input() commandConfirm: boolean;

  private sites: MatrixData[] = [];
  private expandedTargets: number[] = []; // array of the index of groups to expand.
  private showTarget: boolean[] = [];

  constructor(
    private sitesService: SitesService
  )
  {
  }

  ngOnInit()
  {
    if (this.sitesService != null)
    {
      this.sitesService.getSites().subscribe((data) =>
      {
        this.sites = [];
        data.forEach(element =>
        {
          let obj = new MatrixData();
          obj.name = element.name;
          obj.alias = element.alias;

          this.sites.push(obj);
        });

        this.InitializeShowTarget();
      });
    }
  }

  private InitializeShowTarget()
  {
    // initialize the showTargets to false;
    this.showTarget.length = 0;
    this.sites.forEach(element =>
    {
      this.showTarget.push(false);
    });
  }

  private toggleTarget(i: number)
  {
    this.showTarget[i] = !this.showTarget[i];

    // if the target is already in the list...
    if (this.expandedTargets.includes(i))
    {
      let removeIndex = this.expandedTargets.indexOf(i);
      // remove the item
      this.expandedTargets.splice(removeIndex, 1);
    }
    else // add the item to the head of the list.
    {
      this.expandedTargets.unshift(i);
    }

  }

  /**
   * Close the target.
   * @param $event 
   * @param i 
   */
  private onCloseTarget(event: Event, i: number)
  {
    event.stopPropagation();
    this.toggleTarget(i);
  }
}
