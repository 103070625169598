import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CustomViewWidgetComponent, CustomViewWidgetData } from '../custom-view-widget.component';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { DataOneShotService } from '../../services/data-one-shot.service';
import { CommandService, MacroCommandData } from '../../services/command.service';
import { SiteConfiguration, SiteWidgetComponent } from '../site-widget/site-widget.component';

export class Configuration extends SiteConfiguration
{
  channel: number;
  confirmation: boolean;
}

@Component({
  selector: 'app-macro-widget',
  templateUrl: './macro-widget.component.html',
  styleUrls: ['./macro-widget.component.css',
    '../command-button.css',
    '../custom-view-widget.component.css',]
})
export class MacroWidgetComponent extends SiteWidgetComponent
{
  // channel adaptor
  private channel: number;
  private label: string;
  private macroState: number;
  private confirmation: boolean;

  private isAllowed: boolean;

  @ViewChild('myDiv') myDiv: ElementRef;

  constructor(protected ds?: DataSubscriptionService,
    protected oneshot?: DataOneShotService,
    private cs?: CommandService) 
  {
    super(ds, oneshot);
  }

  Configure(obj: Configuration): CustomViewWidgetComponent
  {
    super.Configure(obj);

    this.channel = obj.channel;
    this.confirmation = obj.confirmation;
    this.fontSize = obj.fontSize;

    this.GetData();

    return this;
  }
  static GetType(): string { return "Macro"; }

  GetData()
  {
    // console.log("Widget subscribe");

    this.oneshot.getPermission(this.site, "Macro", this.channel).subscribe((b) =>
    {
      this.isAllowed = b;
      if (!this.isAllowed)
      {
        return;
      }

      this.oneshot.getPidParameters(this.site, "Macro", this.channel,
        [
          "MacroName"
        ])
        .subscribe((res) =>
        {
          for (let p of res)
          {
            //console.log(p.parameter + ": " + p.value);
            switch (p.parameter)
            {
              case "MacroName":
                this.label = p.value;
                break;
            }
          }
        });
    });
  }

  ///////////////////////////////////////////////////////////////////////////
  // Subscribe
  //
  // Call the datasubscription service to add the subscriptions.
  ///////////////////////////////////////////////////////////////////////////
  protected Subscribe()
  {
    super.Subscribe();

    this.ds.Add(this, [
      {
        "site": this.site,
        "block": "Macro",
        "channel": this.channel,
        "pid": "MacroCurrentState"
      }]);
  }

  /**
   * 
   * @param site 
   * @param pid 
   * @param channel 
   * @param value 
   */
  Update(site: string, pid: string, channel: number, value: string)
  {
    super.Update(site, pid, channel, value);

    if (pid == "MacroCurrentState")
    {
      // console.log("Macro State: " + this.channel + " : " + value);

      this.macroState = +value; // convert the string to a number.
    }
  }

  onRun() { this.sendCommand(true); }
  onStop() { this.sendCommand(false); }

  sendCommand(b: boolean)
  {
    let cmd = new MacroCommandData();

    cmd.site = this.site;
    cmd.channel = this.channel;
    cmd.runStop = b;

    if (!this.preventClicks)
    {
      let str = ((b) ? "Run" : "Stop") + " " + this.label + "?";
      str = str.replace(/\0/g, '').trim();

      // if we are not confirming the command, do it
      // or if we did confirm the command, do it.
      if (!this.confirmation || confirm(str))
      {
        this.cs.macro(cmd).subscribe();
      }
    }
  }

  private lowerPressed: boolean;
  private raisePressed: boolean;
  onLowerMouseDown($event)
  {
    this.lowerPressed = true;
  }
  onLowerMouseUp($event)
  {
    this.lowerPressed = false;
  }
  onLowerMouseLeave($event)
  {
    this.lowerPressed = false;
  }
  onRaiseMouseDown($event)
  {
    this.raisePressed = true;
  }
  onRaiseMouseUp($event)
  {
    this.raisePressed = false;
  }
  onRaiseMouseLeave($event)
  {
    this.raisePressed = false;
  }
}
