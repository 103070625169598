import { Component, OnInit, Inject, ComponentFactoryResolver } from '@angular/core';
import { ChannelPropertyComponent } from '../../channel-property/channel-property.component';
import { MacroWidgetComponent } from '../macro-widget.component';
import { Configuration } from '../../angular-meter-widget/angular-meter-widget.component';
import { TileSize, DoubleWide_SingleHeight, FourxWide_DoubleHeight, SingleWide_HalfHeight } from '../../../size-selector/size-selector.component';

@Component({
  selector: 'app-macro-property',
  templateUrl: './macro-property.component.html',
  styleUrls: ['./macro-property.component.css']
})
export class MacroPropertyComponent extends ChannelPropertyComponent<MacroWidgetComponent>
{
  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(MacroWidgetComponent, factoryResolver);

    let cfg = new Configuration();

    cfg.type = MacroWidgetComponent.GetType();

    this.Configure(cfg);
  }

  readonly sizes: TileSize[] =
    [
      SingleWide_HalfHeight,
      DoubleWide_SingleHeight,
      FourxWide_DoubleHeight
    ];

  GetDefaultSize(): number { return 1; }

}
