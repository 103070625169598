import { DataSubscriptionService } from "../../services/data-subscription.service";
import { RealTimeUpdate } from "../custom-view-widget.component";
import { SimpleChanges, Input, Directive, OnDestroy, OnChanges, Component } from "@angular/core";
import { MatrixData } from "../../services/group-panel.service";
import { DataOneShotService } from "../../services/data-one-shot.service";

//////////////////////////////////////////////////////////////////////////
// AlarmCounter
//
// Base class to handle alarm counts from sites.
//////////////////////////////////////////////////////////////////////////

@Component({
	selector: 'app-alarm-counter',
	template: '',
})
export abstract class AlarmCounter implements RealTimeUpdate, OnDestroy, OnChanges
{
	private criticalAlarmMap = new Map();
	private warningAlarmMap = new Map();
	private unconnectedMap = new Map();

	critical: number;
	warning: number;
	unconnected: number;

	public constructor(
		protected ds: DataSubscriptionService,
		protected oneshot: DataOneShotService,
	) { }

	ngOnDestroy(): void
	{
		this.ds.Remove(this);
	}

	ngOnChanges(changes: SimpleChanges): void
	{
		//console.log(this.id + ": ngOnChnages");
		this.criticalAlarmMap = new Map();
		this.warningAlarmMap = new Map();

		this.ds.Remove(this); // remove all previous subscriptions
		this.SubscribeEx();
	}

	///////////////////////////////////////////////////////////////////////////
	// Subscribe
	//
	// Call the datasubscription service to add the subscriptions.
	// The subscriptions is deeply nested.  Need to recurse into the sites to
	// find all the sites.
	///////////////////////////////////////////////////////////////////////////
	protected Subscribe()
	{
		// console.log("alarm counter subscribe");
	}

	//////////////////////////////////////////////////////////////////////////
	// SubscribeEx
	//
	// This is called internally to actually subscribe to the channel.
	// We need to call subscribe if the site changes.
	//////////////////////////////////////////////////////////////////////////
	private SubscribeEx()
	{
		let aliases: number[] = [];

		// get all the aliases
		this.GetAllAliases(aliases);

		this.SubscribeToAllSites(aliases);
	}

	/**
	 * Abstract method to get all the aliases that this couter should count.
	 * @param aliases 
	 */
	protected abstract GetAllAliases(aliases: number[]);

	/**
	 * Reduce the aliases and then subscribe to the data.
	 * @param aliases 
	 */
	protected SubscribeToAllSites(aliases: number[])
	{
		// reduce the array (of duplicates) to a set of uniques
		let aliasSet = new Set();
		aliases.forEach(value =>
		{
			aliasSet.add(+value);
		});

		aliasSet.forEach(a =>
		{
			// console.log("Subscribe to alarms: " + a);
			if (a > 0)
			{
				let site = "" + a;

				// assume not TCP connected and not Warp Engine connected.
				this.unconnectedMap.set(site, [0, 0]);

				this.ds.Add(this, [
					{
						"site": site,
						"block": "AlarmStats",
						"channel": 0,
						"pid": "CriticalAlarms"
					},
					{
						"site": site,
						"block": "AlarmStats",
						"channel": 0,
						"pid": "WarningAlarms"
					},
					{
						"site": site,
						"block": "Expires",
						"channel": 0,
						"pid": "SiteState"
					},
					{
						"site": site,
						"block": "WarpEngine",
						"channel": 0,
						"pid": "WarpEngineGood"
					},
				]);
			}
		});
	}

	///////////////////////////////////////////////////////////////////////////
	// Update
	//
	// Implements the RealTimeUpdate interface.
	// The Data Subscription Service will call this when new values are received.
	///////////////////////////////////////////////////////////////////////////
	Update(site: string, pid: string, channel: number, value: string)
	{
		// console.log("Update to alarms: " + site + pid + value);
		switch (pid)
		{
			case "CriticalAlarms":

				this.criticalAlarmMap.set(site, +value);

				this.countCriticalAlarms();
				break;

			case "WarningAlarms":
				this.warningAlarmMap.set(site, +value);
				this.countWarningAlarms();
				break;

			case "SiteState":
				// if we already have this site
				if (this.unconnectedMap.has(site))
				{
					let arr = this.unconnectedMap.get(site);

					arr[0] = +value;
					this.unconnectedMap.set(site, arr);
				}
				else
				{
					// error
				}

				this.CalculateUnconnectedSites();
				break;
			case "WarpEngineGood":
				// if we already have this site
				if (this.unconnectedMap.has(site))
				{
					let arr = this.unconnectedMap.get(site);

					arr[1] = +value;
					this.unconnectedMap.set(site, arr);
				}
				else
				{
					// error
				}

				this.CalculateUnconnectedSites();
				break;
		}
	}

	/**
	 * Calculate how many sites are not connected.
	 */
	CalculateUnconnectedSites()
	{
		this.unconnected = 0;
		this.unconnectedMap.forEach((val, key) =>
		{
			// if the site is not connected and not warp engine connected...
			if (!val[0] && !val[1])
			{
				this.unconnected++;
			}
		});
	}

	/**
	 * Count the number of critical alarms.
	 */
	countCriticalAlarms()
	{
		this.critical = 0;
		this.criticalAlarmMap.forEach((val) =>
		{
			this.critical += val;
		});
	}

	/**
	 * Count the number of warning alarms.
	 */
	countWarningAlarms()
	{
		this.warning = 0;
		this.warningAlarmMap.forEach((val) =>
		{
			this.warning += val;
		});
	}

}
