import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'offset'
})
export class OffsetPipe implements PipeTransform 
{
  ///////////////////////////////////////////////////////////////////////////
  // transform
  //
  // Transform a 0-based channel number to 1-based.
  ///////////////////////////////////////////////////////////////////////////
  transform(value: number): number 
  {
    return value + 1;
  }
}
