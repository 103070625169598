import { Component, OnInit, Input, ViewChild, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { RealTimeUpdate } from '../custom-view-widget/custom-view-widget.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DataSubscriptionService } from '../services/data-subscription.service';
import { DataOneShotService, PidOneShot, MacroData } from '../services/data-one-shot.service';
import { CommandService, MacroCommandData } from '../services/command.service';

class MacroDataEx
{
  macro: MacroData;
  raisePressed: boolean;
  lowerPressed: boolean;
}

@Component({
  selector: 'app-macro-table',
  templateUrl: './macro-table.component.html',
  styleUrls: ['./macro-table.component.css']
})
export class MacroTableComponent implements OnInit, RealTimeUpdate, OnChanges, OnDestroy
{
  @Input() sites: string[];
  @Input() pageSize: string[];

  @Output() close = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<MacroData>;

  private dataSource: MatTableDataSource<MacroDataEx>;
  private columnsToDisplay: string[] = [
    "siteName", "name", "run", "stop", "status"
  ];
  private loading: boolean = true;

  constructor(private dataOneShot: DataOneShotService,
    private commandService: CommandService,
    private dataSubcription: DataSubscriptionService)
  { }
  ngOnDestroy(): void
  {
    this.dataSubcription.Remove(this);
  }

  ngOnInit()
  {
    this.UpdateSites();
  }
  ngOnChanges()
  {
    this.UpdateSites();
  }
  UpdateSites()
  {
    if (this.sites == null) return;

    this.loading = true;

    this.dataOneShot.getMacros(this.sites).subscribe((data) =>
    {
      this.loading = false;

      var d: MacroDataEx[] = [];

      data.map((val) =>
      {
        // clean up the site name
        val.siteName = val.siteName.replace(/\0/g, '');
        val.name = val.name.replace(/\0/g, '');

        var ex = new MacroDataEx();
        ex.macro = val;
        ex.lowerPressed = false;
        ex.raisePressed = false;

        d.push(ex)
      });

      this.dataSource = new MatTableDataSource<MacroDataEx>(d);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      // subscribe to each macro
      d.forEach((macro) =>
      {
        this.dataSubcription.Add(this, [
          {
            "site": "" + macro.macro.siteId,
            "block": "Macro",
            "channel": macro.macro.channel,
            "pid": "MacroCurrentState"
          },
        ]);
      });
    });
  }

  //////////////////////////////////////////////////////////////////////////////
  // Update
  //////////////////////////////////////////////////////////////////////////////
  Update(site: string, pid: string, channel: number, value: string)
  {
    // find this macro
    let index = this.FindMacro(+site, channel);

    if (index < 0)
      return;

    // set the status value
    if (this.dataSource.data[index].macro.status != +value)
    {
      this.dataSource.data[index].macro.status = +value;

      // refresh the table.
      this.dataSource._updateChangeSubscription();
    }
  }


  //////////////////////////////////////////////////////////////////////////////
  // FindAlarm
  //
  // Given an alarm id, find the index of that alarm in the dataSource.  If it
  // is not found, return -1.
  //////////////////////////////////////////////////////////////////////////////
  FindMacro(siteId: number, channel: number): number
  {
    let index = -1;

    // find the macro that matches this site and channel
    let a = this.dataSource.data.find((obj, i) =>
    {
      if (obj.macro.siteId == siteId && obj.macro.channel == channel)
      {
        index = i;
      }
      return (obj.macro.siteId == siteId && obj.macro.channel == channel);
    });

    return index;
  }

  //////////////////////////////////////////////////////////////////////////////
  // onRun
  //
  // Run a macro
  //////////////////////////////////////////////////////////////////////////////
  onRun(m: MacroData)
  {
    this.macroCommand(m.siteId, m.channel, true);
  }

  //////////////////////////////////////////////////////////////////////////////
  // onStop
  //
  // Run a macro
  //////////////////////////////////////////////////////////////////////////////
  onStop(m: MacroData)
  {
    this.macroCommand(m.siteId, m.channel, false);
  }

  //////////////////////////////////////////////////////////////////////////////
  // macroCommand
  //
  // Run or stop a macro
  //////////////////////////////////////////////////////////////////////////////
  macroCommand(site: number, channel: number, b: boolean)
  {
    var cmd: MacroCommandData = new MacroCommandData();

    cmd.site = "" + site;
    cmd.channel = channel;
    cmd.runStop = b;

    this.commandService.macro(cmd).subscribe();
  }

  /**
   * 
   */
  onClick(event: Event)
  {
    event.stopPropagation();
  }

  /**
   * User clicked the close button.
   */
  onClose()
  {
    this.close.emit();
  }


  onLowerMouseDown(row: MacroDataEx)
  {
    row.lowerPressed = true;
  }
  onLowerMouseUp(row: MacroDataEx)
  {
    row.lowerPressed = false;
  }
  onLowerMouseLeave(row: MacroDataEx)
  {
    row.lowerPressed = false;
  }
  onRaiseMouseDown(row: MacroDataEx)
  {
    row.raisePressed = true;
  }
  onRaiseMouseUp(row: MacroDataEx)
  {
    row.raisePressed = false;
  }
  onRaiseMouseLeave(row: MacroDataEx)
  {
    row.raisePressed = false;
  }
}
