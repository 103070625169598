import { ComponentFactoryResolver, Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FullWidth_DoubleHeight, FullWidth_EightxHeight, FullWidth_FourxHeight, FullWidth_HalfHeight, FullWidth_QuarterHeight, FullWidth_SingleHeight, TileSize } from '../../../size-selector/size-selector.component';
import { CustomViewPropertyComponent } from '../../custom-view-property.component';
import { Configuration, LabelFullWidthComponent } from '../label-full-width.component';

@Component({
  selector: 'app-label-full-width-property',
  templateUrl: './label-full-width-property.component.html',
  styleUrls: ['./label-full-width-property.component.css']
})
export class LabelFullWidthPropertyComponent extends CustomViewPropertyComponent<LabelFullWidthComponent>
{

  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(LabelFullWidthComponent, factoryResolver);

    let cfg = new Configuration();

    cfg.type = LabelFullWidthComponent.GetType();
    cfg.fullWidth = true;

    this.Configure(cfg);
  }

  /**
     * Return the valid sizes of this type of Tile
     */
  readonly sizes: TileSize[] =
    [
      FullWidth_QuarterHeight,
      FullWidth_HalfHeight,
      FullWidth_SingleHeight,
      FullWidth_DoubleHeight,
      FullWidth_FourxHeight,
      FullWidth_EightxHeight,
    ];


  /**
   * Return the index of the array of sizes returned from GetSizes() that is 
   * the default size.
   */
  GetDefaultSize(): number { return 1; }


  /**
   * Override the onNewSizeSelected to set specific sizes.
   * @param $event 
   */
  onNewSizeSelected($event: TileSize)
  {
    super.onNewSizeSelected($event);

    // double height
    if (this.myConfiguration.height >= 150)
    {
      this.myConfiguration.fontSize = 44;
    }
    // single height
    else if (this.myConfiguration.height >= 70)
    {
      this.myConfiguration.fontSize = 36;
    }
    // half height
    else if (this.myConfiguration.height >= 30)
    {
      this.myConfiguration.fontSize = 20;
    }
    // quarter height
    else
    {
      this.myConfiguration.fontSize = 7;
    }

    this.onChange();
  }

}
