import { Component } from '@angular/core';
import { CustomViewWidgetComponent, CustomViewWidgetData, RealTimeUpdate } from '../custom-view-widget.component';
import { DataOneShotService } from '../../services/data-one-shot.service';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { CommandService, CommandData } from '../../services/command.service';
import { SiteConfiguration, SiteWidgetComponent } from '../site-widget/site-widget.component';
import { ChannelHelper } from '../../services/status-condition-types.service';

export class CommandButtonConfiguration extends SiteConfiguration
{
  channel: number;
  confirmation: boolean;
}

@Component({
  selector: 'app-command-button-widget',
  templateUrl: './command-button-widget.component.html',
  styleUrls: ['./command-button-widget.component.css',
    '../command-button.css',
    '../custom-view-widget.component.css',]
})
export class CommandButtonWidgetComponent extends SiteWidgetComponent implements RealTimeUpdate
{
  private raiseLabel: string;
  private lowerLabel: string;

  private channelOnline: boolean;

  // channel adaptor
  private channel: number;
  private confirmation: boolean;

  private isAllowed: boolean;
  private lowerPressed: boolean;
  private raisePressed: boolean;

  ///////////////////////////////////////////////////////////////////////////
  // constructor
  ///////////////////////////////////////////////////////////////////////////
  constructor(protected ds?: DataSubscriptionService,
    protected oneshot?: DataOneShotService,
    private cs?: CommandService) 
  {
    super(ds, oneshot);
  }

  /**
   * Overrides the base CustomViewWidget GetType.
   */
  static GetType(): string { return "Command Button"; }

  ///////////////////////////////////////////////////////////////////////////
  // Configure
  ///////////////////////////////////////////////////////////////////////////
  Configure(obj: CommandButtonConfiguration)
  {
    super.Configure(obj)

    this.channel = obj.channel;
    this.confirmation = obj.confirmation;

    this.GetData();

    return this;
  }

  ///////////////////////////////////////////////////////////////////////////
  // GetData
  ///////////////////////////////////////////////////////////////////////////
  GetData()
  {
    this.oneshot.getPermission(this.site, "Output", this.channel).subscribe((b) =>
    {
      this.isAllowed = b;
      if (!this.isAllowed)
      {
        return;
      }
      this.oneshot.getPidParameters(this.site, "Output", this.channel,
        [
          "OutputLcdLowerLabel",
          "OutputLcdRaiseLabel",
        ])
        .subscribe((res) =>
        {
          for (let p of res)
          {
            //console.log(p.parameter + ": " + p.value);
            switch (p.parameter)
            {
              case "OutputLcdLowerLabel":
                this.lowerLabel = p.value;
                // this.lowerLabelOrig = p.value;
                break;
              case "OutputLcdRaiseLabel":
                this.raiseLabel = p.value;
                // this.raiseLabelOrig = p.value;
                break;
            }
          }
        });
    });
  }

  /**
   * 
   */
  protected Subscribe()
  {
    super.Subscribe();

    this.ds.Add(this, [
      {
        "site": this.site,
        "block": "Output",
        "channel": this.channel,
        "pid": "OutputState",
      }]);
  }

  /**
   * 
   * @param site 
   * @param pid  
   * @param channel 
   * @param value 
   */
  Update(site: string, pid: string, channel: number, value: string)
  {
    super.Update(site, pid, channel, value);

    if (pid == "OutputState")
    {
      this.channelOnline = ChannelHelper.convertChannelStateToOnline(value);
    }
  }


  ///////////////////////////////////////////////////////////////////////////
  // onRaise
  ///////////////////////////////////////////////////////////////////////////
  onRaise() { this.sendCommand(true); }

  ///////////////////////////////////////////////////////////////////////////
  // onLower
  ///////////////////////////////////////////////////////////////////////////
  onLower() { this.sendCommand(false); }

  ///////////////////////////////////////////////////////////////////////////
  // sendCommand
  //
  // If the preventClicks is set (Edit Page), then don't do command.
  // If the confirmation is set, then ask the user.
  // Send the command to the web server.
  ///////////////////////////////////////////////////////////////////////////
  sendCommand(b: boolean)
  {
    let cmd = new CommandData();

    cmd.site = this.site;
    cmd.channel = this.channel;
    cmd.raiseLower = b;

    if (!this.preventClicks)
    {
      let str = ((b) ? this.raiseLabel : this.lowerLabel);
      str = str.replace(/\0/g, '').trim();

      // if we are not confirming the command, do it
      // or if we did confirm the command, do it.
      if (!this.confirmation || confirm(str + "?"))
      {
        this.cs.command(cmd).subscribe();
      }
    }
  }

  onLowerMouseDown($event)
  {
    this.lowerPressed = true;
  }
  onLowerMouseUp($event)
  {
    this.lowerPressed = false;
  }
  onLowerMouseLeave($event)
  {
    this.lowerPressed = false;
  }
  onRaiseMouseDown($event)
  {
    this.raisePressed = true;
  }
  onRaiseMouseUp($event)
  {
    this.raisePressed = false;
  }
  onRaiseMouseLeave($event)
  {
    this.raisePressed = false;
  }

  get commandChannelOfflineMsg(): string
  {
    return this.calcCommandChannelOfflineMsg(this.raiseLabel, this.lowerLabel, this.channel);
  }
}
