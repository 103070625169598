import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CustomViewWidgetComponent, CustomViewWidgetData } from '../custom-view-widget.component';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { DataOneShotService } from '../../services/data-one-shot.service';
import { SiteConfiguration, SiteWidgetComponent } from '../site-widget/site-widget.component';
import { ChannelHelper } from '../../services/status-condition-types.service';

export class Configuration extends SiteConfiguration
{
  site: string;
  channel: number;
}

@Component({
  selector: 'app-meter-text-widget',
  templateUrl: './meter-text-widget.component.html',
  styleUrls: ['./meter-text-widget.component.css',
    '../custom-view-widget.component.css',]
})
export class MeterTextWidgetComponent extends SiteWidgetComponent implements OnInit
{

  // channel adaptor
  private channel: number;

  // Meter Value
  private value: number;
  private label: string
  private units: string;
  private decimalPoint: number;
  private singleLine: boolean;
  public channelOnline: boolean;

  // Meter Colors
  private lowCriticalColor: number;
  private lowWarningColor: number;
  private normalColor: number;
  private highWarningColor: number;
  private highCriticalColor: number;
  private meterCurrentColor: number;

  // Meter Limits
  private lowCriticalLimit: number = 10;
  private lowWarningLimit: number = 20;
  private highWarningLimit: number = 80;
  private highCriticalLimit: number = 90;

  private isAllowed: boolean;

  @ViewChild('label1') label1: ElementRef;
  @ViewChild('label2') label2: ElementRef;
  private myTooltip1 = "";
  private myTooltip2 = "";

  private valueFontSize: number = 16;

  constructor(protected ds?: DataSubscriptionService,
    protected oneshot?: DataOneShotService) 
  {
    super(ds, oneshot);
  }

  ngOnInit()
  {
  }

  Configure(obj: Configuration): CustomViewWidgetComponent
  {
    super.Configure(obj);

    this.channel = obj.channel;

    this.singleLine = (this.height <= 30);
    if (!this.singleLine)
    {
      this.valueFontSize = (this.height - 10) / 3;
    }
    else
    {
      this.valueFontSize = 16;
    }

    this.GetData();

    return this;
  }
  static GetType(): string { return "Text Meter"; }

  /**
   * Get the one-shot data.
   */
  GetData()
  {
    // console.log("Widget subscribe");
    this.oneshot.getPermission(this.site, "Analog", this.channel).subscribe((b) =>
    {
      this.isAllowed = b;
      if (!this.isAllowed)
      {
        return;
      }

      this.CalculateBackgroundColor();

      this.oneshot.getPidParameters(this.site, "Analog", this.channel,
        [
          "AnalogName",
          "AnalogUnitsLabel",
          "AnalogDecimalPoint",
          "AnalogHighCriticalColor",
          "AnalogHighWarningColor",
          "AnalogNormalColor",
          "AnalogLowWarningColor",
          "AnalogLowCriticalColor",
          "AnalogHighCriticalLimit",
          "AnalogHighWarningLimit",
          "AnalogLowWarningLimit",
          "AnalogLowCriticalLimit",
        ])
        .subscribe((res) =>
        {
          for (let p of res)
          {
            //console.log(p.parameter + ": " + p.value);
            switch (p.parameter)
            {
              case "AnalogName":
                this.label = p.value;
                this.UpdateToolTips();
                break;
              case "AnalogUnitsLabel":
                this.units = p.value;
                break;
              case "AnalogDecimalPoint":
                this.decimalPoint = +p.value;
                this.meterCurrentColor = this.GetMeterColor();
                break;
              case "AnalogHighCriticalColor":
                this.highCriticalColor = +p.value;
                this.meterCurrentColor = this.GetMeterColor();
                break;
              case "AnalogHighWarningColor":
                this.highWarningColor = +p.value;
                this.meterCurrentColor = this.GetMeterColor();
                break;
              case "AnalogNormalColor":
                this.normalColor = +p.value;
                this.meterCurrentColor = this.GetMeterColor();
                break;
              case "AnalogLowWarningColor":
                this.lowWarningColor = +p.value;
                this.meterCurrentColor = this.GetMeterColor();
                break;
              case "AnalogLowCriticalColor":
                this.lowCriticalColor = +p.value;
                this.meterCurrentColor = this.GetMeterColor();
                break;
              case "AnalogHighCriticalLimit":
                this.highCriticalLimit = +p.value;
                this.meterCurrentColor = this.GetMeterColor();
                break;
              case "AnalogHighWarningLimit":
                this.highWarningLimit = +p.value;
                this.meterCurrentColor = this.GetMeterColor();
                break;
              case "AnalogLowWarningLimit":
                this.lowWarningLimit = +p.value;
                this.meterCurrentColor = this.GetMeterColor();
                break;
              case "AnalogLowCriticalLimit":
                this.lowCriticalLimit = +p.value;
                this.meterCurrentColor = this.GetMeterColor();
                break;
            }
          }
        });
    });
  }

  ///////////////////////////////////////////////////////////////////////////
  // Subscribe
  //
  // Call the datasubscription service to add the subscriptions.
  ///////////////////////////////////////////////////////////////////////////
  protected Subscribe()
  {
    super.Subscribe();

    this.ds.Add(this, [
      {
        "site": this.site,
        "block": "Analog",
        "channel": this.channel,
        "pid": "AnalogScaled"
      },
      {
        "site": this.site,
        "block": "Analog",
        "channel": this.channel,
        "pid": "AnalogState"
      },
    ]);
  }

  ///////////////////////////////////////////////////////////////////////////
  // Update
  ///////////////////////////////////////////////////////////////////////////
  Update(site: string, pid: string, channel: number, value: string)
  {
    super.Update(site, pid, channel, value);

    if (pid == "AnalogScaled")
    {
      this.value = +value; // convert the string to a number.
      this.meterCurrentColor = this.GetMeterColor();
    }
    if (pid == "AnalogState")
    {
      this.channelOnline = ChannelHelper.convertChannelStateToOnline(value);
    }
  }

  ///////////////////////////////////////////////////////////////////////////
  // GetMeterColor
  //
  // Use the meterValue and the limits to calculate the correct color.
  ///////////////////////////////////////////////////////////////////////////
  GetMeterColor(): number
  {
    if (this.value >= this.highCriticalLimit)
    {
      return this.highCriticalColor;
    }
    if (this.value >= this.highWarningLimit)
    {
      return this.highWarningColor;
    }
    if (this.value <= this.lowCriticalLimit)
    {
      return this.lowCriticalColor;
    }
    if (this.value <= this.lowWarningLimit)
    {
      return this.lowWarningColor;
    }
    return this.normalColor;
  }

  ///////////////////////////////////////////////////////////////////////////
  // UpdateToolTips
  ///////////////////////////////////////////////////////////////////////////
  UpdateToolTips()
  {
    setTimeout(() =>
    {
      if (this.label1 != null)
      {
        this.myTooltip1 = this.TooltipText(this.label1);
      }
      if (this.label2 != null)
      {
        this.myTooltip2 = this.TooltipText(this.label2);
      }
    }, 0);
  }

  TooltipTextLabel1(): string
  {
    return this.TooltipText(this.label1);
  }
  TooltipTextLabel2(): string
  {
    return this.TooltipText(this.label2);
  }
  TooltipText(el: ElementRef): string
  {
    // at the beginning of the render, the element is not assigned yet.  Check for null to just return.
    if (el == null)
    {
      return "";
    }
    // if the width of the item is less than the scroll ing width, it has an ellipsis.  Return the label.
    if (el.nativeElement.offsetWidth < el.nativeElement.scrollWidth)
    {
      return this.label;
    }

    // the element is wide enough to fit everything, there is no tooltip.  Return a blank label.
    return "";
  }

}
