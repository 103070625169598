import { Component, OnInit, Input } from '@angular/core';
import { DataOneShotService } from '../services/data-one-shot.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalSiteDialogComponent } from '../modal-site-dialog/modal-site-dialog.component';

/**
 * This component displays as a Link to a Site Name.
 * When clicked it will dispaly the default site widget in a modal dialog.
 */
@Component({
  selector: 'app-modal-site-link',
  templateUrl: './modal-site-link.component.html',
  styleUrls: ['./modal-site-link.component.css']
})
export class ModalSiteLinkComponent implements OnInit
{
  @Input() siteId: string;
  name: string;

  constructor(private dialog: MatDialog,
    private oneshot: DataOneShotService)
  { }

  ngOnInit()
  {
    // get the site name from the siteId.
    this.oneshot.getPidParameters(this.siteId, "Site", 0,
      [
        "SiteName",
      ])
      .subscribe((res) =>
      {
        for (let p of res)
        {
          switch (p.parameter)
          {
            case "SiteName":
              this.name = p.value;
              break;
          }
        }
      });
  }

  //////////////////////////////////////////////////////////////////////
  // onClick
  //
  // Clicked on the Site Link.  Open the Modal Site Dialog.
  //////////////////////////////////////////////////////////////////////
  // onClick()
  // {
  //   // https://appdividend.com/2019/02/11/angular-modal-tutorial-with-example-angular-material-dialog/

  //   const dialogRef = this.dialog.open(ModalSiteDialogComponent, {
  //     width: '100%',
  //     maxHeight: '90vh',
  //     data: { site: this.siteId }
  //   });
  // }
}
