import { Component, Input, OnChanges, SimpleChanges, Inject, ViewContainerRef, ViewChild } from '@angular/core';
import { CustomViewPageService } from '../services/custom-view-page.service';
import { CreateWidgetService } from '../services/create-widget.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-custom-view-grid',
  templateUrl: './custom-view-grid.component.html',
  styleUrls: ['./custom-view-grid.component.css'],
})
export class CustomViewGridComponent implements OnChanges 
{
  @Input() widgetConfigs: any[] = [];
  @Input() Name: string;
  @Input() MaxHeight: number;
  @Input() MaxWidth: number;
  @Input() aliasSubject: Subject<number>;

  @ViewChild('dynamic', {
    read: ViewContainerRef
}) viewContainerRef: ViewContainerRef;

  private _inputFile: string;
  private ErrorMessage: string;
  private fullWidth: boolean[] = [];

  constructor(
    private pageService: CustomViewPageService)
  { }

  ///////////////////////////////////////////////////////
  // ngOnChanges
  //
  // Called when inputFile changes
  ///////////////////////////////////////////////////////
  ngOnChanges(changes: SimpleChanges)
  {
    if (this.inputFile != null)
    {
      // the input file changed.  Get the contents of the file...
      this.pageService.getPage(this.inputFile).subscribe((response: string[]) =>
      {
        this.ErrorMessage = "";
        try
        {
          // combine the array of strings into a single string with spaces.  Then convert that to JSON.
          let obj = JSON.parse(response.join(" "));

          this.Name = obj.name;

          this.widgetConfigs = obj.widgets;
        }
        catch (ex)
        {
          console.log("Error converting text file to JSON");
          this.ErrorMessage = ex.toString();
        }
      });
    }
  }

  @Input()
  set inputFile(inputFile: string)
  {
    this._inputFile = inputFile;
  }

  get inputFile(): string
  {
    return this._inputFile;
  }

  onFullWidth(i: number)
  {
    this.fullWidth[i] = true;
  }
}
