import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { bufferTime } from "rxjs/operators";

interface ConvertTSendToString<TSend>
{
	(param1: TSend): string;
}

@Injectable({
	providedIn: 'root'
})
export class BufferTimeHttpGet<TSend, TReceive>
{
	/**
	 * Feed in items convertable to a HttpParams.  These are sent as a HTTP Get.
	 */
	oneShotInput = new Subject<TSend>();

	/**
	 * The result of the HTTP Get is returned.
	 */
	oneShotOutput = new Subject<TReceive[]>();

	private url: string;
	private bufferTimePeriodMs: number;
	private numOfParametersPerRequest: number;

	constructor(
		private http: HttpClient,
	)
	{ }


	configure(
		url: string,
		convertTSendToString: ConvertTSendToString<TSend>,
		bufferTimePeriodMs: number,
		numOfParametersPerRequest: number)
	{
		this.url = url;
		this.bufferTimePeriodMs = bufferTimePeriodMs;
		this.numOfParametersPerRequest = numOfParametersPerRequest;

		// gather the one-shots every second
		this.oneShotInput.pipe(bufferTime(this.bufferTimePeriodMs))
			.subscribe((pidOneShotArr) =>
			{
				// if there are no requests, skip this round.
				if (pidOneShotArr.length == 0)
				{
					return;
				}

				// get the array of PIDs
				let httpParams = new HttpParams();
				let numOfHttpParams = 0;

				pidOneShotArr.forEach((p, i) =>
				{
					let str = convertTSendToString(p);
					httpParams = httpParams.append("pid[" + i + "]", str);
					numOfHttpParams++;

					// send groups of this.numOfParametersPerRequest
					if (numOfHttpParams >= this.numOfParametersPerRequest)
					{
						this.sendHttpOneShot(httpParams);
						httpParams = new HttpParams();
						numOfHttpParams = 0;
					}
				});

				// send any leftovers
				if (numOfHttpParams > 0)
				{
					this.sendHttpOneShot(httpParams);
				}
			});
	}

	private sendHttpOneShot(httpParams: HttpParams)
	{
		// create the HTTP Get and subscribe to it to send it out.
		this.http.get<TReceive[]>(this.url, { params: httpParams }).subscribe(response =>
		{
			// we got a response.
			// send the PID Updates out the oneShotOutput queue.
			this.oneShotOutput.next(response);
		});
	}
}
