import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

// https://zefoy.github.io/ngx-color-picker/
@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.css']
})
export class ColorPickerComponent implements OnInit
{

  @Input() color: string;
  @Output() colorChange = new EventEmitter();
  @Input() colorPicker: string;

  // keep a local copy of the color so the parent does not update when the color changes before it is selected.
  myColor: string;

  constructor() { }

  ngOnInit()
  {
    this.myColor = this.color;
  }

  onColorPickerSelect(event: string)
  {
    this.colorChange.emit(event);
  }

}
