import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export class MatrixData
{
	// group
	name: string;

	// site
	alias: number;

	commandConfirm: boolean;

	// Custom View
	view: any;

	public constructor(init?: Partial<MatrixData>)
	{
		// this.sites = [];
		this.name = "";
		this.alias = 0;
		this.view = {

		};

		Object.assign(this, init);
	}
}

export class AccordianTabData
{
	name: string;
	Pages: AccordianTabData[];
	Groups: MatrixData[];

	public constructor(init?: Partial<AccordianTabData>)
	{
		this.Pages = [];
		this.Groups = [];
		this.name = "New Tab";
		Object.assign(this, init);
	}
}

@Injectable({
	providedIn: 'root'
})
export class GroupPanelService
{
	path: string = 'api/Pages/GroupPanel';
	global: string = 'api/Pages/GlobalTabs';
	globalWritable: string = 'api/Pages/GlobalTabsWritable';
	userWriteable: string = 'api/Pages/CanWriteUserFile';

	constructor(private http: HttpClient,
		@Inject('BASE_URL') private baseUrl: string) { }

	//////////////////////////////////////////////////////////////////////////
	// get
	//
	// Get the current Group Panel Data.
	//////////////////////////////////////////////////////////////////////////
	get(): Observable<AccordianTabData[]>
	{
		return this.http.get<AccordianTabData[]>(this.baseUrl + this.path);
	}

	/**
	 * Get an observable to the global tabs.
	 */
	getGlobal(): Observable<AccordianTabData[]>
	{
		return this.http.get<AccordianTabData[]>(this.baseUrl + this.global);
	}

	updateGlobal(data: AccordianTabData[]): Observable<AccordianTabData[]>
	{
		return this.http.post<AccordianTabData[]>(this.baseUrl + this.global, data);
	}

	/**
	 * Get an observable to the global tabs writeable state.
	 */
	getGlobalWritable(): Observable<boolean>
	{
		return this.http.get<boolean>(this.baseUrl + this.globalWritable);
	}

	canWriteUserFile(): Observable<boolean>
	{
		return this.http.get<boolean>(this.baseUrl + this.userWriteable);
	}

	//////////////////////////////////////////////////////////////////////////
	// update
	//
	// Update the Group Panel Data to the passed in data.
	//////////////////////////////////////////////////////////////////////////
	public update(body: AccordianTabData[]): Observable<AccordianTabData[]>
	{
		// let str = JSON.stringify(body);

		return this.http.put<AccordianTabData[]>(this.baseUrl + this.path, body);
	}

}
