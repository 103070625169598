import { Component, OnInit, SecurityContext, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { CustomViewWidgetComponent, CustomViewWidgetData } from '../custom-view-widget.component';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UniqueIdService } from '../../unique-id.service';

export class Configuration extends CustomViewWidgetData
{
  url: string;

  refreshEnable: boolean;
  refreshTime: number;
}

@Component({
  selector: 'app-iframe-widget',
  templateUrl: './iframe-widget.component.html',
  styleUrls: ['./iframe-widget.component.css']
})
export class IframeWidgetComponent extends CustomViewWidgetComponent
{
  private url = "/";

  private refreshEnable: boolean;
  private refreshTime: number;
  private timerHandle: number;

  private id: string;

  @ViewChild('iframe', { static: true }) iframe: ElementRef;

  constructor(
    private sanitizer?: DomSanitizer,
    protected ds?: DataSubscriptionService,
    uniqueId?: UniqueIdService,
  )
  {
    super(ds);

    if (uniqueId)
    {
      this.id = uniqueId.get();
    }
  }

  Configure(obj: Configuration): CustomViewWidgetComponent
  {
    super.Configure(obj);

    this.url = obj.url;
    this.refreshEnable = obj.refreshEnable;
    this.refreshTime = obj.refreshTime;

    this.RefreshTimer();

    return this;
  }
  static GetType(): string { return "IFrame"; }


  /**
   * start the interval timer if enabled.
   */
  private RefreshTimer()
  {
    if (this.refreshEnable)
    {
      this.timerHandle = window.setInterval(() => { this.RefreshImage(); }, this.refreshTime * 1000);
    }
  }


  /**
   * If the original image url has no parameters, add "?date=date()", otherwise add "&date=date()"
   */
  private RefreshImage()
  {
    const iframe = document.getElementById(this.id) as HTMLIFrameElement;

    if (iframe)
    {
      iframe.src = "https://" + this.url;
    }
  }

}
