import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LineConfiguration } from './strip-chart-configuration.model';

@Component({
  selector: 'app-strip-chart-line-property',
  templateUrl: './strip-chart-line-property.component.html',
  styleUrls: ['./strip-chart-line-property.component.css']
})
export class StripChartLinePropertyComponent implements OnInit
{

  @Input() lineCfg: LineConfiguration = new LineConfiguration(false);
  @Output() change = new EventEmitter();

  constructor() { }

  ngOnInit()
  {
  }

  onChange()
  {
    this.change.emit();
  }

  onNewSiteSelected(newValue: number)
  {
    this.lineCfg.unitId = "" + newValue;
    this.onChange();
  }
  onSelectedChannels(channels: number)
  {
    this.lineCfg.channel = channels[0];
    this.onChange();
  }
}
