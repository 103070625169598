import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commandColor'
})
export class CommandColorPipe implements PipeTransform
{

  static commandColorMap = [
    "#000000", // kBacklightOff = 0,
    "#BC1010", //  kBacklightRed, (Axia Red)
    "#0EB300", //  kBacklightGreen, (Axia Green)
    "#FFA500", //  kBacklightYellow
  ];

  ///////////////////////////////////////////////////////////////////////////
  // transform
  //
  // Transform a command button color to a RGB hex string.
  ///////////////////////////////////////////////////////////////////////////
  transform(value: number): string
  {
    // if the value is out of bounds...
    if (value >= CommandColorPipe.commandColorMap.length ||
      value < 0 ||
      isNaN(value))
    {
      return "error";
    }

    return CommandColorPipe.commandColorMap[value];
  }

}
