import { Component, OnInit, Input } from '@angular/core';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { ConnectedOverlayPositionChange } from '@angular/cdk/overlay';

@Component({
  selector: 'app-group-summary-colors',
  templateUrl: './group-summary-colors.component.html',
  styleUrls: ['./group-summary-colors.component.css']
})
export class GroupSummaryColorsComponent implements OnInit
{
  @Input() critical: number;
  @Input() warning: number;
  @Input() unconnected: number;
  @Input() showUnconnected: boolean;

  constructor() { }

  ngOnInit()
  {
    if (this.critical == null)
    {
      this.critical = 0;
    }
    if (this.warning == null)
    {
      this.warning = 0;
    }
    // if we don't know if it is unconnected, it is unconnected.
    if (this.unconnected == null)
    {
      this.unconnected = 1;
    }
  }

}
