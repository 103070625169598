import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, ViewChild, Inject, ComponentFactoryResolver, ViewContainerRef, ComponentRef } from '@angular/core';
import { CustomViewWidgetComponent } from '../../custom-view-widget/custom-view-widget.component';
import { CustomViewPageListChangedService } from '../../services/custom-view-page-list-changed.service';
import { CustomViewPropertyComponent } from '../../custom-view-widget/custom-view-property.component';

/**
 * Show a tile selection and then display the property grid for the tile.
 * Have an Add Tiles button that will output the configuration of the tile.
 */
@Component({
  selector: 'app-add-tiles',
  templateUrl: './add-tiles.component.html',
  styleUrls: [
    './add-tiles.component.css',
    '../../contextMenu.css',
  ]
})
export class AddTilesComponent implements OnInit, OnChanges
{
  //private hideForm: boolean = true;

  @Input() index: number;
  @Input() addInitialTile: boolean;
  @Input() startingNewPage: boolean;

  @Output() addTiles = new EventEmitter();
  @Output() paste = new EventEmitter();

  BackgroundColor: string;
  showMenu = false;
  showAddNewTile = false;

  /////////////////////////////////////////////////////////
  // constructor
  /////////////////////////////////////////////////////////
  constructor(
    private pageEmitter: CustomViewPageListChangedService,
    @Inject(ComponentFactoryResolver) private factoryResolver: ComponentFactoryResolver,
  )
  {
  }

  ngOnInit() { }

  /////////////////////////////////////////////////////////
  // ngOnChanges
  /////////////////////////////////////////////////////////
  ngOnChanges(changes: SimpleChanges): void
  {
    if (this.addInitialTile != null)
    {
      if (this.addInitialTile)
      {
        //this.hideForm = false;
        this.showAddNewTile = true;
      }
    }
  }

  onClick(event)
  {
    if (event.ctrlKey == false)
    {
      //this.hideForm = false;
      this.showMenu = true;
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Add the index of where the tiles should go and pass up the chain.
   * @param $event an object with an array of tile configurations to add.
   */
  onAddTiles(event: any)
  {
    try
    {
      event.index = this.index;
      this.addTiles.emit(event);
      //this.hideForm = true;
      this.showAddNewTile = false;
    }
    catch (ex)
    {
      console.log(ex);
    }
  }
  onAddTile(event: any)
  {
    this.showMenu = false;
    this.showAddNewTile = true;
    event.stopPropagation();
  }

  onPasteTile(event: any)
  {
    this.showMenu = false;
    this.paste.emit();
    event.stopPropagation();
  }

  /**
   * The Add New Tile Dialog closed.  Hide it.
   * @param $event the click event
   */
  onClose()
  {
    // if this is the initial tile, then we need to update the page lists.
    if (this.addInitialTile)
    {
      this.pageEmitter.emitter.emit();
    }

    //this.hideForm = true;
    this.showAddNewTile = false;
  }

  ////////////////////////////////////////////////////////////
  // IsAddTileEnabled
  ////////////////////////////////////////////////////////////
  IsAddTileEnabled(): boolean
  {
    // if everything looks good, enable.
    return true;
  }

}
