import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { CustomViewWidgetComponent, CustomViewWidgetData } from '../custom-view-widget.component';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { DataOneShotService } from '../../services/data-one-shot.service';
import { CommandService, CommandData } from '../../services/command.service';
import { AngularMeterWidgetComponent } from '../angular-meter-widget/angular-meter-widget.component';
import { StatusLabelWidgetComponent } from '../status-label-widget/status-label-widget.component';
import { CommandButtonWidgetComponent } from '../command-button-widget/command-button-widget.component';
import { SiteConfiguration, SiteWidgetComponent } from '../site-widget/site-widget.component';
import { zip } from 'rxjs';

export class Configuration extends SiteConfiguration
{
  channel: number;
  confirmation: boolean;
  meterFontSize: number = 8;
  statusFontSize: number = 8;
  commandFontSize: number = 8;
}

// Meter Status Command Channel
@Component({
  selector: 'app-mscchannel',
  templateUrl: './mscchannel.component.html',
  styleUrls: ['./mscchannel.component.css']
})
export class MSCChannelComponent extends SiteWidgetComponent implements OnInit, AfterViewInit
{
  meterConfiguration;
  statusConfiguration;
  commandConfiguration;

  // channel adaptor
  private channel: number;
  private confirmation: boolean;
  private meterFontSize: number;
  private statusFontSize: number;
  private commandFontSize: number;

  private isMeterAllowed: boolean;
  private isStatusAllowed: boolean;
  private isCommandAllowed: boolean;

  @ViewChild(AngularMeterWidgetComponent) meter: AngularMeterWidgetComponent;
  @ViewChild(StatusLabelWidgetComponent) status: StatusLabelWidgetComponent;
  @ViewChild(CommandButtonWidgetComponent) command: CommandButtonWidgetComponent;

  constructor(
    protected ds?: DataSubscriptionService,
    protected oneshot?: DataOneShotService,
    private cdRef?: ChangeDetectorRef,
  ) 
  {
    super(ds, oneshot);
  }

  ngOnInit()
  {
    super.ngOnInit();
  }

  ngAfterViewInit()
  {
    this.GetChannelPermissions();

  }

  ///////////////////////////////////////////////////////////////////////////
  // GetType
  //
  // Overrides the base CustomViewWidget GetType.
  ///////////////////////////////////////////////////////////////////////////
  static GetType(): string { return "M/S/C Channel"; }

  ///////////////////////////////////////////////////////////////////////////
  // Configure
  //
  // Overrides the CustomViewWidgetComponent to configure the angular meter
  // specific parameters.  Then call the CustomViewWidgetComponent's Configure.
  ///////////////////////////////////////////////////////////////////////////
  Configure(obj: Configuration): CustomViewWidgetComponent
  {
    super.Configure(obj);

    this.channel = obj.channel;
    this.confirmation = obj.confirmation;

    this.meterFontSize = obj.meterFontSize;
    this.statusFontSize = obj.statusFontSize;
    this.commandFontSize = obj.commandFontSize;

    this.GetChannelPermissions();

    return this;
  }

  GetChannelPermissions()
  {
    // get the 3 isAllowed
    let mIsAllowed = this.oneshot.getPermission(this.site, "Analog", this.channel)
    let sIsAllowed = this.oneshot.getPermission(this.site, "Status", this.channel)
    let cIsAllowed = this.oneshot.getPermission(this.site, "Output", this.channel)

    // get the 3 permissions.  Zip will complete when all 3 have arrived.  The "values" is an array of each value.
    zip(mIsAllowed, sIsAllowed, cIsAllowed).subscribe((values) =>
    {
      // unpack the permissions
      this.isMeterAllowed = values[0];
      this.isStatusAllowed = values[1];
      this.isCommandAllowed = values[2];

      // re-evaluate the template to show the M/S/C components.
      this.cdRef.detectChanges();

      this.BuildSubComponents();
    });
  }

  ///////////////////////////////////////////////////////////////////////////
  // BuildSubComponents
  //
  // Set the configuration of each subcomponent, which will cause Angular to
  // rebuild them.
  ///////////////////////////////////////////////////////////////////////////
  BuildSubComponents(): void
  {
    // the height of the block is a multiple of 5.
    // assign the meter 2/5 of the height
    // assign the status 1/5 of the height
    // assign the command 2/5 of the height


    if (this.isMeterAllowed)
    {
      this.meterConfiguration = {
        type: "Angular Meter",
        width: this.width,
        height: this.height * 2 / 5,
        site: this.site,
        channel: this.channel,
        fontSize: this.meterFontSize,
      };
      if (this.meter != null)
      {
        this.meter.Configure(this.meterConfiguration);
        this.meter.StartSubscription();
      }
    }

    if (this.isStatusAllowed)
    {
      this.statusConfiguration = {
        type: "Status Label",
        width: this.width,
        height: this.height * 1 / 5,
        condition: 0, // status channel
        site: this.site,
        channel: this.channel,
        fontSize: this.statusFontSize,
      };

      if (this.status != null)
      {
        this.status.Configure(this.statusConfiguration);
        this.status.StartSubscription();
      }
    }

    if (this.isCommandAllowed)
    {
      this.commandConfiguration = {
        type: "Command Button",
        width: this.width,
        height: this.height * 2 / 5,
        site: this.site,
        channel: this.channel,
        confirmation: this.confirmation,
        fontSize: this.commandFontSize,
      };

      if (this.command != null)
      {
        this.command.Configure(this.commandConfiguration);
        this.command.StartSubscription();

        if (this.preventClicks)
        {
          this.command.PreventClicks();
        }
      }
    }
  }


  PreventClicks()
  {
    this.preventClicks = true;
  }

}
