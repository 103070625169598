import { Component, OnInit, Input, Output, EventEmitter, Inject, ComponentFactoryResolver, ComponentRef, ViewChild, ViewContainerRef } from '@angular/core';
import { CustomViewPropertyComponent } from '../../custom-view-widget/custom-view-property.component';
import { CustomViewWidgetComponent } from '../../custom-view-widget/custom-view-widget.component';

@Component({
  selector: 'app-add-new-tile-dialog',
  templateUrl: './add-new-tile-dialog.component.html',
  styleUrls: ['./add-new-tile-dialog.component.css',
    '../../LightBox.css']
})
export class AddNewTileDialogComponent implements OnInit
{

  @Input() hide: boolean;

  @Output() close = new EventEmitter();
  @Output() addTiles = new EventEmitter();

  @ViewChild('propertyGrid', {
    read: ViewContainerRef
}) viewContainerRef: ViewContainerRef; // the location to insert the property component.
  private myComponent: ComponentRef<CustomViewPropertyComponent<CustomViewWidgetComponent>>; // the property component.

  constructor(
    @Inject(ComponentFactoryResolver) private factoryResolver: ComponentFactoryResolver,
  ) { }

  ngOnInit()
  {
  }

  /**
   * The user wants to close the lightbox.  Tell the parent to close us.
   */
  onClose($event: Event)
  {
    this.close.emit();

    $event.preventDefault();
    $event.stopPropagation();
  }

  ////////////////////////////////////////////////////////////////
  // onWidgetChanged
  //
  // the selected type of tile changed.
  // Create this property grid
  // Clear any previous property grid.
  // insert the new one.
  ////////////////////////////////////////////////////////////////
  onWidgetChanged(widgetType: any)
  {
    // create a factory to create the component
    const factory = this.factoryResolver.resolveComponentFactory(widgetType);

    // create the component.
    this.myComponent = factory.create(this.viewContainerRef.parentInjector) as ComponentRef<CustomViewPropertyComponent<CustomViewWidgetComponent>>;

    this.myComponent.instance.multiselect = true;

    // clear the view container
    this.viewContainerRef.clear();

    // insert the new componenet.
    this.viewContainerRef.insert(this.myComponent.hostView);
  }

  /**
   * Called when the user clicks the Add Tiles button. 
   * Emit the configuration for the new tiles.  Tell the parent to close us.
   * Stop the click event from bubbling.
   * @param $event the click event.
   */
  onAddTiles($event)
  {
    try
    {
      let tiles = this.myComponent.instance.getConfigurationArray();

      this.addTiles.emit({
        tiles: tiles,
      });
      this.close.emit();
    }
    catch (ex)
    {
      console.log(ex);
    }
    $event.preventDefault();
    $event.stopPropagation();
  }


  onEnter(event: Event)
  {
    event.stopPropagation();
    event.preventDefault()
  }
}
