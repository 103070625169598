import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export class AlarmData
{
  alarmId: number;
  channel: number;
  severity: string;
  type: string;
  message: string;
  startTime: string;
  inactiveTime: string;
  clearedTime: string;
  siteId: string; // was number
  siteName: string;
  cleared: boolean;

  // calculated properties
  duration: number;
}

@Injectable({
  providedIn: 'root'
})
export class AlarmService
{

  ///////////////////////////////////////////////////////////////////////////
  // constructor
  ///////////////////////////////////////////////////////////////////////////
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string)
  { }

  ///////////////////////////////////////////////////////////////////////////
  // getAlarms
  //
  // Get the alarms for a list of sites
  ///////////////////////////////////////////////////////////////////////////
  getAlarms(sites?: string[] | string): Observable<AlarmData[]>
  {
    let httpParams = new HttpParams();

    let siteList = [];

    // create the array of sites
    if (sites instanceof Array)
    {
      siteList.push(...sites);
    }
    else if (typeof sites === "string")
    {
      siteList.push(sites);
    }

    siteList.forEach((value, i) =>
    {
      httpParams = httpParams.append("unit[" + i + "]", value);
    });

    // console.log("getAlarms");
    return this.http.get<AlarmData[]>(this.baseUrl + 'api/Alarm/Units', { params: httpParams });
  }

  ///////////////////////////////////////////////////////////////////////////
  // getAll
  //
  // Get all the alarms for all the units.
  ///////////////////////////////////////////////////////////////////////////
  getAll(): Observable<AlarmData[]>
  {
    // console.log("get all alarms");
    return this.http.get<AlarmData[]>(this.baseUrl + 'api/Alarm/GetAll');
  }

  ///////////////////////////////////////////////////////////////////////////
  // getPage
  //
  // Get 1 page of alarms.
  ///////////////////////////////////////////////////////////////////////////
  getPage(page: number): Observable<AlarmData[]>
  {
    // console.log("get page " + page + " of alarms");

    let httpParams = new HttpParams();

    httpParams = httpParams.append("page", "" + page);

    return this.http.get<AlarmData[]>(this.baseUrl + 'api/Alarm/GetPage', { params: httpParams });
  }

  ///////////////////////////////////////////////////////////////////////////
  // ClearAlarm
  //
  // Clear this alarm.
  ///////////////////////////////////////////////////////////////////////////
  ClearAlarm(a: AlarmData)
  {
    let obj: any = {};

    obj.siteId = a.siteId;
    obj.alarmId = a.alarmId

    // console.log("ClearAlarm");
    return this.http.post(this.baseUrl + 'api/Alarm/ClearAlarm', obj);
  }

  ///////////////////////////////////////////////////////////////////////////
  // ClearAllAlarms
  //
  // Clear all the alarms on a list of sites.
  ///////////////////////////////////////////////////////////////////////////
  ClearAllAlarms(sites: number[])
  {
    return this.http.post(this.baseUrl + 'api/Alarm/ClearAllAlarms', sites);
  }
}
