import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowNavBarService
{

  private subject = new BehaviorSubject<boolean>(true);
  constructor() { }

  get(): Subject<boolean>
  {
    return this.subject;
  }
}
