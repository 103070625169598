import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { OptionDef } from '../OptionDef';

export class TileSize
{
  width: number;
  height: number;
  name: string;

  constructor(n: string, w: number, h: number)
  {
    this.width = w;
    this.height = h;
    this.name = n;
  }
}

export const SingleWide_HalfHeight: TileSize = { width: 70, height: 30, name: "Single Wide, Half Height" };
export const DoubleWide_HalfHeight: TileSize = { width: 150, height: 30, name: "Double Wide, Half Height" };
export const FourxWide_HalfHeight: TileSize = { width: 310, height: 30, name: "4x Wide, Half Height" };

export const SingleWide_SingleHeight: TileSize = { width: 70, height: 70, name: "Single Wide, Single Height" };
export const DoubleWide_SingleHeight: TileSize = { width: 150, height: 70, name: "Double Wide, Single Height" };
export const FourxWide_SingleHeight: TileSize = { width: 310, height: 70, name: "4x Wide, Single Height" };

export const DoubleWide_DoubleHeight: TileSize = { width: 150, height: 150, name: "Double Wide, Double Height" };
export const FourxWide_DoubleHeight: TileSize = { width: 310, height: 150, name: "4x Wide, Double Height" };

export const FourxWide_FourxHeight: TileSize = { width: 310, height: 310, name: "4x Wide, 4x Height" };
export const EightxWide_FourxHeight: TileSize = { width: 630, height: 310, name: "8x Wide, 4x Height" };

export const FullWidth_QuarterHeight: TileSize = { width: Number.POSITIVE_INFINITY, height: 10, name: "Full Width, Quarter Height" };
export const FullWidth_HalfHeight: TileSize = { width: Number.POSITIVE_INFINITY, height: 30, name: "Full Width, Half Height" };
export const FullWidth_SingleHeight: TileSize = { width: Number.POSITIVE_INFINITY, height: 70, name: "Full Width, Single Height" };
export const FullWidth_DoubleHeight: TileSize = { width: Number.POSITIVE_INFINITY, height: 150, name: "Full Width, Double Height" };
export const FullWidth_FourxHeight: TileSize = { width: Number.POSITIVE_INFINITY, height: 310, name: "Full Width, 4x Height" };
export const FullWidth_EightxHeight: TileSize = { width: Number.POSITIVE_INFINITY, height: 630, name: "Full Width, 8x Height" };

@Component({
  selector: 'app-size-selector',
  templateUrl: './size-selector.component.html',
  styleUrls: ['./size-selector.component.css']
})
export class SizeSelectorComponent implements OnInit, OnChanges
{
  @Input() currentSize: number;
  @Input() includedSizes: TileSize[];

  @Output() newSizeSelected = new EventEmitter<TileSize>();

  private sizeOptions: OptionDef[] = [];


  calledOnChanges: boolean = false;
  constructor() { }

  ngOnInit(): void { }

  /////////////////////////////////////////////////////////////////////////////
  // ngOnChanges
  //
  // Called when the @Inputs change.
  /////////////////////////////////////////////////////////////////////////////
  ngOnChanges(changes: SimpleChanges): void
  {
    this.calledOnChanges = true;

    this.sizeOptions.length = 0;

    if (typeof this.includedSizes !== "undefined")
    {
      let needToSave = true;

      // loop through the sizes and if a match is found to the width and height, set the size index.
      this.includedSizes.forEach((element, index) =>
      {
        this.sizeOptions.push(new OptionDef("" + index, element.name));
      });

      if (needToSave)
      {
        this.onChange();
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // onChange
  //
  // Emit the width, height, and name of the new size.
  /////////////////////////////////////////////////////////////////////////////
  onChange()
  {
    this.newSizeSelected.emit(this.includedSizes[this.currentSize]);
  }
}
