import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { AccountService } from "../services/account.service";
import { Observable } from "rxjs";

/**
 * Intercept all HTTP destined for /api/* and add the Authorization Bearer header.
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor
{
	constructor(
		private accountService: AccountService,
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
	{
		const user = this.accountService.userValue;
		const isLoggedin = user && user.token;
		const isApiUrl = request.url.includes("/api/");
		if (isLoggedin && isApiUrl)
		{
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${user.token}`
				}
			})
		}

		return next.handle(request);
	}
}