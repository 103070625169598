import { OnChanges } from '@angular/core';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { CustomViewWidgetComponent } from '../custom-view-widget.component';

// Components that extend this need to include the "host:{...}" in that Component to call setWidth when the window changes size.

@Component({
  template: '',
  host: { // If the host window resizes, call setWidth()
    '(window:resize)': 'setWidth()'
  },
})
export class FullWidthCustomViewWidgetComponent extends CustomViewWidgetComponent implements OnChanges, OnInit, AfterViewChecked
{
  // the subclass must have a div with fullWidthBox as an anchor (#fullWidthBox) as the outer div.
  @ViewChild('fullWidthBox', { static: false }) fullWidthBox: ElementRef;

  constructor(
    protected ds?: DataSubscriptionService,
    protected cdRef?: ChangeDetectorRef,
  )
  {
    super(ds);
  }

  ngOnChanges()
  {
    // this.setWidth();
  }

  ngOnInit()
  {
    //this.setWidth();
  }

  ngAfterViewChecked()
  {
    // this.setWidth();
  }

  /**
    * Set the width to multiples of tile sizes.
    */
  setWidth()
  {
    // this allows the view to redraw so fullWidthBox is assigned.
    this.cdRef.detectChanges();

    if (this.fullWidthBox != null)
    {
      let width = this.fullWidthBox.nativeElement.getBoundingClientRect().width;

      // the first element is 70 pixels.  Then 10 pixel gap, then another element of 70.
      // so the series of widths is 70, 150, 230, 310, 390...
      // the series is 70 + n*80
      // 
      let n = Math.floor((width - 70) / 80);
      if (this.width != 70 + n * 80)
      {
        this.width = 70 + n * 80;
      }
    }
  }
}
