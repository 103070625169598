import { Component, OnInit, Input, ViewChild, ViewContainerRef, ComponentRef, Inject, ComponentFactoryResolver, Output, EventEmitter } from '@angular/core';
import { CustomViewWidgetData, CustomViewWidgetComponent } from '../custom-view-widget/custom-view-widget.component';
import { CustomViewPropertyComponent } from '../custom-view-widget/custom-view-property.component';
import { ListOfWidgetsService } from '../services/list-of-widgets.service';

@Component({
  selector: 'app-show-multi-edit-property-grid',
  templateUrl: './show-multi-edit-property-grid.component.html',
  styleUrls: ['./show-multi-edit-property-grid.component.css']
})
export class ShowMultiEditPropertyGridComponent implements OnInit
{

  // pass in the configuration object
  @Input() configurationList: CustomViewWidgetData[];
  @Output() close = new EventEmitter();

  private configuration: any;

  @ViewChild('propertyGrid', {
    read: ViewContainerRef,
    static: true
  }) viewContainerRef: ViewContainerRef;
  private myComponent: ComponentRef<CustomViewPropertyComponent<CustomViewWidgetComponent>>;

  /**
   * 
   * @param listOfWidgets 
   * @param factoryResolver 
   */
  constructor(
    private listOfWidgets: ListOfWidgetsService,
    @Inject(ComponentFactoryResolver) private factoryResolver: ComponentFactoryResolver,
  ) { }

  /**
   * 
   */
  ngOnInit()
  {
    this.reduceConfiguration();
    this.createPropertyGrid();
  }

  /**
   * Reduce the list of configurations to a set of common settings.
   */
  reduceConfiguration()
  {

    // create an empty property object to build up the common properties.
    let multiProperty = {};

    if (this.configurationList != null) 
    {
      // loop through the properties of the first configuration...
      for (let p in this.configurationList[0])
      {
        let isPresentInAll = true;

        // loop through all the tiles (including the 0th one because it is harder to ignore that one)...
        for (let t of this.configurationList)
        {
          // if this configuration does not have this property...
          if (!t.hasOwnProperty(p))
          {
            // clear the flag
            isPresentInAll = false;
          }
        }

        // if all the configurations have the property...
        if (isPresentInAll)
        {
          //do they have the same value?
          let firstValue = this.configurationList[0][p];
          let isSameValue = true;

          // loop thorugh the tiles...
          for (let t of this.configurationList)
          {
            // if the value is different...
            if (t[p] !== firstValue)
            {
              // clear the flag
              isSameValue = false;
            }
          }

          // if the value is the same...
          if (isSameValue)
          {
            // set the multiproperty to the value.
            multiProperty[p] = firstValue;
          }
        }
      }

      // console.log(multiProperty);
      this.configuration = multiProperty;
    }
  }

  /**
   * Use the configuration and List Of Widgets to create the property grid.
   */
  private createPropertyGrid()
  {
    if (this.configuration == null) return;

    let widgetType = this.listOfWidgets.getComponent(this.configuration.type);

    // create a factory to create the component
    const factory = this.factoryResolver.resolveComponentFactory(widgetType);

    // create the component.
    this.myComponent = factory.create(this.viewContainerRef.parentInjector) as ComponentRef<CustomViewPropertyComponent<CustomViewWidgetComponent>>;

    this.myComponent.instance.multiEdit = true;

    // clear the view container
    this.viewContainerRef.clear();

    // insert the new componenet.
    this.viewContainerRef.insert(this.myComponent.hostView);

    // configure the property grid
    this.myComponent.instance.Configure(this.configuration);
  }

  /**
   * OK button was clicked.
   * Copy the properties from the configuration to the configuration list items.
   */
  onOk()
  {
    // loop through the configurations...
    this.configurationList.forEach((item) => 
    {
      // loop through the properties...
      for (let p in this.configuration)
      {
        // assign the value of the property to this item's property.
        item[p] = this.configuration[p];
      }
    });

    this.close.emit();
  }

  /** 
   * Cancel button was clicked.
   */
  onCancel()
  {
    this.close.emit();
  }

}
