import { Component, OnInit, Input, HostBinding, ComponentFactoryResolver, Inject, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { RealTimeUpdate } from '../custom-view-widget.component';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatrixData } from '../../services/group-panel.service';
import { Subject } from 'rxjs';
import { ClipboardService } from '../../services/clipboard.service';
import { DataOneShotService } from '../../services/data-one-shot.service';
import { EditModeService } from '../../services/edit-mode.service';
import { AlarmCounter } from './alarm-counter';
import { MosaicAlarmCounter } from './mosaic-alarm-counter';

@Component({
  selector: 'app-group-summary',
  templateUrl: './group-summary.component.html',
  styleUrls: ['./group-summary.component.css',
    '../../contextMenu.css',
    '../../custom-view-grid/custom-view-grid.component.css'] // Copied the classes from custom-view-grid CSS because the modal dialog was not finding them.
})
export class GroupSummaryComponent extends MosaicAlarmCounter implements OnInit, OnChanges
{
  // optional inputs
  /**
   * @deprecated This was to show sub-groups
   */
  @Input() showSubGroups: Subject<MatrixData>; // call .next() to tell the parent to show a set of groups.

  @Input() clickable: boolean = true;
  @Input() nameEdit = false;
  @Input() reload: number;
  @Input() selected: boolean;
  @Input() allowEdit: boolean = true;

  @Output() dataChanged = new EventEmitter<MatrixData>();
  @Output() delete = new EventEmitter();
  @Output() paste = new EventEmitter();
  @Output() editEvent = new EventEmitter();
  @Output() nameChange = new EventEmitter<string>();
  @Output() changeDraggable = new EventEmitter();

  private edit: boolean;
  private expandSites: boolean = false;
  private contextMenu = false;
  private fullWidth: boolean[] = [];

  /**
   * @deprecated this was for the sub-groups
   */
  private groupDiv: boolean;

  @HostBinding('class.FullWidthHost') showAccordion: boolean = false;

  constructor(protected ds: DataSubscriptionService,
    protected oneshot: DataOneShotService,
    //private modalService: NgbModal,
    private clipboard: ClipboardService,
    private editModeService: EditModeService,
    @Inject(ComponentFactoryResolver) private factoryResolver: ComponentFactoryResolver) 
  {
    super(ds, oneshot);
  }

  ///////////////////////////////////////////////////////////////////////////
  // ngOnInit
  ///////////////////////////////////////////////////////////////////////////
  ngOnInit() 
  {
    if (this.allowEdit)
    {
      // subscribe to the edit mode.
      this.editModeService.get().subscribe(b =>
      {
        this.edit = b;
        if (!this.edit)
        {
          this.contextMenu = false;
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void
  {
    super.ngOnChanges(changes);

    // this is a group div if the alias is invalid and the view is invalid.
    this.groupDiv = !(this.data.alias > 0 || (this.data.view != null && this.data.view.widgets != null));
  }

  ///////////////////////////////////////////////////////////////////////////
  // onNewData
  //
  // Called when the Group Data is edited.
  ///////////////////////////////////////////////////////////////////////////
  onNewData(data: MatrixData)
  {
    // console.log("onNewData: " + data);

    this.data = data;

    this.dataChanged.emit(this.data);

    this.ds.Remove(this); // remove all previous subscriptions
    this.Subscribe(); // add the new subscriptions.
  }

  ///////////////////////////////////////////////////////////////////////////
  // close
  //
  // Close the modal dialog.
  ///////////////////////////////////////////////////////////////////////////
  close()
  {
    //this.modalService.dismissAll();
    this.expandSites = false;
  }

  onEdit(event)
  {
    this.editEvent.emit();

    this.contextMenu = false;
    event.stopPropagation();
    event.preventDefault()
    return false;
  }

  ///////////////////////////////////////////////////////////////////////////
  // onCut
  ///////////////////////////////////////////////////////////////////////////
  onCut(event)
  {
    this.clipboard.Save("Group", this.data);
    this.delete.emit();

    this.contextMenu = false;
    event.stopPropagation();
    event.preventDefault()
    return false;
  }

  ///////////////////////////////////////////////////////////////////////////
  // onCopy
  ///////////////////////////////////////////////////////////////////////////
  onCopy(event)
  {
    this.clipboard.Save("Group", this.data);

    this.contextMenu = false;
    event.stopPropagation();
    event.preventDefault()
    return false;
  }

  ///////////////////////////////////////////////////////////////////////////
  // onDelete
  ///////////////////////////////////////////////////////////////////////////
  onDelete(event)
  {
    if (this.data == null) return;

    if (confirm("Are you sure you want to delete " + this.data.name + "?"))
    {
      this.delete.emit();
    }

    this.contextMenu = false;
    event.stopPropagation();
    event.preventDefault()
    return false;
  }

  ///////////////////////////////////////////////////////////////////////////
  // onPaste
  ///////////////////////////////////////////////////////////////////////////
  onPaste(event)
  {
    this.paste.emit();

    this.contextMenu = false;
    event.stopPropagation();
    event.preventDefault()
    return false;
  }

  ///////////////////////////////////////////////////////////////////////////
  // onNameChange
  //
  // When the name is edited, signal the parent to save the new name.
  ///////////////////////////////////////////////////////////////////////////
  onNameChange()
  {
    if (this.data == null) return;

    this.nameChange.emit(this.data.name);
  }

  onContextMenu(event)
  {
    if (this.clickable && this.edit)
    {
      this.contextMenu = true;
      event.stopPropagation();
      return false;
    }
    else if (!this.clickable)
    {
      event.stopPropagation();
      return false;
    }
  }

  onContextMenuClose(event)
  {
    this.contextMenu = false;
    event.stopPropagation();
    event.preventDefault()
    return false;
  }

  onFullWidth(i: number)
  {
    this.fullWidth[i] = true;
  }

  onNameFocus()
  {
    // console.log("focus");
    this.changeDraggable.emit(false);
  }
  onNameBlur()
  {
    // console.log("blur");
    this.changeDraggable.emit(true);
  }

  onNameClick(event)
  {
    event.stopPropagation();
    event.preventDefault()
    return false;
  }
}
