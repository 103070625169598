import { Component, ComponentFactoryResolver, Inject } from '@angular/core';
import { StatusChannelAdaptor } from '../../../services/status-channel-adaptor';
import { TileSize, DoubleWide_SingleHeight, FourxWide_DoubleHeight, SingleWide_SingleHeight } from '../../../size-selector/size-selector.component';
import { CustomViewPropertyComponent } from '../../custom-view-property.component';
import { LedButtonComponent, LedButtonConfiguration } from '../led-button.component';

@Component({
  selector: 'app-led-button-property',
  templateUrl: './led-button-property.component.html',
  styleUrls: ['./led-button-property.component.css']
})
export class LedButtonPropertyComponent extends CustomViewPropertyComponent<LedButtonComponent>
{

  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  ) 
  {
    super(LedButtonComponent, factoryResolver);

    let cfg = new LedButtonConfiguration();

    cfg.type = LedButtonComponent.GetType();

    this.Configure(cfg);

    this.myConfiguration.statusAdaptor = new StatusChannelAdaptor({});
  }

  readonly sizes: TileSize[] =
    [
      SingleWide_SingleHeight,
      DoubleWide_SingleHeight,
      FourxWide_DoubleHeight
    ];

  GetDefaultSize(): number { return 1; }

  /**
   * 
   * @param $event array of channels, but there is only 1 (multiselect=false)
   */
  onSelectedStatusChannels($event)
  {
    this.myConfiguration.statusAdaptor.channel = $event[0];
  }

  onNewCommandSiteSelected($event)
  {
    this.myConfiguration.commandSite = $event;
    this.onChange();
  }

  onSelectedCommandChannels($event)
  {
    if (this.myConfiguration.commandChannel != $event)
    {
      this.myConfiguration.commandChannel = $event[0];

      this.onChange();
    }
  }
}
