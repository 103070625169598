import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { SitePropertyComponent } from '../site-property/site-property.component';
import { CustomViewWidgetComponent } from '../custom-view-widget.component';

@Component({
  selector: 'app-channel-property',
  templateUrl: './channel-property.component.html',
  styleUrls: ['./channel-property.component.css']
})
export class ChannelPropertyComponent<T extends CustomViewWidgetComponent> extends SitePropertyComponent<T>
{
  private channels: number[];

  constructor(
    component: new () => T,
    factoryResolver: ComponentFactoryResolver,
  )
  {
    super(component, factoryResolver);
  }

  /**
   * return an array of the configuration for each channel.
   */
  getConfigurationArray(): any[]
  {
    let retVal = [];

    this.channels.forEach(d =>
    {
      // clone the configuration.
      let clone = JSON.parse(JSON.stringify(this.myConfiguration));

      // set the channel
      clone.channel = d;

      // add to the retVal
      retVal.push(clone);
    });

    return retVal;
  }

  /**
   * the selected channels changed.
   * @param $event array of channel numbers
   */
  onSelectedChannels($event)
  {
    this.myConfiguration.channel = $event[0]; // assign the configuration channel to the first item.  This allows the tile to be drawn.
    this.channels = $event; // save the array so later we can return an array of configuration objects.
    this.onChange();
  }

}
