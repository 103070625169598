import { Component, OnInit, Input, Inject, ComponentFactoryResolver, ComponentRef, ViewChild, ViewContainerRef } from '@angular/core';
import { CustomViewWidgetData, CustomViewWidgetComponent } from '../custom-view-widget.component';
import { ListOfWidgetsService } from '../../services/list-of-widgets.service';
import { CustomViewPropertyComponent } from '../custom-view-property.component';

/**
 * Show the property grid for a configuration.
 */
@Component({
  selector: 'app-show-property-grid',
  templateUrl: './show-property-grid.component.html',
  styleUrls: ['./show-property-grid.component.css']
})
export class ShowPropertyGridComponent implements OnInit
{
  // pass in the configuration object
  @Input() configuration: CustomViewWidgetData;

  @ViewChild('propertyGrid', {
    read: ViewContainerRef,
    static: true
  }) viewContainerRef: ViewContainerRef;
  private myComponent: ComponentRef<CustomViewPropertyComponent<CustomViewWidgetComponent>>;

  /**
   * 
   * @param listOfWidgets 
   * @param factoryResolver 
   */
  constructor(
    private listOfWidgets: ListOfWidgetsService,
    @Inject(ComponentFactoryResolver) private factoryResolver: ComponentFactoryResolver,
  ) { }

  /**
   * 
   */
  ngOnInit()
  {
    this.createPropertyGrid();
  }

  /**
   * Use the configuration and List Of Widgets to create the property grid.
   */
  private createPropertyGrid()
  {
    if (this.configuration == null) return;

    let widgetType = this.listOfWidgets.getComponent(this.configuration.type);

    // create a factory to create the component
    const factory = this.factoryResolver.resolveComponentFactory(widgetType);

    // create the component.
    this.myComponent = factory.create(this.viewContainerRef.parentInjector) as ComponentRef<CustomViewPropertyComponent<CustomViewWidgetComponent>>;

    this.myComponent.instance.multiselect = false;

    // clear the view container
    this.viewContainerRef.clear();

    // insert the new componenet.
    this.viewContainerRef.insert(this.myComponent.hostView);

    // configure the property grid
    this.myComponent.instance.Configure(this.configuration);
  }

}
