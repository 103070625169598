import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rgb2Hex'
})
export class Rgb2HexPipe implements PipeTransform
{
  ///////////////////////////////////////////////////////////////////////////
  // transform
  //
  // Transforms a rgb or rgba string to hex color.
  // rgb string = "rgb(1, 2, 3)"
  // rgba string = "rgb(1, 2, 3, 4)"
  // hex color = "#123456"
  ///////////////////////////////////////////////////////////////////////////
  transform(rgbStr: string): string
  {
    if (rgbStr == null)
    {
      return "";
    }

    //https://jsfiddle.net/Mottie/xcqpF/1/light/
    let rgb = rgbStr.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);

    return (rgb && rgb.length === 4) ? "#" +
      ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
      ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
      ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
  }
}
