import { Component, Input, Inject, OnChanges, SimpleChanges, ViewChild, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { CreateWidgetService } from '../services/create-widget.service';

@Component({
  selector: 'app-create-widget',
  templateUrl: './create-widget.component.html',
  styleUrls: ['./create-widget.component.css',
    '../custom-view-widget/custom-view-widget.component.css',]
})
export class CreateWidgetComponent implements OnChanges
{
  @Input() configuration: any;
  @Output() fullWidth = new EventEmitter();

  fullWidthFlag: boolean;

  @ViewChild('dynamic', {
    read: ViewContainerRef,
    static: true
  }) viewContainerRef: ViewContainerRef;

  //////////////////////////////////////////////////////////////////////////
  // constructor
  //////////////////////////////////////////////////////////////////////////
  constructor(@Inject(CreateWidgetService) private createWidgetService)
  { }

  //////////////////////////////////////////////////////////////////////////
  // ngOnChanges
  //
  // Called when the @Input() variables change.
  //////////////////////////////////////////////////////////////////////////
  ngOnChanges(changes: SimpleChanges): void
  {
    if (this.viewContainerRef != null)
    {
      // clear the old tile
      this.viewContainerRef.clear();
      // create a new tile in the container
      this.createWidgetService.addWidget(this.configuration, this.viewContainerRef);

      if (this.configuration.fullWidth)
      {
        this.fullWidth.emit();
        this.fullWidthFlag = true;
      }
    }
  }
}
