import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { CustomViewWidgetComponent } from '../custom-view-widget.component';
import { CustomViewPropertyComponent } from '../custom-view-property.component';

/**
 * Base class for a property component that has a site selector.
 */
@Component({
  selector: 'app-site-property',
  templateUrl: './site-property.component.html',
  styleUrls: ['./site-property.component.css']
})
export class SitePropertyComponent<T extends CustomViewWidgetComponent> extends CustomViewPropertyComponent<T>
{
  constructor(
    component: new () => T,
    factoryResolver: ComponentFactoryResolver,
  )
  {
    super(component, factoryResolver);
  }

  /**
   * 
   * @param $event the site id of the selected site.
   */
  onNewSiteSelected($event)
  {
    this.myConfiguration.site = $event;
    this.onChange();
  }

}
