import { Component, ViewChild, ElementRef } from '@angular/core';
import { CustomViewWidgetComponent, CustomViewWidgetData } from '../custom-view-widget.component';
import { DataSubscriptionService } from '../../services/data-subscription.service';

export class Configuration extends CustomViewWidgetData
{
  label: string;
}

@Component({
  selector: 'app-label-widget',
  templateUrl: './label-widget.component.html',
  styleUrls: ['./label-widget.component.css']
})
export class LabelWidgetComponent extends CustomViewWidgetComponent
{
  @ViewChild('label1') label1: ElementRef;
  @ViewChild('labelouter') labelouter: ElementRef;

  private label: string;
  private myTooltip = "";

  ///////////////////////////////////////////////////////////////////////////
  // constructor
  ///////////////////////////////////////////////////////////////////////////
  constructor(protected ds?: DataSubscriptionService) 
  {
    super(ds);
  }

  ///////////////////////////////////////////////////////////////////////////
  // GetType
  ///////////////////////////////////////////////////////////////////////////
  static GetType(): string { return "Label"; }

  ///////////////////////////////////////////////////////////////////////////
  // Configure
  ///////////////////////////////////////////////////////////////////////////
  Configure(obj: Configuration): CustomViewWidgetComponent
  {
    this.label = obj.label;
    setTimeout(() => this.myTooltip = this.TooltipText(this.labelouter, this.label1), 0);

    if (typeof obj.fontSize === "undefined")
    {
      obj.fontSize = 16;
    }
    this.fontSize = obj.fontSize;

    this.numOfLines = Math.floor((obj.height - 10) / this.fontSize);

    super.Configure(obj);

    return this;
  }

  ///////////////////////////////////////////////////////////////////////////
  // TooltipText
  ///////////////////////////////////////////////////////////////////////////
  private TooltipText(outer: ElementRef, inner: ElementRef): string
  {
    // at the beginning of the render, the element is not assigned yet.  Check for null to just return.
    if (inner == null)
    {
      return "";
    }

    // if the outer element's height is less than the inner element's height, then it is clipped.  Show the tooltip.
    if (outer.nativeElement.clientHeight < inner.nativeElement.clientHeight)
    {
      return this.label;
    }

    // the element is big enough to fit everything, there is no tooltip.  Return a blank label.
    return "";
  }

}
