import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomViewPageService
{

  constructor(private http: HttpClient) { }

  public getPage(page: string)
  {
    return this.http.get("/api/DisplayPage/Read/" + page);
  }
  public updatePage(page: string, body: string)
  {
    return this.http.post("/api/DisplayPage/Update/" + page, body);
  }
  public createPage(page: string, body: string)
  {
    return this.http.post("/api/DisplayPage/CreatePage/" + page, body);
  }
  public createRandomPage(body: string)
  {
    return this.http.post<any>("/api/DisplayPage/CreateRandomPage/", body);
  }
  public deletePage(page: string)
  {
    return this.http.delete("/api/DisplayPage/DeletePage/" + page);
  }
}
