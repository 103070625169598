import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material.module';
import { ChartsModule } from '../../node_modules/ng2-charts';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { CustomViewGridComponent } from './custom-view-grid/custom-view-grid.component';
import { CustomViewPageComponent } from './custom-view-page/custom-view-page.component';
import { AngularMeterWidgetComponent } from './custom-view-widget/angular-meter-widget/angular-meter-widget.component';
import { CustomViewWidgetComponent } from "./custom-view-widget/custom-view-widget.component";
import { ColorPipe } from './pipes/color.pipe';
import { LabelWidgetComponent } from './custom-view-widget/label-widget/label-widget.component';
import { StatusLabelWidgetComponent } from './custom-view-widget/status-label-widget/status-label-widget.component';
import { MeterTextWidgetComponent } from './custom-view-widget/meter-text-widget/meter-text-widget.component';
import { LiveGridComponent } from './edit-custom-view-page/live-grid/live-grid.component';
import { EditWidgetComponent } from './edit-custom-view-page/edit-widget/edit-widget.component';
import { OffsetPipe } from './pipes/offset.pipe';
import { AddTilesComponent } from './edit-custom-view-page/add-tiles/add-tiles.component';
import { SiteSelectorComponent } from './site-selector/site-selector.component';
import { CommandButtonWidgetComponent } from './custom-view-widget/command-button-widget/command-button-widget.component';
import { AlarmTypePipe } from './pipes/alarm-type.pipe';
import { AlarmSeverityPipe } from './pipes/alarm-severity.pipe';
import { CreateWidgetComponent } from './create-widget/create-widget.component';
import { SizeSelectorComponent } from './size-selector/size-selector.component';
import { ScaledValuePipe } from './pipes/scale-value.pipe';
import { ContrastColorPipe } from './pipes/contrast-color.pipe';
import { Rgb2HexPipe } from './pipes/rgb2-hex.pipe';
import { ChannelSelectorComponent } from './channel-selector/channel-selector.component';
import { MacroWidgetComponent } from './custom-view-widget/macro-widget/macro-widget.component';
import { MacroStatusPipe } from './pipes/macro-status.pipe';
import { MSCChannelComponent } from './custom-view-widget/mscchannel/mscchannel.component';
import { StripChartComponent } from './custom-view-widget/strip-chart/strip-chart.component';

import { RequestCache } from './caching-interceptor/request-cache';
import { CachingInterceptor } from './caching-interceptor/caching-interceptor';
import { MeterStatusCommandFullRowComponent } from './custom-view-widget/meter-status-command-full-row/meter-status-command-full-row.component';
import { CommandColorPipe } from './pipes/command-color.pipe';
import { RelayActivePipe } from './pipes/relay-active.pipe';
import { MeterStatusCommandFullRowHeaderComponent } from './custom-view-widget/meter-status-command-full-row-header/meter-status-command-full-row-header.component';
import { GroupSummaryComponent } from './custom-view-widget/group-summary/group-summary.component';
import { GroupSummaryPanelComponent } from './custom-view-widget/group-summary-panel/group-summary-panel.component';
import { DefaultSiteWidgetComponent } from './custom-view-widget/default-site-widget/default-site-widget.component';
import { SiteMultiSelectorComponent } from './site-multi-selector/site-multi-selector.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GroupSummaryPanelWrapperComponent } from './group-summary-panel-wrapper/group-summary-panel-wrapper.component';
import { AllSitesPageComponent } from './all-sites-page/all-sites-page.component';
import { ImageWidgetComponent } from './custom-view-widget/image-widget/image-widget.component';
import { GroupSummaryColorsComponent } from './group-summary-colors/group-summary-colors.component';
import { QuickViewComponent } from './quick-view/quick-view.component';
import { EventTableComponent } from './event-table/event-table.component';
import { AlarmTableComponent } from './alarm-table/alarm-table.component';
import { SecondsToTimePipe } from './pipes/seconds-to-time.pipe';
import { LocalDateTimePipe } from './pipes/local-date-time.pipe';
import { ValidDatePipe } from './pipes/valid-date.pipe';
import { MacroTableComponent } from './macro-table/macro-table.component';
import { ModalSiteLinkComponent } from './modal-site-link/modal-site-link.component';
import { SiteSelectorDialogComponent } from './site-selector-dialog/site-selector-dialog.component';
import { SiteSelectionXofYComponent } from './site-selection-xof-y/site-selection-xof-y.component';
import { ListOfWidgetsComponent } from './list-of-widgets/list-of-widgets.component';
import { CustomViewPropertyComponent } from './custom-view-widget/custom-view-property.component';
import { StripChartPropertyComponent } from './custom-view-widget/strip-chart/strip-chart-property.component';
import { StripChartLinePropertyComponent } from './custom-view-widget/strip-chart/strip-chart-line-property.component';
import { AngularMeterPropertyComponent } from './custom-view-widget/angular-meter-widget/angular-meter-property/angular-meter-property.component';
import { StatusLabelPropertyComponent } from './custom-view-widget/status-label-widget/status-label-property/status-label-property.component';
import { SitePropertyComponent } from './custom-view-widget/site-property/site-property.component';
import { ChannelPropertyComponent } from './custom-view-widget/channel-property/channel-property.component';
import { CommandButtonPropertyComponent } from './custom-view-widget/command-button-widget/command-button-property/command-button-property.component';
import { DefaultSitePropertyComponent } from './custom-view-widget/default-site-widget/default-site-property/default-site-property.component';
import { ImagePropertyComponent } from './custom-view-widget/image-widget/image-property/image-property.component';
import { LabelPropertyComponent } from './custom-view-widget/label-widget/label-property/label-property.component';
import { MacroPropertyComponent } from './custom-view-widget/macro-widget/macro-property/macro-property.component';
import { MeterStatusCommandFullRowPropertyComponent } from './custom-view-widget/meter-status-command-full-row/meter-status-command-full-row-property/meter-status-command-full-row-property.component';
import { MeterStatusCommandFullRowHeaderPropertyComponent } from './custom-view-widget/meter-status-command-full-row-header/msc-full-row-header-property/meter-status-command-full-row-header-property.component';
import { MeterTextPropertyComponent } from './custom-view-widget/meter-text-widget/meter-text-property/meter-text-property.component';
import { MSCChannelPropertyComponent } from './custom-view-widget/mscchannel/mscchannel-property/mscchannel-property.component';
import { ShowPropertyGridComponent } from './custom-view-widget/show-property-grid/show-property-grid.component';
import { TimerWidgetComponent } from './custom-view-widget/timer-widget/timer-widget.component';
import { TimerPropertyComponent } from './custom-view-widget/timer-widget/timer-property/timer-property.component';
import { StatusChannelAdaptorComponent } from './status-channel-adaptor/status-channel-adaptor.component';
import { ChannelAdaptorComponent } from './channel-adaptor/channel-adaptor.component';
import { ClearAlarmsWidgetComponent } from './custom-view-widget/clear-alarms-widget/clear-alarms-widget.component';
import { ClearAlarmsPropertyComponent } from './custom-view-widget/clear-alarms-widget/clear-alarms-property/clear-alarms-property.component';
import { AlarmEventMacroButtonComponent } from './alarm-event-macro-button/alarm-event-macro-button.component';
import { ModalAlarmDialogComponent } from './modal-alarm-dialog/modal-alarm-dialog.component';
import { ModalEventDialogComponent } from './modal-event-dialog/modal-event-dialog.component';
import { ModalMacroDialogComponent } from './modal-macro-dialog/modal-macro-dialog.component';
import { SiteWidgetComponent } from './custom-view-widget/site-widget/site-widget.component';
import { IframeWidgetComponent } from './custom-view-widget/iframe-widget/iframe-widget.component';
import { IframePropertyComponent } from './custom-view-widget/iframe-widget/iframe-property/iframe-property.component';
import { SafePipe } from './pipes/safe.pipe';
import { AddNewTileDialogComponent } from './edit-custom-view-page/add-new-tile-dialog/add-new-tile-dialog.component';
import { SingletonHeaderComponent } from './singleton-header/singleton-header.component';
import { EditCustomViewModalComponent } from './edit-custom-view-modal/edit-custom-view-modal.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { EditSiteViewModalComponent } from './edit-site-view-modal/edit-site-view-modal.component';
import { MultiEditModalComponent } from './multi-edit-modal/multi-edit-modal.component';
import { ShowMultiEditPropertyGridComponent } from './show-multi-edit-property-grid/show-multi-edit-property-grid.component';
import { TabSummaryComponent } from './tab-summary/tab-summary.component';
import { GlobalTabSectionComponent } from './global-tab-section/global-tab-section.component';
import { ModalEditTileDialogComponent } from './modal-edit-tile-dialog/modal-edit-tile-dialog.component';
import { FullWidthCustomViewWidgetComponent } from './custom-view-widget/full-width-custom-view-widget/full-width-custom-view-widget.component';
import { CommandButtonHorizontalComponent } from './custom-view-widget/meter-status-command-full-row/command-button-horizontal/command-button-horizontal.component';
import { UrlWidgetComponent } from './custom-view-widget/url-widget/url-widget.component';
import { UrlPropertyComponent } from './custom-view-widget/url-widget/url-property/url-property.component';
import { LabelFullWidthComponent } from './custom-view-widget/label-full-width/label-full-width.component';
import { LabelFullWidthPropertyComponent } from './custom-view-widget/label-full-width/label-full-width-property/label-full-width-property.component';
import { AlertComponent } from './alert/alert.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ErrorInterceptor } from './error-interceptor/error-interceptor';
import { JwtInterceptor } from './jwt-interceptor/jwt-interceptor';
import { NumOfBanksPipe } from './pipes/num-of-banks.pipe';
import { AllSitesTabComponent } from './all-sites-tab/all-sites-tab.component';
import { Test001Component } from './test001/test001.component';
import { TestTilesComponent } from './test-tiles/test-tiles.component';
import { MaxWidthHeightComponent } from './max-width-height/max-width-height.component';
import { LedButtonComponent } from './custom-view-widget/led-button/led-button.component';
import { LedButtonPropertyComponent } from './custom-view-widget/led-button/led-button-property/led-button-property.component';

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        CustomViewGridComponent,
        CustomViewPageComponent,
        AngularMeterWidgetComponent,
        ColorPipe,
        LabelWidgetComponent,
        StatusLabelWidgetComponent,
        MeterTextWidgetComponent,
        LiveGridComponent,
        EditWidgetComponent,
        OffsetPipe,
        AddTilesComponent,
        SiteSelectorComponent,
        CommandButtonWidgetComponent,
        AlarmTypePipe,
        AlarmSeverityPipe,
        CreateWidgetComponent,
        SizeSelectorComponent,
        CustomViewWidgetComponent,
        ScaledValuePipe,
        ContrastColorPipe,
        Rgb2HexPipe,
        ChannelSelectorComponent,
        MacroWidgetComponent,
        MacroStatusPipe,
        MSCChannelComponent,
        MeterStatusCommandFullRowComponent,
        CommandColorPipe,
        RelayActivePipe,
        MeterStatusCommandFullRowHeaderComponent,
        GroupSummaryComponent,
        GroupSummaryPanelComponent,
        DefaultSiteWidgetComponent,
        SiteMultiSelectorComponent,
        GroupSummaryPanelWrapperComponent,
        AllSitesPageComponent,
        ImageWidgetComponent,
        GroupSummaryColorsComponent,
        QuickViewComponent,
        EventTableComponent,
        AlarmTableComponent,
        SecondsToTimePipe,
        LocalDateTimePipe,
        ValidDatePipe,
        MacroTableComponent,
        ModalSiteLinkComponent,
        SiteSelectorDialogComponent,
        StripChartComponent,
        SiteSelectionXofYComponent,
        ListOfWidgetsComponent,
        SitePropertyComponent,
        StripChartPropertyComponent,
        StripChartLinePropertyComponent,
        AngularMeterPropertyComponent,
        StatusLabelPropertyComponent,
        SitePropertyComponent,
        ChannelPropertyComponent,
        CommandButtonPropertyComponent,
        DefaultSitePropertyComponent,
        ImagePropertyComponent,
        LabelPropertyComponent,
        MacroPropertyComponent,
        MeterStatusCommandFullRowPropertyComponent,
        MeterStatusCommandFullRowHeaderPropertyComponent,
        MeterTextPropertyComponent,
        MSCChannelPropertyComponent,
        ShowPropertyGridComponent,
        TimerWidgetComponent,
        TimerPropertyComponent,
        StatusChannelAdaptorComponent,
        ChannelAdaptorComponent,
        ClearAlarmsWidgetComponent,
        ClearAlarmsPropertyComponent,
        AlarmEventMacroButtonComponent,
        ModalAlarmDialogComponent,
        ModalEventDialogComponent,
        ModalMacroDialogComponent,
        SiteWidgetComponent,
        IframeWidgetComponent,
        IframePropertyComponent,
        SafePipe,
        AddNewTileDialogComponent,
        SingletonHeaderComponent,
        EditCustomViewModalComponent,
        ColorPickerComponent,
        EditSiteViewModalComponent,
        MultiEditModalComponent,
        ShowMultiEditPropertyGridComponent,
        TabSummaryComponent,
        GlobalTabSectionComponent,
        ModalEditTileDialogComponent,
        FullWidthCustomViewWidgetComponent,
        AlertComponent,
        LoginComponent,
        CommandButtonHorizontalComponent,
        UrlWidgetComponent,
        UrlPropertyComponent,
        LabelFullWidthComponent,
        LabelFullWidthPropertyComponent,
        NumOfBanksPipe,
        AllSitesTabComponent,
        Test001Component,
        TestTilesComponent,
        MaxWidthHeightComponent,
        LedButtonComponent,
        LedButtonPropertyComponent,
    ],
    imports: [
        NgbModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatDialogModule,
        BrowserAnimationsModule,
        MaterialModule,
        ChartsModule,
        ReactiveFormsModule,
        ColorPickerModule,
        RouterModule.forRoot([
            { path: '', component: CustomViewPageComponent, pathMatch: "full", canActivate: [AuthGuardService] },
            { path: 'QuickView', component: QuickViewComponent, canActivate: [AuthGuardService] },
            { path: 'login', component: LoginComponent },
            { path: '**', redirectTo: '' }
        ], { relativeLinkResolution: 'legacy' }),
        DragDropModule,
        MatRadioModule,
    ],
    providers: [
        RequestCache,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CachingInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
