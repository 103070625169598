import { Component, OnInit, Input, Inject, OnChanges, SimpleChanges, ViewChild, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { CreateWidgetService } from '../../services/create-widget.service';
import { CustomViewWidgetComponent, CustomViewWidgetData } from '../../custom-view-widget/custom-view-widget.component';

export class ClickOnWidgetData
{
  data: CustomViewWidgetData;
  widget: CustomViewWidgetComponent;
  index: number;
  ctrlKey: boolean;
}

@Component({
  selector: 'app-edit-widget',
  templateUrl: './edit-widget.component.html',
  styleUrls: ['./edit-widget.component.css',
    '../../contextMenu.css',
  ]
})
export class EditWidgetComponent implements OnChanges
{
  @Input() selected: boolean;
  @Input() configuration: CustomViewWidgetData;
  @Input() index: number;
  @Input() canWrite: boolean;
  @Input() canDelete: boolean;

  @Output() clickOnTile = new EventEmitter<ClickOnWidgetData>();
  @Output() fullWidth = new EventEmitter();

  @Output() cut = new EventEmitter();
  @Output() copy = new EventEmitter();
  @Output() paste = new EventEmitter();
  @Output() addToSelectedItems = new EventEmitter();

  widget: CustomViewWidgetComponent;
  fullWidthFlag: boolean;
  showMenu: boolean;

  @ViewChild('dynamic', {
    read: ViewContainerRef,
    static: true
  }) viewContainerRef: ViewContainerRef;

  constructor(
    @Inject(CreateWidgetService) private createWidgetService
  )
  { }

  ////////////////////////////////////////////////////////////
  // ngOnChanges
  //
  // This is called when the input 'configuration' is changed.
  // Set the location of the new widget. Create the widget.
  ////////////////////////////////////////////////////////////
  ngOnChanges(changes: SimpleChanges) 
  {
    this.viewContainerRef.clear();
    this.widget = this.createWidgetService.addWidget(this.configuration, this.viewContainerRef, true);

    if (this.configuration.fullWidth)
    {
      this.fullWidth.emit();
      this.fullWidthFlag = true;
    }
  }

  ClickOnWidget(event: MouseEvent)
  {
    if (!this.canWrite)
    {
      event.stopPropagation();
      return;
    }

    // emit the important data
    let clickData = new ClickOnWidgetData();
    clickData.data = this.configuration;
    clickData.widget = this.widget;
    clickData.index = this.index;
    clickData.ctrlKey = event.ctrlKey;

    this.clickOnTile.emit(clickData);

    event.stopPropagation();
  }

  onContextMenu()
  {
    this.showMenu = true;
    event.stopPropagation();
    return false;
  }

  onCut($event)
  {
    this.cut.emit();
    this.showMenu = false;
    event.stopPropagation();
    return false;
  }
  onCopy($event)
  {
    this.copy.emit();
    this.showMenu = false;
    event.stopPropagation();
    return false;
  }
  onPaste($event)
  {
    this.paste.emit();
    this.showMenu = false;
    event.stopPropagation();
    return false;
  }
  onAddToSelectedItems($event) 
  {
    this.addToSelectedItems.emit();
    this.showMenu = false;
    event.stopPropagation();
    return false;
  }
}
