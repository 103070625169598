import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numOfBanks'
})
export class NumOfBanksPipe implements PipeTransform
{

  transform(value: number, channelsPerBank: number): number
  {
    return Math.ceil(value / channelsPerBank);
  }
}
