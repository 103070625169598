import { Component, AfterViewInit, OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from '../environments/environment'
import { ShowNavBarService } from './services/show-nav-bar.service';
import { UsernameService } from './services/username.service';
import { AstMemoryEfficientTransformer } from '@angular/compiler';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit
{
  version = environment.version;
  showNavbar: boolean;
  showPage: boolean;

  constructor(
    private usernameService: UsernameService,
    private showNavbarService: ShowNavBarService,
    private cdr: ChangeDetectorRef,
  )
  {
  }

  ngOnInit()
  {
  }
  ngAfterViewInit()
  {
    // // get the username file to make sure we are logged in.  Otherwise, several componenets will try to query for pages and each will pop up a log in prompt.
    // this.usernameService.get().subscribe(() =>
    // {
    //   this.showPage = true;
    //   this.cdr.detectChanges();
    // },
    //   error => { });

    this.showNavbarService.get().subscribe(show =>
    {
      this.showNavbar = show;
      this.cdr.detectChanges();
    });

  }
}
