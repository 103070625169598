import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { zip } from 'rxjs';
import { CommandData, CommandService, MacroCommandData } from '../../services/command.service';
import { DataOneShotService } from '../../services/data-one-shot.service';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { StatusChannelAdaptor } from '../../services/status-channel-adaptor';
import { CustomViewWidgetComponent, CustomViewWidgetData, RealTimeUpdate } from '../custom-view-widget.component';
import { StatusLabelConfiguration, StatusLabelWidgetComponent } from '../status-label-widget/status-label-widget.component';

export class LedButtonConfiguration extends CustomViewWidgetData
{
  statusSite: string;
  statusAdaptor: StatusChannelAdaptor;

  commandSite: string;
  commandType: string;
  commandChannel: number;
  commandConfirmation: boolean;
}

@Component({
  selector: 'app-led-button',
  templateUrl: './led-button.component.html',
  styleUrls: ['./led-button.component.css']
})
export class LedButtonComponent extends CustomViewWidgetComponent implements RealTimeUpdate
{
  @ViewChild(StatusLabelWidgetComponent) status: StatusLabelWidgetComponent;

  private statusSite: string;
  private statusAdaptor: StatusChannelAdaptor;
  private statusFontSize: number;

  private commandSite: string;
  private commandType: string;
  private commandChannel: number;
  private commandConfirmation: boolean;

  commandLabel: string = "";
  raisePressed: boolean = false;

  constructor(
    protected ds?: DataSubscriptionService,
    protected oneshot?: DataOneShotService,
    private cs?: CommandService,
    private cdRef?: ChangeDetectorRef,
  ) 
  {
    super(ds);
  }

  static GetType(): string { return "LED Button"; }

  private isStatusAllowed: boolean;
  private isCommandAllowed: boolean;

  Configure(obj: LedButtonConfiguration): CustomViewWidgetComponent
  {
    super.Configure(obj);

    this.statusSite = obj.statusAdaptor.site;
    this.statusAdaptor = new StatusChannelAdaptor(obj.statusAdaptor);

    this.commandSite = obj.commandSite
    this.commandType = obj.commandType;
    this.commandChannel = obj.commandChannel
    this.commandConfirmation = obj.commandConfirmation;

    this.GetChannelPermissions();

    return this;
  }

  GetChannelPermissions()
  {
    let cIsAllowed = this.oneshot.getPermission(this.commandSite, this.getBlock(this.commandType), this.commandChannel)

    cIsAllowed.subscribe((values) =>
    {
      this.isCommandAllowed = values;

      // re-evaluate the template to show the S/C components.
      this.cdRef.detectChanges();

      this.BuildSubComponents();
    });
  }

  BuildSubComponents(): void
  {
    let statusConfiguration: StatusLabelConfiguration = {
      type: "Status Label",
      width: this.width,
      height: (this.height) * 1 / 4,
      adaptor: this.statusAdaptor,
      site: this.statusSite,
      fontSize: this.fontSize,
      fullWidth: false,
      backgroundColor: this.backgroundColor,
      hideLabel: true,
    };

    this.status.Configure(statusConfiguration);
    this.status.StartSubscription();

    if (this.isCommandAllowed)
    {
      const block = this.getBlock(this.commandType);
      const param = this.getParam(this.commandType);
      this.oneshot.getPidParameter(this.commandSite, block, this.commandChannel, param).subscribe(p => this.commandLabel = p.value);
    }
  }

  getBlock(commandType: string): string
  {
    switch (commandType)
    {
      default:
      case "Raise":
      case "Lower":
        return "Output";
      case "Macro":
        return "Macro";
    }
  }
  getParam(commandType: string): string
  {
    switch (commandType)
    {
      default:
      case "Raise":
        return "OutputLcdRaiseLabel";
      case "Lower":
        return "OutputLcdLowerLabel";
      case "Macro":
        return "MacroName";
    }
  }

  /**
   * 
   */
  protected Subscribe()
  {
    super.Subscribe();

    // this.ds.Add(this, [
    //   {
    //     "site": this.site,
    //     "block": "Macro",
    //     "channel": this.channel,
    //     "pid": "MacroCurrentState"
    //   }]);
  }

  /**
   * 
   * @param site 
   * @param pid 
   * @param channel 
   * @param value 
   */
  Update(site: string, pid: string, channel: number, value: string)
  {
    super.Update(site, pid, channel, value);
  }

  onClick()
  {

    let str = this.commandLabel
    str = str.replace(/\0/g, '').trim();

    // if we are not confirming the command, do it
    // or if we did confirm the command, do it.
    if (!this.commandConfirmation || confirm(str + "?"))
    {
      switch (this.commandType)
      {
        default:
        case "Raise":
          let raiseCmd = new CommandData();

          raiseCmd.site = this.commandSite;
          raiseCmd.channel = this.commandChannel;
          raiseCmd.raiseLower = true;

          this.cs.command(raiseCmd).subscribe();
          break;
        case "Lower":
          let lowerCmd = new CommandData();

          lowerCmd.site = this.commandSite;
          lowerCmd.channel = this.commandChannel;
          lowerCmd.raiseLower = false;

          this.cs.command(lowerCmd).subscribe();
          break;
        case "Macro":
          let macroCmd = new MacroCommandData();
          macroCmd.site = this.commandSite;
          macroCmd.channel = this.commandChannel;
          macroCmd.runStop = true;

          this.cs.macro(macroCmd).subscribe();
          break;
      }
    }
  }

  onRaiseMouseDown($event)
  {
    this.raisePressed = true;
  }
  onRaiseMouseUp($event)
  {
    this.raisePressed = false;
  }
  onRaiseMouseLeave($event)
  {
    this.raisePressed = false;
  }

}
