import { Component, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { CustomViewWidgetComponent, RealTimeUpdate, CustomViewWidgetData } from "../custom-view-widget.component";
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { DataOneShotService } from '../../services/data-one-shot.service';
import { SiteConfiguration, SiteWidgetComponent } from '../site-widget/site-widget.component';
import { ChannelHelper } from '../../services/status-condition-types.service';

export class Configuration extends SiteConfiguration
{
  channel: number;
}

class LimitParams
{
  cx: number;
  cy: number;
  r: number;
  stroke: number;
  width: number;
  dasharray: string;
  dashoffset: string;
}

class TickMark
{
  x1: number = 0;
  y1: number = 0;
  x2: number = 0;
  y2: number = 0;
  labelx1: number;
  labely1: number;
  label: string;
}

@Component({
  selector: 'app-angular-meter-widget',
  templateUrl: './angular-meter-widget.component.html',
  styleUrls: [
    './angular-meter-widget.component.css',
    '../custom-view-widget.component.css',
  ],
  host: {
    '(window:resize)': 'onResize()'
  },
})
export class AngularMeterWidgetComponent extends SiteWidgetComponent implements RealTimeUpdate
{
  private LimitRegions: LimitParams[] = [];
  private tickMarks: TickMark[] = [];
  private minorTickMarks: TickMark[] = [];

  // channel adaptor
  private channel: number;

  // Meter Value
  private value: number;
  public label: string
  public units: string;
  private decimalPoint: number;
  public channelOnline: boolean;

  public labelY: number;
  public unitsY: number;

  // Limits
  readonly rangeDegrees: number = 75;
  readonly startDegrees: number = (270 - this.rangeDegrees / 2);
  readonly numOfTickMarks: number = 6;
  readonly numOfMiorTickMarks: number = 5;

  private lowDisplayValue: number = 0;
  private lowCriticalLimit: number = 10;
  private lowWarningLimit: number = 20;
  private highWarningLimit: number = 80;
  private highCriticalLimit: number = 90;
  private highDisplayValue: number = 100;

  // Colors
  private lowCriticalColor: number;
  private lowWarningColor: number;
  private normalColor: number;
  private highWarningColor: number;
  private highCriticalColor: number;

  // Pointer
  private pointerPivotX: number = 136; // the pivot point below the meter for the pointer
  private pointerPivotY: number = 220;
  private pointerTopX: number = 0; // the end point of the pointer above the band of colors
  private pointerTopY: number = 0;
  private pointerBottomX: number = 0; // the point on the line from pointer1 to pointer 2 where it is 10% above the bottom of the meter
  private pointerBottomY: number = 10;
  private pointerLength: number = 20;
  public pointerWidth: number = 1;
  public pointerColor = 118;

  private clientWidth: number = 10;
  private clientHeight: number = 10;
  private isAllowed: boolean = false;

  ///////////////////////////////////////////////////////////////////////////
  // constructor
  ///////////////////////////////////////////////////////////////////////////
  constructor(protected ds?: DataSubscriptionService,
    protected oneshot?: DataOneShotService,
    private cdRef?: ChangeDetectorRef,
  ) 
  {
    super(ds, oneshot);
  }

  /**
   * Overrides the base CustomViewWidget GetType.
   */
  static GetType(): string { return "Angular Meter"; }

  ///////////////////////////////////////////////////////////////////////////
  // Subscribe
  //
  // Call the datasubscription service to add the subscriptions.
  ///////////////////////////////////////////////////////////////////////////
  protected Subscribe()
  {
    super.Subscribe();

    this.ds.Add(this, [
      {
        "site": this.site,
        "block": "Analog",
        "channel": this.channel,
        "pid": "AnalogScaled"
      },
      {
        "site": this.site,
        "block": "Analog",
        "channel": this.channel,
        "pid": "AnalogState"
      },
    ]);
  }

  ///////////////////////////////////////////////////////////////////////////
  // GetData
  //
  // Get all the PID data to draw the angular meter.
  ///////////////////////////////////////////////////////////////////////////
  GetData()
  {
    // if either are null, return.
    if (this.site == null || this.channel == null)
    {
      return;
    }

    this.oneshot.getPermission(this.site, "Analog", this.channel).subscribe((b) =>
    {

      this.isAllowed = b;
      if (!this.isAllowed)
      {
        return;
      }

      this.oneshot.getPidParameters(this.site, "Analog", this.channel,
        [
          "AnalogName",
          "AnalogUnitsLabel",
          "AnalogHighCriticalLimit",
          "AnalogHighWarningLimit",
          "AnalogLowWarningLimit",
          "AnalogLowCriticalLimit",
          "AnalogDecimalPoint",
          "AnalogHighCriticalColor",
          "AnalogHighWarningColor",
          "AnalogNormalColor",
          "AnalogLowWarningColor",
          "AnalogLowCriticalColor",
          "AnalogMeterMax",
          "AnalogMeterMin",
        ])
        .subscribe((res) =>
        {
          let updateTicks = false;
          let updatePointer = false;
          let updateRegions = false;

          for (let p of res)
          {
            //console.log(p.parameter + ": " + p.value);
            switch (p.parameter)
            {
              case "AnalogName":
                this.label = p.value;
                break;
              case "AnalogUnitsLabel":
                this.units = p.value;
                break;
              case "AnalogHighCriticalLimit":
                this.highCriticalLimit = +p.value;
                updateRegions = true;
                break;
              case "AnalogHighWarningLimit":
                this.highWarningLimit = +p.value;
                updateRegions = true;
                break;
              case "AnalogLowWarningLimit":
                this.lowWarningLimit = +p.value;
                updateRegions = true;
                break;
              case "AnalogLowCriticalLimit":
                this.lowCriticalLimit = +p.value;
                updateRegions = true;
                break;
              case "AnalogDecimalPoint":
                this.decimalPoint = +p.value;
                updateTicks = true;
                break;
              case "AnalogHighCriticalColor":
                this.highCriticalColor = +p.value;
                updateRegions = true;
                break;
              case "AnalogHighWarningColor":
                this.highWarningColor = +p.value;
                updateRegions = true;
                break;
              case "AnalogNormalColor":
                this.normalColor = +p.value;
                updateRegions = true;
                break;
              case "AnalogLowWarningColor":
                this.lowWarningColor = +p.value;
                updateRegions = true;
                break;
              case "AnalogLowCriticalColor":
                this.lowCriticalColor = +p.value;
                updateRegions = true;
                break;
              case "AnalogMeterMax":
                this.highDisplayValue = +p.value;
                updateTicks = true;
                updatePointer = true;
                updateRegions = true;
                break;
              case "AnalogMeterMin":
                this.lowDisplayValue = +p.value;

                if (this.value == null)
                {
                  this.value = this.lowDisplayValue;
                }
                updateTicks = true;
                updatePointer = true;
                updateRegions = true;
                break;
            }
          }

          if (updateRegions) this.CalculateMeterRegions();
          if (updatePointer) this.UpdatePointer();
          if (updateTicks) this.CalculateTickMarks();

          this.CalculateBackgroundColor();
        });
    });

  }

  CalculateMeterRegions()
  {
    // there are 5 regions.  Start with the lowest and work to the middle.
    // Then start at the top and work down.  The last region is the normal region.
    // If a region limit is set to -9999 or 9999, then ignore it.
    let lowerBound = this.lowDisplayValue;
    let higherBound = this.highDisplayValue;

    this.pointerPivotX = this.clientWidth / 2;
    let angle = this.rangeDegrees / 2
    this.pointerLength = (this.clientWidth * .70) / (2 * Math.sin(2 * Math.PI * angle / 360));
    this.pointerPivotY = this.clientHeight * .55 + this.pointerLength;

    // clear the limits
    this.LimitRegions = [];

    // Add the Low Critical Region.
    if (this.lowCriticalLimit > -9999 && this.lowCriticalLimit > lowerBound)
    {
      let l = this.GetBasicArc();

      l.stroke = this.lowCriticalColor;
      l.dashoffset = this.DashOffset(lowerBound);
      l.dasharray = this.DashArray(this.lowCriticalLimit - lowerBound);

      // console.log("LC Offset: " + l.dashoffset);
      // console.log("LC Array: " + l.dasharray);

      this.LimitRegions.push(l);

      lowerBound = this.lowCriticalLimit;
    }

    // Add the Low Warning Region.
    if (this.lowWarningLimit > -9999 && this.lowWarningLimit > lowerBound)
    {
      let l = this.GetBasicArc();

      l.stroke = this.lowWarningColor;
      l.dashoffset = this.DashOffset(lowerBound);//"409";
      l.dasharray = this.DashArray(this.lowWarningLimit - lowerBound);//"41 1215";

      this.LimitRegions.push(l);

      lowerBound = this.lowWarningLimit;
    }

    // Add the High Critical Region.
    if (this.highCriticalLimit < 9999 && this.highCriticalLimit < higherBound)
    {
      let l = this.GetBasicArc();

      l.stroke = this.highCriticalColor;
      l.dashoffset = this.DashOffset(this.highCriticalLimit);// "200";
      l.dasharray = this.DashArray(higherBound - this.highCriticalLimit);//"41 1215";

      this.LimitRegions.push(l);

      higherBound = this.highCriticalLimit;
    }

    // Add the High Warning Region.
    if (this.highWarningLimit < 9999 && this.highWarningLimit < higherBound)
    {
      let l = this.GetBasicArc();

      l.stroke = this.highWarningColor;
      l.dashoffset = this.DashOffset(this.highWarningLimit);
      l.dasharray = this.DashArray(higherBound - this.highWarningLimit); "41 1215";

      this.LimitRegions.push(l);

      higherBound = this.highWarningLimit;
    }

    // Add the Normal Region.
    if (lowerBound < higherBound)
    {
      let l = this.GetBasicArc();

      l.stroke = this.normalColor;
      l.dashoffset = this.DashOffset(lowerBound);
      l.dasharray = this.DashArray(higherBound - lowerBound);

      this.LimitRegions.push(l);
    }

    this.labelY = this.fontSize + this.clientHeight / 50;
    this.unitsY = this.clientHeight * 0.9;

    this.pointerWidth = this.clientWidth / 150;

    this.UpdatePointer();
    this.CalculateTickMarks();
  }

  ///////////////////////////////////////////////////////////////////////////
  // CalculateTickMarks
  //
  // Calculate the tick mark parameters.
  //
  // IN: highDisplayValue, lowDisplayValue, pointerX1, pointerY1, decimalPoint
  // OUT: tickMarks, minorTickMarks
  ///////////////////////////////////////////////////////////////////////////
  CalculateTickMarks()
  {
    let rangeValue = this.highDisplayValue - this.lowDisplayValue;

    this.tickMarks.length = 0;
    this.minorTickMarks.length = 0;

    let tickDecimalPoint = this.CalculateTickDecimalPoint();

    let arcOffset = this.getArcWidth() / 2 + this.clientHeight / 60;

    // calculate the major tick marks.
    for (var i = 0; i < this.numOfTickMarks; i++)
    {
      let t = new TickMark();

      let value = (this.highDisplayValue - this.lowDisplayValue) * i / (this.numOfTickMarks - 1) + this.lowDisplayValue;
      let angle = this.startDegrees + (value - this.lowDisplayValue) * (this.rangeDegrees) / (rangeValue);

      value = value / Math.pow(10, this.decimalPoint); // convert from 4 digit to decimal number

      t.x1 = this.pointerPivotX + (this.pointerLength + arcOffset) * Math.cos(2 * Math.PI * angle / 360) * 1.05;
      t.y1 = this.pointerPivotY + (this.pointerLength + arcOffset) * Math.sin(2 * Math.PI * angle / 360) * 1.05;

      t.x2 = this.pointerPivotX + (this.pointerLength + arcOffset) * Math.cos(2 * Math.PI * angle / 360) * 1.0;
      t.y2 = this.pointerPivotY + (this.pointerLength + arcOffset) * Math.sin(2 * Math.PI * angle / 360) * 1.0;

      t.labelx1 = this.pointerPivotX + (this.pointerLength + arcOffset) * Math.cos(2 * Math.PI * angle / 360) * 1.08;
      t.labely1 = this.pointerPivotY + (this.pointerLength + arcOffset) * Math.sin(2 * Math.PI * angle / 360) * 1.08;

      t.label = "" + value.toFixed(tickDecimalPoint);

      if (Number.isNaN(t.x1) ||
        Number.isNaN(t.x2) ||
        Number.isNaN(t.y1) ||
        Number.isNaN(t.y2))
      {
        t.x1 = 0;
        t.x2 = 0;
        t.y1 = 0;
        t.y2 = 0;
      }
      this.tickMarks.push(t);
    }


    for (var j = 0; j < this.numOfMiorTickMarks * (this.numOfTickMarks - 1); j++)
    {
      if ((j % this.numOfMiorTickMarks) == 0)
      {
        continue;
      }

      let t = new TickMark();

      let value = (this.highDisplayValue - this.lowDisplayValue) * j / (this.numOfMiorTickMarks * (this.numOfTickMarks - 1)) + this.lowDisplayValue;
      let angle = this.startDegrees + (value - this.lowDisplayValue) * (this.rangeDegrees) / (rangeValue);

      t.x1 = this.pointerPivotX + (this.pointerLength + arcOffset) * Math.cos(2 * Math.PI * angle / 360) * 1.025;
      t.y1 = this.pointerPivotY + (this.pointerLength + arcOffset) * Math.sin(2 * Math.PI * angle / 360) * 1.025;

      t.x2 = this.pointerPivotX + (this.pointerLength + arcOffset) * Math.cos(2 * Math.PI * angle / 360) * 1.0;
      t.y2 = this.pointerPivotY + (this.pointerLength + arcOffset) * Math.sin(2 * Math.PI * angle / 360) * 1.0;

      t.label = "" + value;

      if (Number.isNaN(t.x1) ||
        Number.isNaN(t.x2) ||
        Number.isNaN(t.y1) ||
        Number.isNaN(t.y2))
      {
        t.x1 = 0;
        t.x2 = 0;
        t.y1 = 0;
        t.y2 = 0;
      }

      this.minorTickMarks.push(t);
    }
  }

  getArcWidth()
  {
    return this.clientWidth / 30;
  }

  ///////////////////////////////////////////////////////////////////////////
  // CalculateTickDecimalPoint
  // 
  // Based on the display value, calculate how precise to show the tick mark
  // value.
  ///////////////////////////////////////////////////////////////////////////
  CalculateTickDecimalPoint(): number
  {
    let high = this.highDisplayValue / Math.pow(10, this.decimalPoint);
    let low = this.lowDisplayValue / Math.pow(10, this.decimalPoint);

    let tickDiff = high - low;

    // if the total range is less than 0.01, use all 4 decimal places in the ticks
    if (tickDiff <= 0.01)
    {
      return 4;
    }
    else if (tickDiff <= 0.1)
    {
      return 3;
    }
    else if (tickDiff <= 1)
    {
      return 2;
    }
    else if (tickDiff <= 10)
    {
      return 1;
    }
    else // if the range is bigger than 10, no decimal places
    {
      return 0;
    }
  }

  ARC_RADIUS: number = 0.6;

  ///////////////////////////////////////////////////////////////////////////
  // GetBasicArc
  //
  // Return the basic values of the arc segment of the angular meter.
  ///////////////////////////////////////////////////////////////////////////
  GetBasicArc(): LimitParams
  {
    let l = new LimitParams();

    // t.x1 = this.pointerPivotX + this.pointerLength * Math.cos(2 * Math.PI * angle / 360) * 1.05;
    // t.y1 = this.pointerPivotY + this.pointerLength * Math.sin(2 * Math.PI * angle / 360) * 1.05;

    l.cx = this.pointerPivotX;//this.clientWidth / 2;
    l.cy = this.pointerPivotY;//this.clientWidth * .95;//.95;
    l.r = this.pointerLength;//this.clientWidth * this.ARC_RADIUS;
    l.width = this.clientWidth / 30;

    return l;
  }

  //////////////////////////////////////////////////////////////////////
  // DashOffset
  //
  // The offset is the number of units to rotate counter-clockwise from 
  // 0 degrees (East, Right) to start the dashes.
  //////////////////////////////////////////////////////////////////////
  DashOffset(value: number): string
  {
    let valuePerDegree: number = (this.highDisplayValue - this.lowDisplayValue) / this.rangeDegrees;
    let valueInDegrees = (value - this.lowDisplayValue) / valuePerDegree;
    let regionStartInDegrees = this.startDegrees + valueInDegrees;

    let circumference = 2 * Math.PI * this.pointerLength;// this.clientWidth * this.ARC_RADIUS;//0.8;
    let sweepStart = (360 - regionStartInDegrees) * circumference / 360;

    return "" + sweepStart;
  }

  //////////////////////////////////////////////////////////////////////
  // DashArray
  //
  // The DashArray is 2 numbers.  The first is the number of units to
  // draw the color.  The second is the number of units to not draw.
  // The total needs to be the number of units in the circle.
  //////////////////////////////////////////////////////////////////////
  DashArray(valueSweepLength: number): string
  {
    let valuePerDegree: number = (this.highDisplayValue - this.lowDisplayValue) / this.rangeDegrees; // value per degree
    let pixelPerCirle = 2 * Math.PI * this.pointerLength;//this.clientWidth * this.ARC_RADIUS;//.8;

    let degrees = valueSweepLength / valuePerDegree;
    let sweepLengthPixels = degrees / 360 * pixelPerCirle;
    // return pixel units
    return "" + sweepLengthPixels + " " + (pixelPerCirle - sweepLengthPixels);
  }

  ///////////////////////////////////////////////////////////////////////////
  // Configure
  //
  // Overrides the CustomViewWidgetComponent to configure the angular meter
  // specific parameters.  Then call the CustomViewWidgetComponent's Configure.
  ///////////////////////////////////////////////////////////////////////////
  Configure(obj: Configuration): CustomViewWidgetComponent
  {
    //obj.fontSize = obj.fontSize * 1.5;
    super.Configure(obj);

    this.channel = obj.channel;

    this.GetData();

    return this;
  }

  ///////////////////////////////////////////////////////////////////////////
  // Update
  //
  // Implements the RealTimeUpdate interface.
  // The Data Subscription Service will call this when new values are received.
  // The AnalogScaledValue is the only real-time PID for the angular meter.
  // Update the value and pointer.
  ///////////////////////////////////////////////////////////////////////////
  Update(site: string, pid: string, channel: number, value: string)
  {
    super.Update(site, pid, channel, value);
    if (pid == "AnalogScaled")
    {
      this.UpdateAnalogScaled(value);
    }
    if (pid == "AnalogState")
    {
      this.channelOnline = ChannelHelper.convertChannelStateToOnline(value);
    }
  }

  UpdateAnalogScaled(value: string)
  {
    // Update the value.
    this.value = +value; // convert the string to a number.
    this.UpdatePointer();
  }

  ////////////////////////////////////////////////////////////////////
  // UpdatePointer 
  //
  // IN: highDisplayValue, lowDisplayValue, value, pointerX1, pointerY1, pointerLength, clientHeight
  // OUT: pointerX2, pointerY2, pointerX3, pointerY3
  ////////////////////////////////////////////////////////////////////
  UpdatePointer(): void
  {
    let angle: number;
    let rangeValue = this.highDisplayValue - this.lowDisplayValue;

    // if the value is below the low display value, clip it to the start 
    if (this.value < this.lowDisplayValue)
    {
      angle = this.startDegrees;
    }
    // if the value is above the high display value, clip it to the end
    else if (this.value > this.highDisplayValue)
    {
      angle = this.startDegrees + this.rangeDegrees;
    }
    // otherwise, calculate the intermediate degree value.
    else
    {
      angle = this.startDegrees + (this.value - this.lowDisplayValue) * (this.rangeDegrees) / (rangeValue);
    }

    // we know the pivot point and the angle and the length.
    // calculate the end of the pointer line
    let arcOffset = this.getArcWidth() / 2 + this.clientHeight / 60;

    // convert the degree to (x,y)
    this.pointerTopX = this.pointerPivotX + (this.pointerLength + arcOffset) * Math.cos(2 * Math.PI * angle / 360);
    this.pointerTopY = this.pointerPivotY + (this.pointerLength + arcOffset) * Math.sin(2 * Math.PI * angle / 360);

    if (Number.isNaN(this.pointerTopX))
    {
      this.pointerTopX = 0;
    }
    if (Number.isNaN(this.pointerTopY))
    {
      this.pointerTopY = 0;
    }

    // calculate the slope of the pointer line using the pivot point of (X1, Y1)
    let m = (this.pointerTopY - this.pointerPivotY) / (this.pointerTopX - this.pointerPivotX);

    // set the Y coordinate as the bottom edge
    this.pointerBottomY = this.clientHeight;

    // point slope formula:
    // y - y1 = m(x - x1)
    // (x1, y1) is a known point, the pivot point
    // m is the slope
    // (x, y) is another point on the line.
    // y is chosen to be 10% from the bottom edge
    // by rearranging the formula:
    // x = (y - y1)/m + x1
    this.pointerBottomX = (this.pointerBottomY - this.pointerPivotY) / m + this.pointerPivotX;

    if (Number.isNaN(this.pointerBottomX))
    {
      this.pointerBottomX = 0;
    }
    if (Number.isNaN(this.pointerBottomY))
    {
      this.pointerBottomY = 0;
    }
  }

  ///////////////////////////////////////////////////////////////////////////
  // CalculateBackgroundColor
  //
  // Ask the browser window to give us the background color.
  ///////////////////////////////////////////////////////////////////////////
  CalculateBackgroundColor()
  {
    // After the view is set, wait 0ms and then get the background rgb value so we can calculate the contrasting color.
    this.cdRef.detectChanges();

    // if myDiv is valid...
    if (this.myDiv != null)
    {
      // Get the background color from the browser window.
      this.myBackgroundColor = window.getComputedStyle(this.myDiv.nativeElement)['background-color'];

      this.onResize();
    }
  }

  onResize()
  {
    this.cdRef.detectChanges();

    this.clientWidth = this.width;
    this.clientHeight = this.height;

    if (Number.isNaN(this.clientWidth))
    {
      this.clientWidth = 10;

    }
    if (Number.isNaN(this.clientHeight))
    {
      this.clientHeight = 10;

    }

    this.CalculateMeterRegions();
    this.UpdatePointer();
  }
}
