import { DataSubscriptionService } from "../../services/data-subscription.service";
import { Input, Component } from "@angular/core";
import { MatrixData } from "../../services/group-panel.service";
import { DataOneShotService } from "../../services/data-one-shot.service";
import { AlarmCounter } from "./alarm-counter";

@Component({
	selector: 'app-mosaic-alarm-counter',
	template: '',
})
export class MosaicAlarmCounter extends AlarmCounter// implements OnChanges
{
	@Input() data: MatrixData = new MatrixData();

	public constructor(
		protected ds: DataSubscriptionService,
		protected oneshot: DataOneShotService,
	)
	{
		super(ds, oneshot);
	}

	/**
	 * Get all the aliases for this matrix.
	 * @param aliases 
	 */
	protected GetAllAliases(aliases: number[])
	{
		this.GetAllAliasesEx(aliases, this.data);
	}

	///////////////////////////////////////////////////////////////////
	// GetAllAliases
	//
	// Return a list of all the alias numbers.
	//
	// Put this alias on the list.  For each element in sites, call 
	// GetAllAliases to recurse down the tree.
	///////////////////////////////////////////////////////////////////
	GetAllAliasesEx(aliases: number[], d: MatrixData)
	{
		// add this alias to the list.
		aliases.push(d.alias);

		// find the Custom View's sites
		if (d.view != null && d.view.widgets != null)
		{
			this.FindCustomViewAliases(aliases, d.view.widgets);
		}
	}

	///////////////////////////////////////////////////////////////////////////
	// FindCustomViewAliases
	//
	// loop through the array of tiles.  Find all "site" and "alias" properties.
	///////////////////////////////////////////////////////////////////////////
	FindCustomViewAliases(aliases: number[], obj: any[]): void
	{
		if (!Array.isArray(obj))
			return;

		obj.forEach((ele) =>
		{
			for (var property in ele)
			{
				if (property == "site")
				{
					aliases.push(+ele['site']); // '+' to turn any strings to numbers
				}
				if (property == "alias")
				{
					aliases.push(+ele['alias']); // '+' to turn any strings to numbers
				}
			}
		});
	}
}