import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CustomViewWidgetData } from '../custom-view-widget/custom-view-widget.component';

@Component({
  selector: 'app-modal-edit-tile-dialog',
  templateUrl: './modal-edit-tile-dialog.component.html',
  styleUrls: ['./modal-edit-tile-dialog.component.css',
    '../LightBox.css']
})
export class ModalEditTileDialogComponent implements OnInit
{
  @Input() configuration: CustomViewWidgetData;
  @Output() save = new EventEmitter<CustomViewWidgetData>();
  @Output() cancel = new EventEmitter();

  workingCopy: CustomViewWidgetData;

  constructor() { }

  ngOnInit()
  {
    if (this.configuration != null)
    {
      this.workingCopy = JSON.parse(JSON.stringify(this.configuration));
    }
    else
    {
      this.workingCopy = new CustomViewWidgetData();
    }
  }

  /**
   * Copy the working copy to the configuration.
   * Fire the close event.
   */
  onSave()
  {
    // this.configuration = this.workingCopy;
    this.save.emit(this.workingCopy);
  }

  /**
   * Fire the close event.
   */
  onCancel()
  {
    this.cancel.emit();
  }

  onEnter(event: Event)
  {
    event.stopPropagation();
    event.preventDefault()
  }

}
