import { Component } from '@angular/core';
import { CustomViewListService } from '../services/custom-view-list.service';
import { CustomViewData } from '../services/CustomViewData';
import { CustomViewPageListChangedService } from '../services/custom-view-page-list-changed.service';
import { MatrixData } from '../services/group-panel.service';
import { Configuration } from '../custom-view-widget/strip-chart/strip-chart-configuration.model';

/**
 * The default router outlet
 */
@Component({
	selector: 'app-custom-view-page',
	templateUrl: './custom-view-page.component.html',
	styleUrls: ['./custom-view-page.component.css']
})
export class CustomViewPageComponent
{
}
