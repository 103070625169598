import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, AfterViewChecked, OnChanges } from '@angular/core';
import { CustomViewWidgetComponent } from '../custom-view-widget.component';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { DataOneShotService } from '../../services/data-one-shot.service';
import { CommandData, CommandService } from '../../services/command.service';
import { SiteWidgetComponent, SiteConfiguration } from '../site-widget/site-widget.component';
import { ChannelHelper } from '../../services/status-condition-types.service';

export class Configuration extends SiteConfiguration
{
  channel: number;
  confirmation: boolean;
}

@Component({
  selector: 'app-meter-status-command-full-row',
  templateUrl: './meter-status-command-full-row.component.html',
  styleUrls: ['./meter-status-command-full-row.component.css'],
  host: {
    '(window:resize)': 'setWidth()'
  },
})
export class MeterStatusCommandFullRowComponent extends SiteWidgetComponent implements OnChanges, OnInit, AfterViewChecked
{
  // channel adaptor
  @Input() site: string;
  @Input() channel: number;
  @Input() commandConfirm: boolean;

  // Meter Value
  private meterValue: number;
  private meterLabel: string;
  private meterUnits: string;
  private meterDecimalPoint: number;
  public meterOnline: boolean;

  // Meter Colors
  private lowCriticalColor: number;
  private lowWarningColor: number;
  private normalColor: number;
  private highWarningColor: number;
  private highCriticalColor: number;
  private meterCurrentColor: number;
  // Meter Limits
  private lowCriticalLimit: number = 10;
  private lowWarningLimit: number = 20;
  private highWarningLimit: number = 80;
  private highCriticalLimit: number = 90;

  // Status Value
  private statusOnMessage: string;
  private statusOffMessage: string;
  private statusOnColor: number;
  private statusOffColor: number;
  private statusCurrentMessage: string;
  private statusCurrentColor: number = 128; // lightgray-ish
  private statusOnline: boolean;
  private statusValue: boolean;

  // Command Channel
  private outputConfirmation: boolean;
  private raisePressed: boolean = false;
  private lowerPressed: boolean = false;

  private isMeterAllowed: boolean;
  private isStatusAllowed: boolean;
  private isCommandAllowed: boolean;

  private meterUsed: boolean;
  private statusUsed: boolean;
  private commandUsed: boolean;

  @ViewChild('fullWidthBox') fullWidthBox: ElementRef;

  ///////////////////////////////////////////////////////////////////////////
  // constructor
  ///////////////////////////////////////////////////////////////////////////
  constructor(
    protected ds?: DataSubscriptionService,
    protected oneshot?: DataOneShotService,
    private cdRef?: ChangeDetectorRef,
  ) 
  {
    super(ds, oneshot);
  }

  ngAfterViewChecked()
  {
    // this.setWidth();
  }

  /**
   * 
   */
  ngOnInit()
  {
    //this.Initialize();
  }

  private Initialize(): void
  {
    if (this.site == null || this.channel == null)
      return;

    let obj: any = {};

    obj.site = this.site;
    obj.channel = this.channel;
    obj.confirmation = this.commandConfirm;

    // if the background is already set, keep it.
    if (this.backgroundColor != null)
    {
      obj.backgroundColor = this.backgroundColor;
    }

    this.Configure(obj);
    this.StartSubscription();
  }

  ngOnChanges()
  {
    this.Initialize();
  }

  /**
   * 
   */
  setWidth()
  {
    // this allows the view to redraw so fullWidthBox is assigned.
    this.cdRef.detectChanges();

    if (this.fullWidthBox != null)
    {
      let width = this.fullWidthBox.nativeElement.getBoundingClientRect().width;

      // the first element is 70 pixels.  Then 10 pixel gap, then another element of 70.
      // so the series of widths is 70, 150, 230, 310, 390...
      // the series is 70 + n*80
      // 
      let n = Math.floor((width - 70) / 80);
      this.width = 70 + n * 80;
    }
  }

  ///////////////////////////////////////////////////////////////////////////
  // Configure
  //
  // Configure this tile with the parameters.
  ///////////////////////////////////////////////////////////////////////////
  Configure(obj: Configuration): CustomViewWidgetComponent
  {
    super.Configure(obj);

    this.channel = obj.channel;
    if (obj.confirmation != null)
    {
      this.outputConfirmation = obj.confirmation;
    }

    this.GetData();

    return this;
  }

  ///////////////////////////////////////////////////////////////////////////
  // GetType
  //
  // Get the type of this widget.
  ///////////////////////////////////////////////////////////////////////////
  static GetType(): string { return "Meter Status Command Row"; }

  ///////////////////////////////////////////////////////////////////////////
  // GetData
  //
  // Get the Single Shot parameters.   
  ///////////////////////////////////////////////////////////////////////////
  private GetData()
  {
    this.oneshot.getPermission(this.site, "Analog", this.channel).subscribe((b) =>
    {
      this.isMeterAllowed = b;
      if (!this.isMeterAllowed)
      {
        return;
      }

      this.oneshot.getPidParameter(this.site, "Analog", this.channel, "AnalogName").subscribe(p => this.meterLabel = p.value);
      this.oneshot.getPidParameter(this.site, "Analog", this.channel, "AnalogUnitsLabel").subscribe(p => this.meterUnits = p.value);
      this.oneshot.getPidParameter(this.site, "Analog", this.channel, "AnalogDecimalPoint").subscribe(p => this.meterDecimalPoint = +p.value);
      this.oneshot.getPidParameter(this.site, "Analog", this.channel, "AnalogHighCriticalColor").subscribe(p => 
      {
        this.highCriticalColor = +p.value;
        this.meterCurrentColor = this.GetMeterColor();
      });
      this.oneshot.getPidParameter(this.site, "Analog", this.channel, "AnalogHighWarningColor").subscribe(p => 
      {
        this.highWarningColor = +p.value;
        this.meterCurrentColor = this.GetMeterColor();
      });
      this.oneshot.getPidParameter(this.site, "Analog", this.channel, "AnalogNormalColor").subscribe(p => 
      {
        this.normalColor = +p.value;
        this.meterCurrentColor = this.GetMeterColor();
      });
      this.oneshot.getPidParameter(this.site, "Analog", this.channel, "AnalogLowWarningColor").subscribe(p => 
      {
        this.lowWarningColor = +p.value;
        this.meterCurrentColor = this.GetMeterColor();
      });
      this.oneshot.getPidParameter(this.site, "Analog", this.channel, "AnalogLowCriticalColor").subscribe(p => 
      {
        this.lowCriticalColor = +p.value;
        this.meterCurrentColor = this.GetMeterColor();
      });

      this.oneshot.getPidParameter(this.site, "Analog", this.channel, "AnalogHighCriticalLimit").subscribe(p => 
      {
        this.highCriticalLimit = +p.value;
        this.meterCurrentColor = this.GetMeterColor();
      });
      this.oneshot.getPidParameter(this.site, "Analog", this.channel, "AnalogHighWarningLimit").subscribe(p => 
      {
        this.highWarningLimit = +p.value;
        this.meterCurrentColor = this.GetMeterColor();
      });
      this.oneshot.getPidParameter(this.site, "Analog", this.channel, "AnalogLowWarningLimit").subscribe(p => 
      {
        this.lowWarningLimit = +p.value;
        this.meterCurrentColor = this.GetMeterColor();
      });
      this.oneshot.getPidParameter(this.site, "Analog", this.channel, "AnalogLowCriticalLimit").subscribe(p => 
      {
        this.lowCriticalLimit = +p.value;
        this.meterCurrentColor = this.GetMeterColor();
      });
      this.oneshot.getPidParameter(this.site, "Analog", this.channel, "AnalogVisible").subscribe(p => 
      {
        this.meterUsed = !!+p.value;
      });
    });

    this.oneshot.getPermission(this.site, "Status", this.channel).subscribe((b) =>
    {
      this.isStatusAllowed = b;
      if (!this.isStatusAllowed)
      {
        return;
      }

      this.oneshot.getPidParameter(this.site, "Status", this.channel, "StatusOnMessage").subscribe(p => 
      {
        this.statusOnMessage = p.value;
        this.statusCurrentMessage = (this.statusValue) ? this.statusOnMessage : this.statusOffMessage;
      });
      this.oneshot.getPidParameter(this.site, "Status", this.channel, "StatusOffMessage").subscribe(p => 
      {
        this.statusOffMessage = p.value;
        this.statusCurrentMessage = (this.statusValue) ? this.statusOnMessage : this.statusOffMessage;
      });
      this.oneshot.getPidParameter(this.site, "Status", this.channel, "StatusOnColor").subscribe(p => 
      {
        this.statusOnColor = +p.value;
        this.statusCurrentColor = (this.statusValue) ? this.statusOnColor : this.statusOffColor;
      });
      this.oneshot.getPidParameter(this.site, "Status", this.channel, "StatusOffColor").subscribe(p => 
      {
        this.statusOffColor = +p.value;
        this.statusCurrentColor = (this.statusValue) ? this.statusOnColor : this.statusOffColor;
      });
      this.oneshot.getPidParameter(this.site, "Status", this.channel, "StatusVisible").subscribe(p => 
      {
        this.statusUsed = !!+p.value;
      });
    });

    this.oneshot.getPermission(this.site, "Output", this.channel).subscribe((b) =>
    {
      this.isCommandAllowed = b;

      if (!this.isCommandAllowed)
      {
        return;
      }

      this.oneshot.getPidParameter(this.site, "Output", this.channel, "OutputVisible").subscribe(p => 
      {
        this.commandUsed = !!+p.value;
      });
    });

    this.CalculateBackgroundColor();

  }

  ///////////////////////////////////////////////////////////////////////////
  // Subscribe
  //
  // Call the datasubscription service to add the subscriptions.
  ///////////////////////////////////////////////////////////////////////////
  protected Subscribe()
  {
    super.Subscribe();

    this.ds.Add(this, [
      {
        "site": this.site,
        "block": "Analog",
        "channel": this.channel,
        "pid": "AnalogScaled"
      },
      {
        "site": this.site,
        "block": "Analog",
        "channel": this.channel,
        "pid": "AnalogState"
      },
      {
        "site": this.site,
        "block": "Status",
        "channel": this.channel,
        "pid": "StatusStatus"
      },
      {
        "site": this.site,
        "block": "Status",
        "channel": this.channel,
        "pid": "StatusState"
      },
    ]);
  }

  ///////////////////////////////////////////////////////////////////////////
  // Update
  //
  // A real-time update was received.  Update the meter or status value.
  ///////////////////////////////////////////////////////////////////////////
  Update(site: string, pid: string, channel: number, value: string)
  {
    super.Update(site, pid, channel, value);

    switch (pid)
    {
      case "AnalogScaled":
        this.meterValue = +value; // convert the string to a number.
        this.meterCurrentColor = this.GetMeterColor();
        break;
      case "AnalogState":
        this.meterOnline = ChannelHelper.convertChannelStateToOnline(value);
        break;
      case "StatusStatus":
        this.statusValue = !!+value;
        this.statusCurrentMessage = (this.statusValue) ? this.statusOnMessage : this.statusOffMessage;
        this.statusCurrentColor = (this.statusValue) ? this.statusOnColor : this.statusOffColor;
        break;
      case "StatusState":
        this.statusOnline = ChannelHelper.convertChannelStateToOnline(value);
        break;
    }
  }

  ///////////////////////////////////////////////////////////////////////////
  // GetMeterColor
  //
  // Use the meterValue and the limits to calculate the correct color.
  ///////////////////////////////////////////////////////////////////////////
  private GetMeterColor(): number
  {
    if (this.meterValue >= this.highCriticalLimit)
    {
      return this.highCriticalColor;
    }
    if (this.meterValue >= this.highWarningLimit)
    {
      return this.highWarningColor;
    }
    if (this.meterValue <= this.lowCriticalLimit)
    {
      return this.lowCriticalColor;
    }
    if (this.meterValue <= this.lowWarningLimit)
    {
      return this.lowWarningColor;
    }
    return this.normalColor;
  }

  onLowerMouseDown($event)
  {
    this.lowerPressed = true;
  }
  onLowerMouseUp($event)
  {
    this.lowerPressed = false;
  }
  onLowerMouseLeave($event)
  {
    this.lowerPressed = false;
  }
  onRaiseMouseDown($event)
  {
    this.raisePressed = true;
  }
  onRaiseMouseUp($event)
  {
    this.raisePressed = false;
  }
  onRaiseMouseLeave($event)
  {
    this.raisePressed = false;
  }

  get statusChannelOfflineMsg(): string
  {
    return this.calcStatusChannelOfflineMsg(this.statusOnMessage, this.statusOffMessage, this.channel);
  }

}
