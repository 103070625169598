import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AccountService } from './account.service';

class FileTimestamps
{
  global: string;
  user: string;
}

@Injectable({
  providedIn: 'root'
})
export class TabChangedService
{
  path: string = 'api/Pages/Timestamps';

  public globalChanged: Observable<string>;
  public userChanged: Observable<string>;

  private globalSubject = new Subject<string>();
  private userSubject = new Subject<string>();

  private globalTimestamp: string;
  private userTimestamp: string;

  private timerHandle: number;

  constructor(private http: HttpClient,
    private as: AccountService,
    @Inject('BASE_URL') private baseUrl: string) 
  {
    this.globalChanged = this.globalSubject.asObservable();
    this.userChanged = this.userSubject.asObservable();

    this.timerHandle = window.setInterval(() =>
    {
      this.PollFileTimestamps();
    }, 30000);

    // once the user logs in, poll for the timestamps for the files.
    as.user.subscribe((user) =>
    {
      if (user != null)
      {
        this.PollFileTimestamps();
      }
    });
  }

  stop(): void
  {
    window.clearInterval(this.timerHandle);
  }

  /**
   * 
   */
  PollFileTimestamps()
  {
    this.getTimestamps().subscribe((t) =>
    {
      // if we didn't have a global timestamp, take this one.
      if (this.globalTimestamp == null)
      {
        this.globalTimestamp = t.global;
      }

      // if we didn't have a user timestamp, take this one.
      if (this.userTimestamp == null)
      {
        this.userTimestamp = t.user;
      }

      // if the global timestamps are differnet...
      if (this.globalTimestamp != t.global)
      {
        // save it and publish it.
        this.globalTimestamp = t.global;
        this.globalSubject.next(this.globalTimestamp);
      }

      // if the user timestamps are differnet...
      if (this.userTimestamp != t.user)
      {
        // save it and publish it.
        this.userTimestamp = t.user;
        this.userSubject.next(this.userTimestamp);
      }
    });
  }

  private getTimestamps(): Observable<FileTimestamps>
  {
    return this.http.get<FileTimestamps>(this.baseUrl + this.path);
  }

}
