import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { bufferTime, filter, map, mergeMap, take } from 'rxjs/operators';
import { Point } from '../custom-view-widget/strip-chart/point.model';
import { BufferTimeHttpGet } from './buffer-time-http-get';

export class PidOneShot
{
  site: string;
  block: string;
  channel: number;
  parameter: string;
}
export class PidUpdate
{
  site: string;
  block: string;
  channel: number;
  parameter: string;
  value: string;
}
export class MacroData
{
  siteId: number;
  siteName: string;
  channel: number;
  name: string;
  status: number;
}
export class HistoryData
{
  str: string;
}
export class DataPoint
{
  x: number;
  y: number;
}

/// This service provides a method of requesting an array of PIDs and getting the result.
@Injectable({
  providedIn: 'root'
})
export class DataOneShotService
{
  oneShot: BufferTimeHttpGet<PidOneShot, PidUpdate>;
  permission: BufferTimeHttpGet<PidOneShot, PidUpdate>;

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string)
  {
    this.oneShot = new BufferTimeHttpGet(this.http);
    this.oneShot.configure(
      this.baseUrl + 'api/Pages/GetPids',
      (p: PidOneShot) => p.site + ":" + p.block + ":" + p.channel + ":" + p.parameter,
      10,
      100);

    this.permission = new BufferTimeHttpGet(this.http);
    this.permission.configure(
      this.baseUrl + 'api/Pages/Permission',
      (p: PidOneShot) => p.site + ":" + p.block + ":" + p.channel,
      10,
      100);
  }

  getPidsArray(pids: PidOneShot[]): Observable<PidUpdate[]>
  {
    let httpParams = new HttpParams();
    let i: number = 0;
    for (let p of pids)
    {
      let str = p.site + ":" + p.block + ":" + p.channel + ":" + p.parameter;
      httpParams = httpParams.append("pid[" + i + "]", str);
      i++;
    }

    return this.http.get<PidUpdate[]>(this.baseUrl + 'api/Pages/GetPids', { params: httpParams }).pipe(map((value: PidUpdate[], index: number): PidUpdate[] =>
    {
      // clean the values so they don't have null characters
      for (let p of value)
      {
        if (p.value != null)
        {
          p.value = p.value.replace(/\0/g, '');
        }
      }
      return value;
    }));
  }


  /**
   * 1. create an array of the {site, block, channel, param}.
   * 2. add all the elements of the array to the bufferTime subject.
   * 3. take 1 from the bufferTime subject and return that Observable 
   * 
   * a. create the bufferTime subject and map the outputs to a HTTP request with all the parameters.
   * b. subscribe to the HTTP request.  Get the response.  Return the response as the result of the map.
   * 
   */
  getPidParametersNew(site: string, block: string, channel: number, parameters: string[]): Observable<PidUpdate[]>
  {
    // loop for each parameter...
    parameters.forEach(p =>
    {
      // create the PidOneShot
      let pid = new PidOneShot();
      pid.site = site;
      pid.block = block;
      pid.channel = channel;
      pid.parameter = p;

      // feed each PidOneShot into the input queue
      this.oneShot.oneShotInput.next(pid);
    });

    return this.oneShot.oneShotOutput.pipe(
      // TODO: take(1),
      map((value: PidUpdate[], index: number): PidUpdate[] =>
      {
        let result = [];

        // clean the values so they don't have null characters
        for (let p of value)
        {
          if (p.value != null)
          {
            p.value = p.value.replace(/\0/g, '');
          }

          // filter out the items that do not go to this subscriber
          if (p.block == block && p.channel == channel && p.site == site)
          {
            result.push(p);
          }
        }

        return result;
      })
    );
  }

  getPidParameter(site: string, block: string, channel: number, param: string): Observable<PidUpdate>
  {
    // create the PidOneShot
    let pid = new PidOneShot();
    pid.site = site;
    pid.block = block;
    pid.channel = channel;
    pid.parameter = param;

    // feed each PidOneShot into the input queue
    this.oneShot.oneShotInput.next(pid);

    // get the observable that will output arrays of PidUpdates
    return this.oneShot.oneShotOutput.pipe(
      // for each array of PidUpdates, filter the values for null and only push along the ones that this call asked for
      map((value: PidUpdate[], index: number): PidUpdate[] =>
      {
        let result = [];

        // clean the values so they don't have null characters
        for (let p of value)
        {
          if (p.value != null)
          {
            p.value = p.value.replace(/\0/g, '');
          }

          // filter out the items that do not go to this subscriber
          if (p.block == block && p.channel == channel && p.site == site && p.parameter == param)
          {
            result.push(p);
          }
        }

        return result;
      }),
      // filter out the arrays that have no matching PidUpdates
      filter(x => x.length > 0),
      // convert the array of PidUpdates to a single PidUpdate
      map(arr => arr[0]),
      // after we get 1, complete this Observable
      take(1),
    );
  }

  getPidParameters(site: string, block: string, channel: number, parameters: string[]): Observable<PidUpdate[]>
  {
    let httpParams = new HttpParams();
    let i: number = 0;
    for (let p of parameters)
    {
      let str = site + ":" + block + ":" + channel + ":" + p;
      httpParams = httpParams.append("pid[" + i + "]", str);
      i++;
    }

    return this.http.get<PidUpdate[]>(this.baseUrl + 'api/Pages/GetPids', { params: httpParams }).pipe(map((value: PidUpdate[], index: number): PidUpdate[] =>
    {
      // clean the values so they don't have null characters
      for (let p of value)
      {
        if (p.value != null)
        {
          p.value = p.value.replace(/\0/g, '');
        }
      }
      return value;
    }));
  }

  getMacroData(site: string): Observable<MacroData[]>
  {
    return this.http.get<MacroData[]>(this.baseUrl + 'api/Macro/GetAll/' + site);
  }

  getMacros(sites: string[] | string): Observable<MacroData[]>
  {
    let httpParams = new HttpParams();

    let siteList = [];

    // create the array of sites
    if (sites instanceof Array)
    {
      siteList.push(...sites);
    }
    else
    {
      siteList.push(sites);
    }

    // create the parameters for each site
    siteList.forEach((value, i) =>
    {
      httpParams = httpParams.append("unit[" + i + "]", value);
    });

    return this.http.get<MacroData[]>(this.baseUrl + 'api/Macro/Units', { params: httpParams });
  }

  /////////////////////////////////////////////////////////////////////
  // getHistory
  /////////////////////////////////////////////////////////////////////
  getHistory(unitId: string, type: string, channel: number, historyIndex: number): Observable<HistoryData>
  {
    let httpParams = new HttpParams();

    httpParams = httpParams.append("u", unitId);
    httpParams = httpParams.append("t", type);
    httpParams = httpParams.append("c", "" + channel);
    httpParams = httpParams.append("h", "" + historyIndex);

    return this.http.get<HistoryData>(this.baseUrl + 'api/Pages/GetHistory', { params: httpParams });
  }

  /**
   * Get an array of data points that include up to the last 'timespan' seconds.
   * @param unitId 
   * @param type 
   * @param channel 
   * @param timespanSec Number of seconds in the past to get data.
   */
  getHistoryRange(unitId: string, type: string, channel: number, timespanSec: number): Observable<DataPoint[]>
  {
    let httpParams = new HttpParams();

    httpParams = httpParams.append("u", unitId);
    httpParams = httpParams.append("t", type);
    httpParams = httpParams.append("c", "" + channel);
    httpParams = httpParams.append("e", "" + timespanSec);

    return this.http.get<DataPoint[]>(this.baseUrl + 'api/Pages/GetHistoryRange', { params: httpParams });
  }

  getPermission(unitId: string, type: string, channel: number): Observable<boolean>
  {
    // create the PidOneShot
    let pid = new PidOneShot();
    pid.site = unitId;
    pid.block = type;
    pid.channel = channel;

    // feed each PidOneShot into the input queue
    this.permission.oneShotInput.next(pid);

    return this.permission.oneShotOutput.pipe(
      map((value: PidUpdate[], index: number): boolean[] =>
      {
        let result = [];

        for (let p of value)
        {
          // filter out the items that do not go to this subscriber
          if (p.block == type && p.channel == channel && p.site == unitId)
          {
            result.push(p.value.toLocaleLowerCase() === "true");
          }
        }

        return result;
      }),

      filter(x => x.length > 0),
      map(arr => arr[0]),
      take(1)
    );
  }

  getNumberOfChannels(unitId: string, type: string): Observable<number>
  {
    let httpParams = new HttpParams();

    httpParams = httpParams.append("u", unitId);
    httpParams = httpParams.append("t", type);

    return this.http.get<number>(this.baseUrl + 'api/Pages/NumOfChannels', { params: httpParams });
  }
}
