import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { AccordianTabData, MatrixData } from '../../services/group-panel.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subject, config } from 'rxjs';
import { ClipboardService } from '../../services/clipboard.service';
import { UniqueIdService } from '../../unique-id.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditModeService } from '../../services/edit-mode.service';
import * as fileSaver from '@elastic/filesaver'

@Component({
  selector: 'app-group-summary-panel',
  templateUrl: './group-summary-panel.component.html',
  styleUrls: ['./group-summary-panel.component.css',
    '../../appStyles.css',
    '../../contextMenu.css']
})
export class GroupSummaryPanelComponent implements OnInit, OnChanges
{
  @Input() data: AccordianTabData;
  @Input() index: number;
  @Input() dataChanged: Subject<AccordianTabData>; // call next() to cause the creator of the Subject to trigger to save the data.
  @Input() root: boolean = false;
  @Input() allowEdit = true;

  @Output() cut = new EventEmitter<number>(); // tell the holder of this item to remove it from the list.
  @Output() changeDraggable = new EventEmitter();

  private edit: boolean;

  private pleatSiteGroup: MatrixData;

  /**
   * @deprecated This was to show sub-groups
   */
  private rootGroupListSub = new Subject<MatrixData>();
  private subGroupListSub = new Subject<MatrixData>();

  private rootBreadcrumbSub = new Subject<MatrixData>();
  private subBreadcrumbSub = new Subject<MatrixData>();

  private groupList: MatrixData[];
  private groupDataChanged = new Subject<any>();

  private expanded: boolean = false;
  private contextMenu = false;
  private addInitialTile: boolean = false;

  private id: string;
  private draggable: boolean[] = [];
  private sites: string[] = [];
  private groupSites: string[] = [];

  private showTarget: boolean[] = [];
  private expandedTargets: number[] = []; // array of the index of groups to expand.

  /**
   * 
   * @param clipboard 
   * @param uniqueId 
   * @param modalService 
   */
  constructor(
    private clipboard: ClipboardService,
    private uniqueId: UniqueIdService,
    private modalService: NgbModal,
    private editModeService: EditModeService,
  ) 
  {
    this.id = uniqueId.get();
  }

  ngOnInit()
  {
    // subscribe to the edit mode.
    this.editModeService.get().subscribe(b =>
    {
      this.edit = b && this.allowEdit;
    });

    // expand the root group summary panel.
    this.expanded = this.root;
    // this.groupList = null;

    this.rootGroupListSub.subscribe((data: MatrixData) =>
    {
      // this.groupList = data.sites;
      this.rootBreadcrumbSub.next(data);

      // calculate the sites that are under this breadcrumb
      this.groupSites = this.GetGroupsEx(data);
    });

    this.subGroupListSub.subscribe((data: MatrixData) =>
    {
      // this.groupList = data.sites;
      this.subBreadcrumbSub.next(data);

      // calculate the sites that are under this breadcrumb
      this.groupSites = this.GetGroupsEx(data);
    });

    // if any of the groups changed, mark this object as changed.  It will save further upstream.
    this.groupDataChanged.subscribe(() =>
    {
      this.onDataChanged();
    });

    // if some data has changed, update the counts.
    if (this.dataChanged != null)
    {
      this.dataChanged.subscribe(() =>
      {
        this.UpdatePleatGroupData();
      });
    }
    this.UpdatePleatGroupData();
  }

  UpdatePleatGroupData()
  {
    // after init, create the pleatSiteGroup from data.
    this.pleatSiteGroup = new MatrixData();
    if (this.data != null)
    {
      this.pleatSiteGroup.name = this.data.name;
    }

    // this.GetGroupPanelSites(this.pleatSiteGroup, this.data);

    this.InitializeShowTarget();
  }

  clickExpandCollapse()
  {
    this.expanded = !this.expanded;
    this.groupList = null;
    this.InitializeShowTarget();
  }
  InitializeShowTarget()
  {
    // initialize the showTargets to false;
    this.showTarget.length = 0;

    if (this.data)
    {
      this.data.Groups.forEach(element =>
      {
        this.showTarget.push(false);
      });
    }

    this.expandedTargets.length = 0;

  }

  ngOnChanges(changes: SimpleChanges): void
  {
    if (this.data.Pages == null)
    {
      this.data.Pages = [];
    }
    if (this.data.Groups == null)
    {
      this.data.Groups = [];
    }


    this.data.Pages.forEach((ele, i) =>
    {
      this.draggable[i] = true;
    });

    this.sites = this.GetSites();
  }

  drop(event: CdkDragDrop<string[]>)
  {
    moveItemInArray(this.data.Pages, event.previousIndex, event.currentIndex);
    this.onDataChanged();
  }

  dropGroup(event: CdkDragDrop<string[]>)
  {
    moveItemInArray(this.data.Groups, event.previousIndex, event.currentIndex);
    this.onDataChanged();
  }

  dropSubgroup(event: CdkDragDrop<string[]>)
  {
    moveItemInArray(this.groupList, event.previousIndex, event.currentIndex);
    this.onDataChanged();
  }

  onNameChange()
  {
    this.onDataChanged();
  }
  onGroupNameChange(newName: string)
  {
    this.data.name = newName;
    this.onDataChanged();
  }

  /////////////////////////////////////////////////////
  // onPageCopy
  // 
  // Copy the object to the clipboard.
  /////////////////////////////////////////////////////
  onPageCopy()
  {
    this.clipboard.Save("Page", this.data);
    this.contextMenu = false;
  }

  /////////////////////////////////////////////////////
  // onPageCut
  //
  // Copy the page to the clipboard
  // Tell the parent to cut the index from the list.
  /////////////////////////////////////////////////////
  onPageCut()
  {
    this.clipboard.Save("Page", this.data);

    this.cut.emit(this.index);

    this.contextMenu = false;
  }

  /////////////////////////////////////////////////////
  // onPageDelete
  //
  // Tell the parent to cut the index from the list.
  /////////////////////////////////////////////////////
  onPageDelete()
  {
    if (confirm("Are you sure you want to delete " + this.data.name + "?"))
    {
      this.cut.emit(this.index);
      this.onDataChanged();
    }
    this.contextMenu = false;
  }

  /////////////////////////////////////////////////////
  // onChildCut
  //
  // When a child Group Summary Panel had it's cut clicked
  // Remove the index.
  /////////////////////////////////////////////////////
  onChildCut(i: number)
  {
    this.data.Pages.splice(i, 1);
    this.UpdatePleatGroupData();
  }

  /**
   * @deprecated this was to show subgroups
   */
  onClearSubGroups()
  {
    this.rootGroupListSub.next(new MatrixData());
  }

  ////////////////////////////////////////////////////////
  // onPageAdd
  //
  // Add a new page to the list of Pages.
  ////////////////////////////////////////////////////////
  // onPageAdd()
  // {
  //   if (this.data.Pages == null)
  //   {
  //     this.data.Pages = [];
  //   }

  //   // if the pleat is collapsed and Add is clicked, expand the pleat and add the item.  Otherwise it looks like nothing happened.
  //   this.expanded = true;

  //   this.data.Pages.push(new GroupPanelData({ name: "New Page" }));

  //   // make sure the last item has a unique name.
  //   this.onPagesNameChanged(this.data.Pages.length - 1);

  //   this.onDataChanged();
  // }

  onPagesNameChanged(index: number)
  {
    this.FindUniqueName(index, this.data.Pages);
    this.onDataChanged();
  }

  onGroupDataChanged(event: MatrixData, i: number)
  {
    this.data.Groups[i] = event;

    this.onDataChanged();
  }

  onDataChanged()
  {
    this.UpdatePleatGroupData();

    this.dataChanged.next(this.data);
  }

  //////////////////////////////////////////////////////////////////////////////////
  // FindUniqueName
  //
  // Find a unique name for this page.  Add (x) to make it unique.  
  // Trim any (y) before adding (x).
  //
  // Loop to see if this is unique.
  // If it is unique, return the name.
  //
  // If there is a match
  //   Start with x = 1
  //   loop forever
  //     Trim off the "(y)" from the input string.
  //     Add a "(x)" to the end.
  //     Loop to see if this is unique
  //     If it is unique, return the name
  // 
  //////////////////////////////////////////////////////////////////////////////////
  FindUniqueName(index: number, array: any): void
  {
    if (this.CheckForUnique(index, array))
    {
      return;
    }

    let x = 1;
    let baseName: string = array[index].name;

    // trim off the (y)
    let rx = /^.* \((\d+)\)$/g; // try to find a "[name] (x)"

    let stringIndex = rx.exec(baseName);
    if (stringIndex != null && stringIndex.length > 1)
    {
      baseName = baseName.substr(0, baseName.lastIndexOf(" (")).trim();
      x = +stringIndex[1];
    }

    while (true)
    {
      // increment the index on each loop.  This starts at the number we found
      x++;

      array[index].name = "" + baseName + " (" + x + ")";

      if (this.CheckForUnique(index, array))
      {
        return;
      }
    }
  }

  //////////////////////////////////////////////////////////////////////////////////
  // CheckForUnique
  //
  // Return true if none of the elements of array.name contain the name.
  //////////////////////////////////////////////////////////////////////////////////
  CheckForUnique(index: number, array: any[]): boolean
  {
    let unique = true;

    // check each element of the array
    array.forEach((element, i) =>
    {
      if (index != i)
      {
        // if array elements have 'name'...
        if (('name' in array[0]))
        {
          // if the names match, then not unique.
          if (element.name == array[index].name)
          {
            unique = false;
          }
        }
      }
    });

    return unique;
  }

  onDelete(i: number)
  {
    this.data.Groups.splice(i, 1); // remove the group

    this.rootBreadcrumbSub.next(null); // clear the breadcumbs
    this.groupList = null; // clear the subgroups.

    this.onDataChanged();
  }

  onDeleteSubGroup(i: number)
  {
    this.groupList.splice(i, 1);
    this.onDataChanged();
  }

  onPasteGroup(event, list: MatrixData[], i: number)
  {
    let obj = this.clipboard.Restore();

    if (obj != null)
    {
      switch (obj.type)
      {
        case "Group":
          list.splice(i + 1, 0, obj.obj);
          // this.data.Groups.push(new GroupSiteData(obj.obj));
          this.onDataChanged();
          this.expanded = true;
          break;
      }
    }
  }

  onPaste()
  {
    let obj = this.clipboard.Restore();

    if (obj != null)
    {
      switch (obj.type)
      {
        case "Group":
          this.data.Groups.push(new MatrixData(obj.obj));
          this.onDataChanged();
          this.expanded = true;
          break;

        case "Page":
          // create a new Group Panel Data obj 
          this.data.Pages.push(new AccordianTabData(obj.obj));

          this.UpdatePleatGroupData();

          // make sure the last item has a unique name.
          this.onPagesNameChanged(this.data.Pages.length - 1);

          this.onDataChanged();

          this.expanded = true;
          break;
      }
    }
    this.contextMenu = false;
  }

  onGroupAdd()
  {
    if (this.data.Groups == null)
    {
      this.data.Groups = [];
    }

    this.data.Groups.push(new MatrixData());
    this.onDataChanged();
  }

  onBreadcrumbClick(data: MatrixData)
  {
    // this.groupList = data.sites;

    // calculate the sites that are under this breadcrumb
    this.groupSites = this.GetGroupsEx(data);
  }

  onAddSubgroup(content: any)
  {
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: 'mymodal' });
  }

  private newList: MatrixData[];
  private newNode: MatrixData;
  private newNodeIndex: number;

  open(content: any, list: MatrixData[])
  {
    this.newList = list;
    this.newNode = new MatrixData();
    this.newNodeIndex = -1;

    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: 'mymodal', backdrop: false });
  }

  openCustomView(content: any, list: MatrixData[])
  {
    this.newList = list;
    this.newNode = new MatrixData();
    this.newNodeIndex = -1;
    this.newNode.view = {
      widgets: [],
      name: "",
      backgroundColor: "gray",
      maxheight: ""
    };

    this.addInitialTile = true;

    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: 'mymodal', backdrop: false });
  }

  onEditGroupSummary(siteModal: any, customViewModal: any, list: MatrixData[], i: number)
  {
    // if edit mode is not on, return.
    if (!this.edit) return;

    this.newList = list;
    this.newNode = list[i];
    this.newNodeIndex = i;

    let content: any;

    if (this.newNode.view.widgets != null)
    {
      content = customViewModal;

      this.addInitialTile = false;
    }
    else
    {
      content = siteModal;
    }

    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', windowClass: 'mymodal', backdrop: false });
  }

  /**
   * Save the node to the array
   * @param node 
   */
  SaveTiles(node: MatrixData)
  {
    // if the node index is negative...
    if (this.newNodeIndex < 0)
    {
      // if the list is not created...
      if (this.newList == null)
      {
        // start a new list
        this.newList = [];
      }
      // add this item to the end of the list.
      this.newList.push(node);
    }
    else
    {
      // the index is valid.  Insert this node in the middle.
      this.newList.splice(this.newNodeIndex, 1, node);
    }


    // tell any listeners that the data has changed.
    this.dataChanged.next();
  }

  /**
   * Close the modal
   */
  close()
  {
    this.modalService.dismissAll();
  }

  /**
   * Called when right-clicking on tab header
   */
  onContextMenu()
  {
    // show the context menu
    this.contextMenu = true;

    // stop the propagation of the right-click.
    return false;
  }

  onContextMenuClose(event)
  {
    this.contextMenu = false;
  }

  onChangeTabDraggable(event)
  {
    // console.log("Tab draggable: " + event);
    this.changeDraggable.emit(event);
  }

  onDraggable(event: boolean, i: number)
  {
    // console.log("Tab " + i + " draggable: " + event);
    this.draggable[i] = event;
  }

  /**
   * Return an array of all the sites.
   */
  GetSites(): string[]
  {
    let sites = this.GetSitesEx(this.data);

    // fiter the array to only have unique entries
    // https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
    sites = sites.filter((d, i, self) =>
    {
      return self.indexOf(d) === i;
    });
    return sites;
  }

  /**
   * Return all the sites under this Panel node.
   * @param d 
   */
  private GetSitesEx(groupPanelData: AccordianTabData): string[]
  {
    let sites = [];

    // recursively loop through the panels
    groupPanelData.Pages.forEach((d) =>
    {
      sites = sites.concat(this.GetSitesEx(d));
    });

    // loop through the groups.
    groupPanelData.Groups.forEach((d) =>
    {
      sites = sites.concat(this.GetGroupsEx(d));
    });

    return sites;
  }

  /**
   * Return the sites in the Group data.
   * @param d 
   */
  private GetGroupsEx(d: MatrixData): string[]
  {
    let sites = [];
    // d.sites.forEach((g) =>
    // {
    //   sites = sites.concat(this.GetGroupsEx(g));
    // });
    if (d.alias > 0)
    {
      sites.push("" + d.alias);
    }

    if (d.view.widgets)
    {
      d.view.widgets.forEach((w) =>
      {
        if (w.site)
        {
          sites = sites.concat(w.site);
        }
      });
    }
    return sites;
  }

  toggleTarget(i: number)
  {
    if (this.edit) return;

    this.showTarget[i] = !this.showTarget[i];

    // if the target is already in the list...
    if (this.expandedTargets.includes(i))
    {
      let removeIndex = this.expandedTargets.indexOf(i);
      // remove the item
      this.expandedTargets.splice(removeIndex, 1);
    }
    else // add the item to the head of the list.
    {
      this.expandedTargets.unshift(i);
    }

  }

  /**
   * Close the target.
   * @param $event 
   * @param i 
   */
  onCloseTarget($event: Event, i: number)
  {
    event.stopPropagation();
    this.toggleTarget(i);
  }


  /**
   * User clicked the Add Tab button.  Add a new Group Panel Data object and notify everyone of the change.
   */
  addTab()
  {
    this.data.Pages.push(new AccordianTabData());
    this.dataChanged.next();
  }

  onSaveAs()
  {
    var blob = new Blob([JSON.stringify(this.data, null, ' ')], { type: "text/plain;charset=utf-8" });
    fileSaver.saveAs(blob, this.pleatSiteGroup.name + ".arcadia");
  }

  public fileChangeEvent(fileInput: any)
  {
    if (fileInput.target.files && fileInput.target.files[0])
    {
      var reader = new FileReader();

      reader.onload = (e: any) =>
      {
        console.log(e.target.result);

        let obj = JSON.parse(e.target.result);
        this.data.Pages.push(obj);
        this.dataChanged.next();
      }

      reader.readAsText(fileInput.target.files[0]);
    }
    this.contextMenu = false;
  }
}
