import { Component, Inject, ComponentFactoryResolver } from '@angular/core';
import { MeterTextWidgetComponent, Configuration } from '../meter-text-widget.component';
import { ChannelPropertyComponent } from '../../channel-property/channel-property.component';
import { TileSize, DoubleWide_HalfHeight, FourxWide_HalfHeight, DoubleWide_SingleHeight, DoubleWide_DoubleHeight, FourxWide_DoubleHeight, SingleWide_SingleHeight, FourxWide_SingleHeight } from '../../../size-selector/size-selector.component';

@Component({
  selector: 'app-meter-text-property',
  templateUrl: './meter-text-property.component.html',
  styleUrls: ['./meter-text-property.component.css']
})
export class MeterTextPropertyComponent extends ChannelPropertyComponent<MeterTextWidgetComponent>
{
  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(MeterTextWidgetComponent, factoryResolver);

    let cfg = new Configuration();

    cfg.type = MeterTextWidgetComponent.GetType();

    this.Configure(cfg);
  }

  readonly sizes: TileSize[] =
    [
      SingleWide_SingleHeight,
      DoubleWide_HalfHeight,
      FourxWide_HalfHeight,
      DoubleWide_SingleHeight,
      DoubleWide_DoubleHeight,
      FourxWide_SingleHeight,
      FourxWide_DoubleHeight,
    ];
  GetDefaultSize(): number { return 0; }

  /**
   * Override the onNewSizeSelected to set specific sizes.
   * @param $event 
   */
  onNewSizeSelected($event: TileSize)
  {
    super.onNewSizeSelected($event);

    if (this.myConfiguration.height >= 150)
    {
      this.myConfiguration.fontSize = 36;
    }
    else if (this.myConfiguration.height >= 70)
    {
      this.myConfiguration.fontSize = 20;
    }
    else
    {
      this.myConfiguration.fontSize = 20;
    }

    this.onChange();
  }

}