import { Component, OnInit, Inject, ComponentFactoryResolver } from '@angular/core';
import { MSCChannelComponent, Configuration } from '../mscchannel.component';
import { ChannelPropertyComponent } from '../../channel-property/channel-property.component';
import { TileSize, FourxWide_FourxHeight, DoubleWide_DoubleHeight, SingleWide_SingleHeight } from '../../../size-selector/size-selector.component';

@Component({
  selector: 'app-mscchannel-property',
  templateUrl: './mscchannel-property.component.html',
  styleUrls: ['./mscchannel-property.component.css']
})
export class MSCChannelPropertyComponent extends ChannelPropertyComponent<MSCChannelComponent>
{
  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(MSCChannelComponent, factoryResolver);

    let cfg = new Configuration();

    cfg.type = MSCChannelComponent.GetType();

    this.Configure(cfg);
  }

  readonly sizes: TileSize[] =
    [
      SingleWide_SingleHeight,
      DoubleWide_DoubleHeight,
      FourxWide_FourxHeight,
    ];


  GetDefaultSize(): number { return 0; }


  /**
   * Override the onNewSizeSelected to set specific sizes.
   * @param $event 
   */
  onNewSizeSelected($event: TileSize)
  {
    super.onNewSizeSelected($event);

    if (this.myConfiguration.height >= 310)
    {
      this.myConfiguration.meterFontSize = 17;
      this.myConfiguration.statusFontSize = 30;
      this.myConfiguration.commandFontSize = 36;
    }
    else if (this.myConfiguration.height >= 150)
    {
      this.myConfiguration.meterFontSize = 8;
      this.myConfiguration.statusFontSize = 12;
      this.myConfiguration.commandFontSize = 18;
    }
    else
    {
      this.myConfiguration.meterFontSize = 5;
      this.myConfiguration.statusFontSize = 7;
      this.myConfiguration.commandFontSize = 8;
    }

    this.onChange();
  }
}