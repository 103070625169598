import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomViewWidgetComponent, CustomViewWidgetData } from '../custom-view-widget.component';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { MonotonicService } from '../../services/monotonic.service';

export class Configuration extends CustomViewWidgetData
{
  imageSrc: string;
  imageAlt: string;

  username: string;
  password: string;

  refreshEnable: boolean;
  refreshTime: number;
}

@Component({
  selector: 'app-image-widget',
  templateUrl: './image-widget.component.html',
  styleUrls: ['./image-widget.component.css']
})
export class ImageWidgetComponent extends CustomViewWidgetComponent implements OnDestroy
{

  private imageSrcOrig: string; // the full URL
  private imageSrc: string; // the full URL + changing URL parameter
  private imageAlt: string;
  private refreshEnable: boolean;
  private refreshTime: number;
  private timerHandle: number;

  constructor(
    private monotonic?: MonotonicService,
    protected ds?: DataSubscriptionService) 
  {
    super(ds);
  }

  /**
   * 
   */
  ngOnDestroy()
  {
    this.releaseTimer();
  }

  releaseTimer()
  {
    if (this.timerHandle != null)
    {
      clearInterval(this.timerHandle);
    }
  }

  //////////////////////////////////////////////////////////////////////////
  // Configure
  //////////////////////////////////////////////////////////////////////////
  Configure(obj: Configuration): CustomViewWidgetComponent
  {
    var url;

    if (obj.imageSrc != null)
    {
      try
      {
        url = new URL(obj.imageSrc);
      }
      catch { }
    }

    if (url != null)
    {
      if (obj.username != null) url.username = obj.username;
      if (obj.password != null) url.password = obj.password;

      this.imageSrcOrig = url.toString();
    }
    this.imageAlt = obj.imageAlt;

    this.refreshEnable = obj.refreshEnable;
    this.refreshTime = obj.refreshTime;

    super.Configure(obj);

    this.RefreshImage();
    this.RefreshTimer();

    return this;
  }

  /**
   * 
   */
  static GetType(): string { return "Image"; }

  /**
   * start the interval timer if enabled.
   */
  private RefreshTimer()
  {
    this.releaseTimer();

    // if the refresh is enabled and the refresh time is valid...
    if (this.refreshEnable && this.refreshTime)
    {
      this.timerHandle = window.setInterval(() => { this.RefreshImage(); }, this.refreshTime * 1000);
    }
  }

  /**
   * If the original image url has no parameters, add "?date=date()", otherwise add "&date=date()"
   */
  private RefreshImage()
  {
    if (this.monotonic == null) return;

    let d = this.monotonic.get();

    if (this.imageSrcOrig != null)
    {
      if (this.imageSrcOrig.indexOf("?") == -1)
      {
        this.imageSrc = this.imageSrcOrig + "?" + d;
      }
      else
      {
        this.imageSrc = this.imageSrcOrig + "&" + d;
      }
    }
  }
}
