import { Component, Inject, ComponentFactoryResolver } from '@angular/core';
import { CustomViewPropertyComponent } from '../custom-view-property.component';
import { LineConfiguration, Configuration } from './strip-chart-configuration.model';
import { StripChartComponent } from './strip-chart.component';
import { TileSize, FullWidth_DoubleHeight, FullWidth_FourxHeight, FullWidth_EightxHeight } from '../../size-selector/size-selector.component';

/**
 * Configure the properties of a strip chart
 */
@Component({
  selector: 'app-strip-chart-property',
  templateUrl: './strip-chart-property.component.html',
  styleUrls: ['./strip-chart-property.component.css']
})
export class StripChartPropertyComponent extends CustomViewPropertyComponent<StripChartComponent>
{
  readonly sizes = [
    FullWidth_DoubleHeight,
    FullWidth_FourxHeight,
    FullWidth_EightxHeight,
  ];

  /**
   * 
   * @param factoryResolver Factory to create the property's component.
   */
  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(StripChartComponent, factoryResolver);
    let cfg = new Configuration();

    cfg.timePeriod = 30;
    cfg.lines.push(new LineConfiguration(true));
    cfg.lines.push(new LineConfiguration(false));
    cfg.type = StripChartComponent.GetType();
    cfg.fullWidth = true;
    cfg.maxTicksLimit = 5;

    this.Configure(cfg);
  }

  /**
   * Get this property's component.
   */
  static GetComponent(): any
  {
    return StripChartComponent;
  }

  /**
   * When the chart line property changes, call the base.onChange()
   */
  onLineChange()
  {
    this.onChange();
  }

  /**
   * Get the index of the default size.
   */
  GetDefaultSize(): number { return 1; }

}
