import { Injectable } from '@angular/core';
import { AngularMeterPropertyComponent } from '../custom-view-widget/angular-meter-widget/angular-meter-property/angular-meter-property.component';
import { StripChartPropertyComponent } from '../custom-view-widget/strip-chart/strip-chart-property.component';
import { StatusLabelPropertyComponent } from '../custom-view-widget/status-label-widget/status-label-property/status-label-property.component';
import { CommandButtonPropertyComponent } from '../custom-view-widget/command-button-widget/command-button-property/command-button-property.component';
import { DefaultSitePropertyComponent } from '../custom-view-widget/default-site-widget/default-site-property/default-site-property.component';
import { ImagePropertyComponent } from '../custom-view-widget/image-widget/image-property/image-property.component';
import { LabelPropertyComponent } from '../custom-view-widget/label-widget/label-property/label-property.component';
import { MacroPropertyComponent } from '../custom-view-widget/macro-widget/macro-property/macro-property.component';
import { MeterStatusCommandFullRowPropertyComponent } from '../custom-view-widget/meter-status-command-full-row/meter-status-command-full-row-property/meter-status-command-full-row-property.component';
import { MeterStatusCommandFullRowHeaderPropertyComponent } from '../custom-view-widget/meter-status-command-full-row-header/msc-full-row-header-property/meter-status-command-full-row-header-property.component';
import { MeterTextPropertyComponent } from '../custom-view-widget/meter-text-widget/meter-text-property/meter-text-property.component';
import { MSCChannelPropertyComponent } from '../custom-view-widget/mscchannel/mscchannel-property/mscchannel-property.component';
import { TimerPropertyComponent } from '../custom-view-widget/timer-widget/timer-property/timer-property.component';
import { ClearAlarmsPropertyComponent } from '../custom-view-widget/clear-alarms-widget/clear-alarms-property/clear-alarms-property.component';
import { IframePropertyComponent } from '../custom-view-widget/iframe-widget/iframe-property/iframe-property.component';
import { UrlPropertyComponent } from '../custom-view-widget/url-widget/url-property/url-property.component';
import { LabelFullWidthPropertyComponent } from '../custom-view-widget/label-full-width/label-full-width-property/label-full-width-property.component';
import { LedButtonPropertyComponent } from '../custom-view-widget/led-button/led-button-property/led-button-property.component';

export class WidgetData
{
  public label: string;
  public value: any;
}

/**
 * get the set of widget labels with the widget's property component.
 */
@Injectable({
  providedIn: 'root'
})
export class ListOfWidgetsService
{
  /**
   * Array of the property components to create all the tiles.
   * TODO: the label should be able to be generated from the Component.
   */
  private widgets: WidgetData[] = [
    { label: "Strip Chart", value: StripChartPropertyComponent },
    { label: "Angular Meter", value: AngularMeterPropertyComponent },
    { label: "Status Label", value: StatusLabelPropertyComponent },
    { label: "Command Button", value: CommandButtonPropertyComponent },
    { label: "LED Button", value: LedButtonPropertyComponent },
    { label: "Default Site", value: DefaultSitePropertyComponent },
    { label: "Image", value: ImagePropertyComponent },
    { label: "Label", value: LabelPropertyComponent },
    { label: "Macro", value: MacroPropertyComponent },
    { label: "Meter Status Command Row", value: MeterStatusCommandFullRowPropertyComponent },
    { label: "Full Row Header", value: MeterStatusCommandFullRowHeaderPropertyComponent },
    { label: "Text Meter", value: MeterTextPropertyComponent },
    { label: "M/S/C Channel", value: MSCChannelPropertyComponent },
    { label: "Timer", value: TimerPropertyComponent },
    { label: "Clear Alarms", value: ClearAlarmsPropertyComponent },
    { label: "IFrame", value: IframePropertyComponent },
    { label: "URL", value: UrlPropertyComponent },
    { label: "Label Full Width", value: LabelFullWidthPropertyComponent },

  ];

  constructor() { }

  public get(): WidgetData[]
  {
    return this.widgets;
  }

  /**
   * Get the component from a name (string).
   * @param name
   */
  public getComponent(name: string): any
  {
    let result = null;
    this.widgets.forEach(d =>
    {
      if (d.label == name)
      {
        result = d.value;
      }
    });

    return result;
  }
}
