import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { EventData, EventService } from '../services/event.service';
import { RealTimeUpdate } from '../custom-view-widget/custom-view-widget.component';
import { DataSubscriptionService } from '../services/data-subscription.service';

@Component({
  selector: 'app-event-table',
  templateUrl: './event-table.component.html',
  styleUrls: ['./event-table.component.css']
})
export class EventTableComponent implements OnInit, RealTimeUpdate, OnDestroy
{
  @Input() sites: string[] = [];
  @Input() pageSize: string[];

  @Output() close = new EventEmitter();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<EventData>;

  private dataSource: MatTableDataSource<EventData>;
  private columnsToDisplay: string[] = [
    "siteName", "dateTime", "message"
  ];

  private loading: boolean = true;

  constructor(private dataSubcription: DataSubscriptionService,
    private eventService: EventService)
  { }
  ngOnDestroy(): void
  {
    this.dataSubcription.Remove(this);
  }

  ngOnInit()
  {
    this.UpdateSites();
  }
  ngOnChanges()
  {
    this.UpdateSites();
  }

  UpdateSites()
  {
    if (this.sites == null)
      return;

    this.loading = true;

    this.eventService.getEvents(this.sites).subscribe((d) =>
    {
      this.loading = false;

      d.map((val) =>
      {
        // clean up the site name
        val.siteName = val.siteName.replace(/\0/g, '');
      });

      this.dataSource = new MatTableDataSource<EventData>(d);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.sites.forEach((site) =>
      {
        this.dataSubcription.Add(this, [
          {
            "site": site,
            "block": "Event",
            "channel": 0,
            "pid": "New"
          },
        ]);
      });
    });
  }

  //////////////////////////////////////////////////////////////////////////////
  // Update
  //////////////////////////////////////////////////////////////////////////////
  Update(site: string, pid: string, channel: number, value: string)
  {
    //console.log("" + site + pid + channel + value);

    if (typeof value != "string")
    {
      return;
    }

    let event = this.CreateEventData(value);

    switch (pid)
    {
      case "New":
        this.dataSource.data.push(event);
        this.dataSource._updateChangeSubscription();
        break;
    }
  }

  //////////////////////////////////////////////////////////////////////////////
  // CreateEventData
  //
  // Given a string from the web server, break it apart and create an EventData
  // object.
  //////////////////////////////////////////////////////////////////////////////
  CreateEventData(str: string): EventData
  {
    let event = new EventData();
    let split = str.split('~');

    event.siteId = +split[0];
    event.dateTime = split[1];
    event.message = split[2];
    event.siteName = split[3];

    // clean up the site name
    event.siteName = event.siteName.replace(/\0/g, '');

    return event;
  }

  /**
   * 
   * @param $event 
   */
  onClick($event: Event)
  {
    event.stopPropagation();
  }

  /**
   * When you click on the paginator, stop the propagation of the click event.
   */
  onClickPaginator(event: Event)
  {
    event.stopPropagation();

  }

  /**
   * User clicked the close button.
   */
  onClose()
  {
    this.close.emit();
  }
}
