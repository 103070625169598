import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { StatusConditionTypesService, ConditionTypes, ChannelType } from '../services/status-condition-types.service';
import { ChannelAdaptorComponent } from '../channel-adaptor/channel-adaptor.component';
import { StatusChannelAdaptor } from '../services/status-channel-adaptor';

export class StatusAdaptorData
{
  public conditionType: number;
  public site: string;
  public channels: number[];
}

@Component({
  selector: 'app-status-channel-adaptor',
  templateUrl: './status-channel-adaptor.component.html',
  styleUrls: ['./status-channel-adaptor.component.css']
})
export class StatusChannelAdaptorComponent implements OnInit
{
  @Input() myConfiguration: StatusChannelAdaptor; // obj with condition and channel fields
  @Input() multiselect: boolean;

  @Output() changed = new EventEmitter();
  @Output() selectedChannels = new EventEmitter<number[]>();

  protected conditions: ConditionTypes[] = [];
  protected channelType: ChannelType;
  protected selectedCondition: ConditionTypes;

  public ChannelType: any = ChannelType; // this is so that the template can access the enum.

  /**
   * 
   * @param statusConditionsService 
   */
  constructor(
    private statusConditionsService: StatusConditionTypesService
  )
  {
    this.conditions = this.statusConditionsService.get();
  }

  /**
   * 
   */
  ngOnInit()
  {
    if (this.conditions.length > 0 && this.myConfiguration != null)
    {
      this.selectedCondition = this.conditions[this.myConfiguration.condition];
      this.channelType = this.selectedCondition.channelType;
    }
  }

  /**
   * The condition selection has changed.
   * Update the type of channel needed.
   * @param $event 
   */
  onChanged($event: ConditionTypes)
  {
    this.myConfiguration.condition = $event.value;
    this.channelType = $event.channelType;
    this.changed.emit();
  }

  /**
   * The site changed.  
   * @param $event The new site ID
   */
  onNewSiteSelected($event: string)
  {
    if (this.myConfiguration == null) return;

    this.myConfiguration.site = $event;
    this.changed.emit();

    // if this condition is for a site...
    if (this.conditions[this.myConfiguration.condition].channelType == ChannelType.Site)
    {
      if (this.myConfiguration.channel == null)
      {
        this.myConfiguration.channel = 0;
      }

      // send out a channel so the configuration is complete.
      this.selectedChannels.emit([this.myConfiguration.channel]);
    }
  }

  /**
   * The Channel changed.
   * @param $event An array of the selected channels
   */
  onSelectedChannelChanged($event: number[])
  {
    this.selectedChannels.emit($event);
  }

}
