import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contrastColor'
})
export class ContrastColorPipe implements PipeTransform
{
  ///////////////////////////////////////////////////////////////////////////
  // transform
  //
  // Transform a color (#RRGGBB) to either black (#000000) or white (#FFFFFF)
  // based on how dark the color is.
  ///////////////////////////////////////////////////////////////////////////
  transform(value: string): string
  {
    // if the value is null, return ""
    if (value == null)
    {
      return "";
    }
    // if the value is not the correct format, return ""
    if (value.length != 7)
    {
      return "";
    }
    if (value[0] != "#")
    {
      return "";
    }

    // value = "#rrggbb"
    let red = parseInt(value.substr(1, 2), 16);
    let green = parseInt(value.substr(3, 2), 16);
    let blue = parseInt(value.substr(5, 2), 16);

    if (isNaN(red) || isNaN(green) || isNaN(blue))
    {
      return "";
    }

    // convert to grayscale using NTSC formula http://support.ptc.com/help/mathcad/en/index.html#page/PTC_Mathcad_Help/example_grayscale_and_color_in_images.html
    // and subtract from 1 to get the inverse grayscale.
    let a = 1 - (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

    // if the inverse grayscale is dark, make it black.
    if (a < 0.5)
    {
      return "#000000";
    }
    else // otherwise make it white
    {
      return "#FFFFFF";
    }
  }
}
