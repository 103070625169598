import { Component, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { CustomViewWidgetComponent, CustomViewWidgetData } from '../custom-view-widget.component';
import { DataOneShotService } from '../../services/data-one-shot.service';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { StatusChannelAdaptor } from '../../services/status-channel-adaptor';
import { StatusConditionTypesService, StatusData, StatusValue } from '../../services/status-condition-types.service';
import { Ternary } from '../../pipes/macro-status.pipe';
import { SiteConfiguration, SiteWidgetComponent } from '../site-widget/site-widget.component';

export class StatusLabelConfiguration extends SiteConfiguration
{
  adaptor: StatusChannelAdaptor;
  hideLabel: boolean;
}

@Component({
  selector: 'app-status-label-widget',
  templateUrl: './status-label-widget.component.html',
  styleUrls: ['./status-label-widget.component.css',
    '../custom-view-widget.component.css',]
})
export class StatusLabelWidgetComponent extends SiteWidgetComponent
{
  CurrentColor: number;
  CurrentMessage: string;
  hideLabel: boolean;

  private adaptor: StatusChannelAdaptor;

  private onMessage: string;
  private offMessage: string;

  private onColor: number;
  private offColor: number;

  private statusStatus: boolean;

  private maxLines: number;
  private isAllowed: boolean;

  @ViewChild('inner') inner: ElementRef;
  @ViewChild('outer') outer: ElementRef;

  private tooltipTextLabel: string;
  private statusData: StatusData;

  /**
   * 
   * @param ds 
   * @param oneshot 
   * @param statusCondition 
   */
  constructor(
    protected ds?: DataSubscriptionService,
    protected oneshot?: DataOneShotService,
    private statusCondition?: StatusConditionTypesService,
  ) 
  {
    super(ds, oneshot);
  }

  /**
   * Overrides the base CustomViewWidget GetType.
   */
  static GetType(): string { return "Status Label"; }

  Configure(obj: StatusLabelConfiguration)
  {
    // console.log("Status Led configure");

    super.Configure(obj);

    if (obj.adaptor)
    {
      this.adaptor = new StatusChannelAdaptor(obj.adaptor);
    }
    else
    {
      this.adaptor = new StatusChannelAdaptor(obj);
    }

    this.statusData = this.statusCondition.getStatusData(this.adaptor.condition);

    this.onColor = this.statusData.onColorStatic;
    this.offColor = this.statusData.offColorStatic;
    this.onMessage = this.statusData.onMessage;
    this.offMessage = this.statusData.offMessage;

    this.hideLabel = false;
    if (obj.hideLabel)
    {
      this.hideLabel = true;
    }

    if (typeof obj.fontSize === "undefined")
    {
      obj.fontSize = 16;
    }
    this.fontSize = obj.fontSize;

    this.maxLines = Math.floor((obj.height - 10) / this.fontSize);

    // console.log("Status Led configure 2");
    if (this.adaptor.channel != null)
    {
      this.oneshot.getPermission(this.site, this.statusData.block, this.adaptor.channel).subscribe((b) =>
      {
        this.isAllowed = b;
        if (!this.isAllowed)
        {
          return;
        }
        this.oneshot.getPidParameters(this.site, this.statusData.block, this.adaptor.channel, this.statusData.pidList)
          .subscribe((res) =>
          {
            for (let p of res)
            {
              //console.log("OneShot: " + p.parameter + ":" + p.channel + " = " + p.value);

              for (let i = 0; i < this.statusData.pidList.length; i++)
              {
                if (p.parameter == this.statusData.pidList[0])
                {
                  if (p.value != null)
                  {
                    p.value = p.value.replace(/\0/g, '').trim();
                  }
                  this.onMessage = p.value;
                }

                if (p.parameter == this.statusData.pidList[1])
                {
                  if (p.value != null)
                  {
                    p.value = p.value.replace(/\0/g, '').trim();
                  }
                  this.offMessage = p.value;
                }

                if (p.parameter == this.statusData.pidList[2])
                {
                  this.onColor = +p.value;
                  // console.log("onColor: " + this.onColor);
                }

                if (p.parameter == this.statusData.pidList[3])
                {
                  this.offColor = +p.value;
                  // console.log("offColor: " + this.offColor);
                }
              }
            }

            this.UpdateMessageAndColor();
          });
      });
    }

    return this;
  }

  /**
   * 
   */
  protected Subscribe()
  {
    super.Subscribe();

    let block = (this.statusData.rtBlock.length == 0) ? this.statusData.block : this.statusData.rtBlock;

    if (this.statusData)
    {
      this.statusData.rtPids.forEach((d) =>
      {
        //console.log("sub: " + d + ":" + this.adaptor.channel);
        this.ds.Add(this, [
          {
            "site": this.site,
            "block": block,
            "channel": this.adaptor.channel,
            "pid": d,
          }]);
      });
    }
  }

  /**
   * Called when a Real-Time update arrives.
   * @param site 
   * @param pid 
   * @param channel 
   * @param value 
   */
  Update(site: string, pid: string, channel: number, value: string)  
  {
    super.Update(site, pid, channel, value);

    if (this.site != site)
      return;
    if (this.adaptor.channel != channel)
      return;

    // if the pid is not part of the rtPids, return
    if (this.statusData.rtPids.indexOf(pid) == -1)
      return;

    //console.log("Update: " + site + ":" + pid + ": " + channel + " = " + value);

    switch (this.statusData.Update(pid, value))
    {
      case StatusValue.STATUS_OFF:
        // console.log("Status Channel " + this.adaptor.channel + " OFF");
        this.statusStatus = false;
        break;
      case StatusValue.STATUS_ON:
        // console.log("Status Channel " + this.adaptor.channel + " ON");
        this.statusStatus = true;
        break;
      case StatusValue.STATUS_OFFLINE:
        // console.log("Status Channel " + this.adaptor.channel + " OFFLINE");
        this.statusStatus = null;
        break;
    }

    this.UpdateMessageAndColor();
    //this.UpdateTooltip();
  }

  UpdateMessageAndColor()
  {
    if (this.statusStatus == null)
    {
      this.CurrentColor = 143 - 15; // gray 0xCC, 0xCC, 0xCC
      this.CurrentMessage = this.calcStatusChannelOfflineMsg(this.onMessage, this.offMessage, this.adaptor.channel);
    }
    else if (this.statusStatus == true)
    {
      this.CurrentColor = this.onColor;
      this.CurrentMessage = this.onMessage;
    }
    else if (this.statusStatus == false)
    {
      this.CurrentColor = this.offColor;
      this.CurrentMessage = this.offMessage;
    }

    if (!this.online)
    {
      this.statusStatus = false;
      this.CurrentColor = 143 - 15; // gray 0xCC, 0xCC, 0xCC
      this.CurrentMessage = "Site Offline";
    }

  }

  get statusChannelOfflineMsg(): string
  {
    return this.calcStatusChannelOfflineMsg(this.onMessage, this.offMessage, this.adaptor.channel);
  }

  UpdateTooltip()
  {
    setTimeout(() =>
    {

      this.tooltipTextLabel = this.TooltipTextLabel();
    }, 0);
  }
  TooltipTextLabel()
  {
    return this.TooltipText(this.outer, this.inner);
  }

  TooltipText(outer: ElementRef, inner: ElementRef): string
  {
    // at the beginning of the render, the element is not assigned yet.  Check for null to just return.
    if (inner == null)
    {
      return "";
    }

    if (outer.nativeElement.offsetHeight + this.fontSize < inner.nativeElement.scrollHeight)
    {
      return this.CurrentMessage;
    }

    // the element is big enough to fit everything, there is no tooltip.  Return a blank label.
    return "";
  }

}
