/////////////////////////////////////////////////////////
// Point
//
// Model of a value in time.
/////////////////////////////////////////////////////////
export class Point
{
	public x: Date;
	public y: number;

	constructor(init?: Partial<Point>)
	{
		this.x = new Date();
		this.y = null;

		Object.assign(this, init);
	}
}

