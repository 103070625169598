import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class CommandData
{
  site: string;
  channel: number;
  raiseLower: boolean;
}

export class MacroCommandData
{
  site: string;
  channel: number;
  runStop: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CommandService
{

  ///////////////////////////////////////////////////////////////////////////
  // constructor
  ///////////////////////////////////////////////////////////////////////////
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  )
  { }

  command(cmd: CommandData): Observable<CommandData>
  {
    return this.http.put<CommandData>(this.baseUrl + 'api/Pages/Command', cmd);
  }

  macro(cmd: MacroCommandData): Observable<MacroCommandData>
  {
    return this.http.put<MacroCommandData>(this.baseUrl + 'api/Pages/Macro', cmd);
  }
}
