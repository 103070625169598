import
{
  Injectable,
  ComponentFactoryResolver,
  Inject,
  ViewContainerRef,
  ComponentRef,
  ApplicationRef
} from '@angular/core';
import { CustomViewWidgetComponent } from '../custom-view-widget/custom-view-widget.component';
import { ListOfWidgetsService } from './list-of-widgets.service';

@Injectable({
  providedIn: 'root'
})
export class CreateWidgetService
{

  constructor(
    @Inject(ComponentFactoryResolver) private factoryResolver: ComponentFactoryResolver,
    private listofWidgets: ListOfWidgetsService,
    private app: ApplicationRef,
  )
  {
  }

  //////////////////////////////////////////////////////////////////////////
  // addWidget
  //
  // Based on the type, create the component and add it to the View.
  //////////////////////////////////////////////////////////////////////////
  addWidget(obj: any, viewContainerRef: ViewContainerRef, preventClicks?: boolean): CustomViewWidgetComponent
  {
    if (obj == null)
    {
      return null;
    }

    let comp = null;
    this.listofWidgets.get().forEach((d) =>
    {
      if (d.label == obj.type)
      {
        let property = new d.value();
        comp = this.addComponent(property.GetComponent(), obj, viewContainerRef, preventClicks);
      }
    });

    return comp;
  }

  //////////////////////////////////////////////////////////////////////////
  // addComonent
  //
  // Pass in the widget type to create.  Use the factory to create it.
  // If the object is a CustomViewWidget, then Configure it.
  //////////////////////////////////////////////////////////////////////////
  private addComponent(widgetType: any, obj: any, viewContainerRef: ViewContainerRef, preventClicks?: boolean): CustomViewWidgetComponent
  {
    const factory = this.factoryResolver.resolveComponentFactory(widgetType);
    const component = factory.create(viewContainerRef.parentInjector);
    viewContainerRef.insert(component.hostView);

    let widget = component.instance as CustomViewWidgetComponent;
    widget.Configure(obj);

    if (preventClicks)
    {
      widget.PreventClicks();
    }
    widget.StartSubscription();

    return widget;
  }
}
