import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scaledValue'
})
export class ScaledValuePipe implements PipeTransform
{

  ///////////////////////////////////////////////////////////////////////////
  // transform
  //
  // Scale a value to put the decimal place in the correct position
  ///////////////////////////////////////////////////////////////////////////
  transform(value: number, decimalPlace: number): string
  {
    let scaledValue = value / Math.pow(10, decimalPlace);

    // if the value is 0x7777, then no data
    if (value == 30583)
    {
      return "n/a";
    }

    if (decimalPlace < 0 || decimalPlace > 100)
    {
      return "n/a";
    }

    return scaledValue.toFixed(decimalPlace);
  }
}
