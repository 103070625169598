import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { OptionDef } from '../OptionDef';
import { SitesService } from '../services/sites.service';
import { Subject } from 'rxjs';

class SiteData
{
  alias: number;
  name: string;
}

@Component({
  selector: 'app-site-multi-selector',
  templateUrl: './site-multi-selector.component.html',
  styleUrls: ['./site-multi-selector.component.css']
})
export class SiteMultiSelectorComponent implements OnInit
{
  @Input() currentSites: number[];
  @Output() newSitesSelected = new EventEmitter();

  private siteOptions: OptionDef[] = [];

  @Output() selectedItems: Subject<number[]> = new Subject<number[]>();

  private internalSelectedItems: number[] = [];
  private isChecked: boolean[] = [];
  private sites: SiteData[];

  constructor(private siteService: SitesService) { }

  ngOnInit()
  {
  }

  /////////////////////////////////////////////////////////////////
  // ngOnChanges
  //
  // The list of currentSites changed.  Reload the existing sites.
  // Mark the current sites.
  /////////////////////////////////////////////////////////////////
  ngOnChanges(changes: SimpleChanges): void
  {
    this.siteService.getSites().subscribe((sites) =>
    {
      this.siteOptions = [];
      sites.forEach((data, i) =>
      {
        this.siteOptions.push(new OptionDef("" + data.alias, data.name));
      });

      // if nothing is selected...
      if (this.currentSites == null || this.currentSites.length == 0)
      {

        this.currentSites = [];
        this.currentSites.push(sites[0].alias);
        this.onChange(this.currentSites);
      }
    });
  }

  /////////////////////////////////////////////////////////////////
  // onChange
  //
  // When the checkboxes change, emit the new value.
  /////////////////////////////////////////////////////////////////
  onChange(newValues: number[])
  {
    this.newSitesSelected.emit(newValues);
  }

  ////////////////////////////////////////////////////////
  // onCheckboxChanged
  //
  // Update selectedItems with the new set of selected channels.
  ////////////////////////////////////////////////////////
  onCheckboxChanged(pid, onOff)
  {
    // if the checkbox is now checked...
    if (onOff)
    {
      // add this channel to the sorted list.
      this.internalSelectedItems.push(pid.channel);
      this.internalSelectedItems.sort((a, b) => { return a - b; });
    }
    else // the checkbox is now unchecked...
    {
      // https://stackoverflow.com/a/5767357/9516
      // Find the channel in the sorted list.
      let index = this.internalSelectedItems.indexOf(pid.channel);
      // if it is found...
      if (index > -1)
      {
        // splice out this channel
        this.internalSelectedItems.splice(index, 1);
      }
    }

    // Output the sorted list of selected channels.
    this.selectedItems.next(this.internalSelectedItems);
  }

}
