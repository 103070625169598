import { Component, Inject, ComponentFactoryResolver } from '@angular/core';
import { ChannelPropertyComponent } from '../../channel-property/channel-property.component';
import { CommandButtonConfiguration } from '../command-button-widget.component';
import { CommandButtonWidgetComponent } from '../command-button-widget.component';
import { TileSize, DoubleWide_SingleHeight, FourxWide_DoubleHeight, EightxWide_FourxHeight, SingleWide_HalfHeight } from '../../../size-selector/size-selector.component';

@Component({
  selector: 'app-command-button-property',
  templateUrl: './command-button-property.component.html',
  styleUrls: ['./command-button-property.component.css']
})
export class CommandButtonPropertyComponent extends ChannelPropertyComponent<CommandButtonWidgetComponent>
{
  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(CommandButtonWidgetComponent, factoryResolver);

    let cfg = new CommandButtonConfiguration();

    cfg.type = CommandButtonWidgetComponent.GetType();

    this.Configure(cfg);
  }

  /**
   * Return the valid sizes of this type of Tile
   */
  readonly sizes: TileSize[] =
    [
      SingleWide_HalfHeight,
      DoubleWide_SingleHeight,
      FourxWide_DoubleHeight,
      EightxWide_FourxHeight,
    ];

  /**
   * Return the index of the array of sizes returned from GetSizes() that is 
   * the default size.
   */
  GetDefaultSize(): number { return 1; }

}
