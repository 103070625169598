import { Component } from '@angular/core';
import { AccordianTabData, GroupPanelService } from '../services/group-panel.service';
import { Subject } from 'rxjs';
import { EditModeService } from '../services/edit-mode.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-group-summary-panel-wrapper',
  templateUrl: './group-summary-panel-wrapper.component.html',
  styleUrls: ['./group-summary-panel-wrapper.component.css']
})
export class GroupSummaryPanelWrapperComponent
{
  private groupPanelData: AccordianTabData[] = [];
  private groupPanelDataChanged = new Subject<AccordianTabData>();
  private editMode: boolean;

  constructor(
    private groupPanelService: GroupPanelService,
    private editModeService: EditModeService,
  ) 
  {
    // get the configuration of the group panels.
    this.groupPanelService.get().subscribe((data) =>
    {
      this.groupPanelData = data;
    });

    // if any group panel is changed (edited), then this will push the data to the server.
    this.groupPanelDataChanged.subscribe(() => 
    {
      this.groupPanelService.update(this.groupPanelData).subscribe(() => { });
    });

    // get a copy of the edit mode when it changes.
    this.editModeService.get().subscribe(b =>
    {
      this.editMode = b;
    });

  }

  /**
   * User clicked the Add Tab button.  Add a new Group Panel Data object and notify everyone of the change.
   */
  addTab()
  {
    this.groupPanelData.push(new AccordianTabData());
    this.groupPanelDataChanged.next();
  }

  /**
   * A tab in the list was cut.  Remove it.
   * @param index the index of the tab to remove.
   */
  onTabCut(index: number)
  {
    this.groupPanelData.splice(index, 1);
  }

  onTabDrag(event)
  {

  }

  /**
   * The user dropped a tab in a new place.  Move the items in the array. Save the state by calling DataChanged.
   * @param event 
   */
  drop(event: CdkDragDrop<string[]>)
  {
    moveItemInArray(this.groupPanelData, event.previousIndex, event.currentIndex);
    this.groupPanelDataChanged.next();
  }
}
