import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'alarmSeverity'
})
export class AlarmSeverityPipe implements PipeTransform
{
  ///////////////////////////////////////////////////////////////////////////
  // transform
  //
  // Convert the alarm severity number to a string.
  ///////////////////////////////////////////////////////////////////////////
  transform(value: string): string
  {
    switch (value)
    {
      default: return "Unknown";
      case "0": return "Warning";
      case "1": return "Critical";
    }
  }
}
