import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ListOfWidgetsService, WidgetData } from '../services/list-of-widgets.service';
import { CustomViewPropertyComponent } from '../custom-view-widget/custom-view-property.component';

/**
 * Show a drop-down to select a component type.  
 * Optionally start with an initial configuration.
 * Emit the Property Component for that type.
 */
@Component({
  selector: 'app-list-of-widgets',
  templateUrl: './list-of-widgets.component.html',
  styleUrls: ['./list-of-widgets.component.css']
})
export class ListOfWidgetsComponent implements OnInit
{
  @Input() initialConfiguration;
  @Output() tileTypeSelected = new EventEmitter(); // output the component property type

  private types: WidgetData[];

  constructor(
    private listOfWidgets: ListOfWidgetsService,
  )
  { }

  ///////////////////////////////////////////////////////////////
  // ngOnInit
  //
  // Get the list of widgets and their property components
  // Add them to the list.
  ///////////////////////////////////////////////////////////////
  ngOnInit()
  {
    this.types = this.listOfWidgets.get();
  }

  ///////////////////////////////////////////////////////////////
  // onChange
  //
  // This is called when the select drop-down changes.
  // Emit the Property Component for the selected widget.
  ///////////////////////////////////////////////////////////////
  onChanged(event: WidgetData)
  {
    this.tileTypeSelected.emit(event.value);
  }

}
