import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomViewPageListChangedService
{
  emitter: EventEmitter<any> = new EventEmitter();

  // Usage: 
  //   source.emitter.emit();  <-- broadcasts the event that the Custom View Page List has changed.
  //
  //   dest.emitter.subscribe( () => {});  <-- received the broadcast and can run code to reload the page.
}
