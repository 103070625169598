import { ContentObserver } from '@angular/cdk/observers';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';


/**
 * Show a lightbox with centered inputs for max width/height.
 * Output the max width and height values as they change so the background can be redrawn.
 * If cancelled, output the original values to restore and output a done signal.
 * If OK, output the done signal.
 */
@Component({
  selector: 'app-max-width-height',
  templateUrl: './max-width-height.component.html',
  styleUrls: ['./max-width-height.component.css']
})
export class MaxWidthHeightComponent implements OnInit
{
  @Input() maxWidth: number;
  @Input() maxHeight: number;

  @Output() newWidth = new EventEmitter<number>();
  @Output() newHeight = new EventEmitter<number>();
  @Output() close = new EventEmitter();

  private origWidth: number;
  private origHeight: number;

  private form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder)
  {
    this.form = new UntypedFormGroup({
      maxHeight: new UntypedFormControl(null),
      unlimitedHeight: new UntypedFormControl(null),

      maxWidth: new UntypedFormControl(null),
      unlimitedWidth: new UntypedFormControl(null),
    },
      this.CreateValidateForm()
    );

  }

  private CreateValidateForm(): ValidatorFn
  {
    return (group: UntypedFormGroup): ValidationErrors | null =>
    {
      if (this.form == null)
      {
        return;
      }

      const h = this.form.controls['maxHeight'];
      const uh = this.form.controls['unlimitedHeight'];

      if (uh.value == false) // not checked, not unlimited...
      {
        if (h.value == null) // does not have a value...
        {
          return { 'height-must-have-value': true };
        }
        if (h.value < 1 || h.value > 99)
        {
          return { 'height-out-of-range': true };
        }
      }

      const w = this.form.controls['maxWidth'];
      const uw = this.form.controls['unlimitedWidth'];

      if (uw.value == false) // not checked, not unlimited...
      {
        if (w.value == null) // does not have a value...
        {
          return { 'width-must-have-value': true };
        }
        if (w.value < 1 || w.value > 99)
        {
          return { 'width-out-of-range': true };
        }
      }

      return null;
    }
  }

  ngOnInit(): void
  {
    // save the starting values.
    this.origWidth = this.maxWidth;
    this.origHeight = this.maxHeight;

    const uh = this.form.controls['unlimitedHeight'];
    const h = this.form.controls['maxHeight'];
    const uw = this.form.controls['unlimitedWidth'];
    const w = this.form.controls['maxWidth'];

    // assign the input fields and checkboxes.
    if (this.maxHeight != null)
    {
      h.setValue(this.maxHeight);
      uh.setValue(false);
      this.ToggleUnlimitedHeightCheckbox(false, h);
    }
    else
    {
      h.setValue(null);
      uh.setValue(true);
      this.ToggleUnlimitedHeightCheckbox(true, h);
    }

    if (this.maxWidth != null)
    {
      w.setValue(this.maxWidth);
      uw.setValue(false);
      this.ToggleUnlimitedHeightCheckbox(false, w);
    }
    else
    {
      w.setValue(null);
      uw.setValue(true);
      this.ToggleUnlimitedHeightCheckbox(true, w);
    }

    // if the checkbox changes, enable/disable the input field.
    uh.valueChanges.subscribe(x =>
    {
      this.ToggleUnlimitedHeightCheckbox(x, h);
    });
    uw.valueChanges.subscribe(x =>
    {
      this.ToggleUnlimitedHeightCheckbox(x, w);
    });
  }

  private ToggleUnlimitedHeightCheckbox(x: boolean, h: AbstractControl)
  {
    if (x)
    {
      h.disable();
    }
    else
    {
      h.enable();
      h.setValue(99);
    }
  }

  /**
   * User entered a new max height
   */
  onMaxHeightChanged()
  {
    const h = this.form.get("maxHeight") as UntypedFormControl;
    const uh = this.form.get("unlimitedHeight") as UntypedFormControl;
    if (uh.value)
    {
      this.newHeight.emit(null);
    }
    else 
    {
      this.newHeight.emit(h.value);
    }
  }

  /**
   * User entered a new max width
   */
  onMaxWidthChanged()
  {
    const x = this.form.get("maxWidth") as UntypedFormControl;
    const ux = this.form.get("unlimitedWidth") as UntypedFormControl;

    if (ux.value)
    {
      this.newWidth.emit(null);
    }
    else
    {
      this.newWidth.emit(x.value);
    }
  }

  onOk()
  {
    this.close.emit();
  }

  /**
   * User clicked cancel.
   * Emit the original W/H to reset the calling component, then close.
   */
  onCancel()
  {
    this.newWidth.emit(this.origWidth);
    this.newHeight.emit(this.origHeight);
    this.close.emit();
  }

}

