import { Component, OnInit, EventEmitter, Input, Output, AfterViewInit } from '@angular/core';
import { ConditionTypes, ChannelType } from '../services/status-condition-types.service';

@Component({
  selector: 'app-channel-adaptor',
  templateUrl: './channel-adaptor.component.html',
  styleUrls: ['./channel-adaptor.component.css']
})
export class ChannelAdaptorComponent implements OnInit
{

  @Input() myConfiguration; // obj with condition and channel fields
  @Input() multiselect: boolean;

  @Output() changed = new EventEmitter();
  @Output() selectedChannels = new EventEmitter<number[]>();

  protected conditions: ConditionTypes[] = [];
  protected channelType: ChannelType;
  protected selectedCondition: ConditionTypes;

  public ChannelType: any = ChannelType; // this is so that the template can access the enum.

  constructor() { }

  /**
   * 
   */
  ngOnInit()
  {
    if (this.conditions.length > 0 && this.myConfiguration != null)
    {
      this.channelType = this.conditions[0].channelType;
      this.selectedCondition = this.conditions[this.myConfiguration.condition];
    }
  }

  /**
   * The condition selection has changed.
   * Update the type of channel needed.
   * @param $event 
   */
  onChanged($event: ConditionTypes)
  {
    this.myConfiguration.condition = $event.value;
    this.channelType = $event.channelType;
    this.changed.emit();
  }

  /**
   * The site changed.  
   * @param $event The new site ID
   */
  onNewSiteSelected($event: string)
  {
    if (this.myConfiguration == null) return;

    this.myConfiguration.site = $event;
    this.changed.emit();

    if (this.conditions[this.myConfiguration.condition].channelType == ChannelType.Site)
    {
      if (this.myConfiguration.channel == null)
      {
        this.myConfiguration.channel = [0];
      }

      this.selectedChannels.emit(this.myConfiguration.channel);
    }
  }

  /**
   * The Channel changed.
   * @param $event An array of the selected channels
   */
  onSelectedChannelChanged($event: number[])
  {
    this.selectedChannels.emit($event);
  }
}
