import { Component } from '@angular/core';
import { CustomViewWidgetComponent, CustomViewWidgetData } from '../custom-view-widget.component';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { DataOneShotService } from '../../services/data-one-shot.service';
import { AlarmService } from '../../services/alarm.service';
import { SiteConfiguration, SiteWidgetComponent } from '../site-widget/site-widget.component';

export class Configuration extends SiteConfiguration
{
  confirmation: boolean;
}

@Component({
  selector: 'app-clear-alarms-widget',
  templateUrl: './clear-alarms-widget.component.html',
  styleUrls: ['./clear-alarms-widget.component.css',
    '../custom-view-widget.component.css',]
})
export class ClearAlarmsWidgetComponent extends SiteWidgetComponent
{
  // channel adaptor
  private confirmation: boolean;

  //private siteName: string;
  private isAllowed: boolean = false;

  /**
   * Clear Alarms Widget
   * @param ds 
   * @param oneshot 
   * @param cs 
   */
  constructor(
    protected ds?: DataSubscriptionService,
    protected oneshot?: DataOneShotService,
    private as?: AlarmService,
  ) 
  {
    super(ds, oneshot);
  }

  /**
   * Overrides the base CustomViewWidget GetType.
   */
  static GetType(): string { return "Clear Alarms"; }

  Configure(obj: Configuration)
  {
    super.Configure(obj);

    this.confirmation = obj.confirmation;

    this.GetData();

    return this;
  }

  ///////////////////////////////////////////////////////////////////////////
  // GetData
  ///////////////////////////////////////////////////////////////////////////
  GetData()
  {
    this.oneshot.getPermission(this.site, "Alarm", 0).subscribe((b) =>
    {
      this.isAllowed = b;
      if (!this.isAllowed)
      {
        return;
      }
    });
  }

  /**
   * onClearAlarms
   */
  onClearAlarms() { this.ClearAlarms(); }

  /**
   * Clear the alarms on the site.
   */
  ClearAlarms()
  {
    if (!this.preventClicks)
    {
      let str = "Clear all alarms on " + this.siteName;

      // if we are not confirming the command, do it
      // or if we did confirm the command, do it.
      if (!this.confirmation || confirm(str + "?"))
      {
        this.as.ClearAllAlarms([+this.site]).subscribe();
      }
    }
  }
}

