import { Component, Inject, ComponentFactoryResolver } from '@angular/core';
import { Configuration } from '../../angular-meter-widget/angular-meter-widget.component';
import { TileSize, DoubleWide_SingleHeight, FourxWide_DoubleHeight, EightxWide_FourxHeight, SingleWide_HalfHeight } from '../../../size-selector/size-selector.component';
import { TimerWidgetComponent } from '../timer-widget.component';
import { ChannelPropertyComponent } from '../../channel-property/channel-property.component';

@Component({
  selector: 'app-timer-property',
  templateUrl: './timer-property.component.html',
  styleUrls: ['./timer-property.component.css']
})
export class TimerPropertyComponent extends ChannelPropertyComponent<TimerWidgetComponent>
{
  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(TimerWidgetComponent, factoryResolver);

    let cfg = new Configuration();

    cfg.type = TimerWidgetComponent.GetType();

    this.Configure(cfg);
  }

  /**
   * Return the valid sizes of this type of Tile
   */
  readonly sizes: TileSize[] =
    [
      SingleWide_HalfHeight,
      DoubleWide_SingleHeight,
      FourxWide_DoubleHeight,
      EightxWide_FourxHeight
    ];

  /**
   * Return the index of the array of sizes returned from GetSizes() that is 
   * the default size.
   */
  GetDefaultSize(): number { return 1; }

}
