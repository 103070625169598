import { Component, ComponentFactoryResolver, Inject } from '@angular/core';
import { DoubleWide_DoubleHeight, DoubleWide_SingleHeight, EightxWide_FourxHeight, FourxWide_DoubleHeight, FourxWide_SingleHeight, SingleWide_HalfHeight, SingleWide_SingleHeight, TileSize } from '../../../size-selector/size-selector.component';
import { CustomViewPropertyComponent } from '../../custom-view-property.component';
import { Configuration, UrlWidgetComponent } from '../url-widget.component';

@Component({
  selector: 'app-url-property',
  templateUrl: './url-property.component.html',
  styleUrls: ['./url-property.component.css']
})
export class UrlPropertyComponent extends CustomViewPropertyComponent<UrlWidgetComponent>

{
  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(UrlWidgetComponent, factoryResolver);

    let cfg = new Configuration();

    cfg.type = UrlWidgetComponent.GetType();

    this.Configure(cfg);
  }

  /**
   * Return the valid sizes of this type of Tile
   */
  readonly sizes: TileSize[] =
    [
      SingleWide_HalfHeight,
      SingleWide_SingleHeight,
      DoubleWide_SingleHeight,
      DoubleWide_DoubleHeight,
      FourxWide_SingleHeight,
      FourxWide_DoubleHeight,
      EightxWide_FourxHeight
    ];

  /**
   * Return the index of the array of sizes returned from GetSizes() that is 
   * the default size.
   */
  GetDefaultSize(): number { return 2; }

}
