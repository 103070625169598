import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { User } from '../models/User';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate
{

  constructor(
    private router: Router,
    private accountService: AccountService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
  {
    const user = this.accountService.userValue;

    // if the user is logged in, you are authorized.
    if (user) 
    {
      return true;
    }

    // not logged in.
    // refresh the token.  If that works, then we are allowed.
    return this.accountService.refreshToken().pipe(map((value: User) => 
    {
      return true;
    }));
  }
}
