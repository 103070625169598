import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform
{
  constructor(private sanitizer: DomSanitizer) { }

  // https://stackoverflow.com/a/38037914/9516
  transform(url)
  {
    if (url == null)
    {
      url = "";
    }
    //console.log("Trusting: " + url);

    let safe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    //console.log("safe: " + safe);
    return safe
  }

}
