import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

/**
 * The header above a default site widget or custom view widget.
 */
@Component({
  selector: 'app-singleton-header',
  templateUrl: './singleton-header.component.html',
  styleUrls: ['./singleton-header.component.css']
})
export class SingletonHeaderComponent implements OnInit
{

  @Input() name: string; // the name to put on the left-hand side.
  @Output() close = new EventEmitter(); // emits when the close button is pressed.

  constructor() { }

  ngOnInit()
  {
  }

  onClose(event: Event)
  {
    this.close.emit(event)
  }
}
