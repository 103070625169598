import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DataSubscriptionService } from '../services/data-subscription.service';

export interface RealTimeUpdate
{
  Update(site: string, pid: string, channel: number, value: string);
}

export class CustomViewWidgetData
{
  public type: string = "unknown";
  public width: number;
  public height: number;
  public backgroundColor: string = "lightgray";

  public fullWidth: boolean;
  public fontSize: number;
}

////////////////////////////////////////////////////////////
// CustomViewWidgetComponent
////////////////////////////////////////////////////////////
@Component({
  selector: 'app-custom-view-widget',
  templateUrl: './custom-view-widget.component.html',
  styleUrls: ['./custom-view-widget.component.css']
})
export class CustomViewWidgetComponent implements OnInit, RealTimeUpdate, OnDestroy
{
  protected width: number;
  protected height: number;
  protected backgroundColor: string;
  protected preventClicks: boolean;

  protected fontSize: number;
  protected numOfLines: number;

  protected myBackgroundColor = "gray";
  @ViewChild('myDiv') myDiv: ElementRef;

  ////////////////////////////////////////////////////////////
  // constructor
  //
  // The DataSubscriptionService must be optional, because
  // we need a default constructor (no parameters) to pass
  // into the CustomViewPropertyComponent to use with the 
  // component factory.
  ////////////////////////////////////////////////////////////
  constructor(protected ds?: DataSubscriptionService)
  {
  }

  static GetType(): string { return "unknown"; }

  ////////////////////////////////////////////////////////////
  // Subscribe
  // 
  // Subscribe to the real-time data with the datasubsription 
  // service.  Override this method in subclasses.
  ////////////////////////////////////////////////////////////
  protected Subscribe() { }

  ngOnInit() { }

  ///////////////////////////////////////////////////////////////////////////
  // ngOnDestroy
  //
  // Remove the subscriptions when this widget is destroyed.
  ///////////////////////////////////////////////////////////////////////////
  ngOnDestroy(): void
  {
    this.ds.Remove(this);
  }

  ////////////////////////////////////////////////////////////
  // Update
  //
  // This method is called when the data subscription service
  // receives an update from the web server.
  ////////////////////////////////////////////////////////////
  Update(site: string, pid: string, channel: number, value: string) { }

  ////////////////////////////////////////////////////////////
  // Configure
  //
  // This method is called when the widget is created.
  ////////////////////////////////////////////////////////////
  Configure(obj: any): CustomViewWidgetComponent 
  {
    this.width = obj.width;
    this.height = obj.height;
    this.fontSize = obj.fontSize;

    // if the font size was not defined, give it a default of 18.
    if (this.fontSize == null)
    {
      this.fontSize = 18;
    }

    this.numOfLines = Math.floor((this.height - 4) / (this.fontSize * 1.5)); // -4 is for 2 px padding on top and bottom.
    if (this.numOfLines == 0)
    {
      this.numOfLines = 1;
    }


    if (typeof obj.backgroundColor === "undefined")
    {
      this.backgroundColor = "#294552";
    }
    else
    {
      this.backgroundColor = obj.backgroundColor;
    }

    this.CalculateBackgroundColor();
    return this;
  }

  /**
   * Calculate the color of the background.
   */
  CalculateBackgroundColor()
  {
    // After the view is set, wait 0ms and then get the background rgb value so we can calculate the contrasting color.
    setTimeout(() =>
    {
      if (this.myDiv != null)
      {
        this.myBackgroundColor = window.getComputedStyle(this.myDiv.nativeElement)['background-color'];
      }
    }, 0);
  }

  ////////////////////////////////////////////////////////////
  // PreventClicks
  //
  // Prevent any actions on this widget.
  ////////////////////////////////////////////////////////////
  PreventClicks()
  {
    this.preventClicks = true;
  }

  ////////////////////////////////////////////////////////////
  // StartSubscription
  //
  // Start listening for the data subscription events to 
  // subscribe to the real-time data.
  ////////////////////////////////////////////////////////////
  StartSubscription()
  {
    // remove any existing subscriptions.
    this.ds.Remove(this);

    this.Subscribe();
  }
}
