import { Injectable, PipeTransform } from '@angular/core';
import { MacroStatusPipe, IConvertToTernary } from '../pipes/macro-status.pipe';
import { DataSubscriptionService } from './data-subscription.service';

export enum ChannelType
{
  Site,
  Meter,
  Status,
  Command,
  Macro,
};

export class ChannelHelper
{
  static convertChannelStateToOnline(state: string): boolean
  {
    return (+state) === 1;
  }
}

export enum StatusValue
{
  STATUS_OFF,
  STATUS_ON,
  STATUS_OFFLINE,
}

export class ConditionTypes
{
  public value: number;
  public label: string;
  public channelType: ChannelType;

  public data: StatusData;
}

export class StatusData
{
  public block: string;

  public onColorStatic: number; // the pallette number
  public offColorStatic: number;

  public onMessage: string = "";
  public offMessage: string = "";

  public pidList: string[];

  public rtBlock: string = "";
  public rtPids: string[];

  protected online: boolean;
  protected status: boolean;

  /**
   * Given new information about the Status, return the state of the channel
   * @param pid 
   * @param value 
   */
  public Update(pid: string, value: string): StatusValue
  {
    if (pid == this.rtPids[0])
    {
      this.status = !!+value;
    }
    return (this.status) ? StatusValue.STATUS_ON : StatusValue.STATUS_OFF;
  }

  getCurrentValue(): StatusValue
  {
    // if the channel is online...
    if (this.online)
    {
      // console.log("status: " + ((this.status) ? StatusValue.STATUS_ON : StatusValue.STATUS_OFF));
      return (this.status) ? StatusValue.STATUS_ON : StatusValue.STATUS_OFF;
    }
    else // the channel is offline.
    {
      // console.log("offline");
      return StatusValue.STATUS_OFFLINE;
    }
  }

}

/**
 * Handle getting many pids to evaulate if any are out of tolerance.
 */
export class MeterOutOfToleranceData extends StatusData
{
  private limits: boolean[] = [false, false, false, false];

  /**
   * Update the internal limits flags and return A && B && C && D.
   * @param pid 
   * @param value 
   */
  public Update(pid: string, value: string): StatusValue
  {
    this.rtPids.forEach((p, i) =>
    {
      if (p == pid)
      {
        this.limits[i] = !!+value;
      }
    });

    let retVal = false;
    this.limits.forEach(element =>
    {
      if (element)
      {
        retVal = true;
      }
    });
    return (retVal) ? StatusValue.STATUS_ON : StatusValue.STATUS_OFF;;
  }

}

class StatusChannelData extends StatusData 
{
  public block = "Status";
  public onColorStatic = 62 - 15;
  public offColorStatic = 132 - 15;
  public pidList = ["StatusOnMessage",
    "StatusOffMessage",
    "StatusOnColor",
    "StatusOffColor",
  ];
  public rtPids = [
    "StatusStatus",
    "StatusState",
  ];

  /**
   * separate the Status from the State
   * @param pid 
   * @param value 
  */
  public Update(pid: string, value: string): StatusValue
  {
    // set the status and online flags
    switch (pid)
    {
      case this.rtPids[0]:
        this.status = !!+value;
        break;
      case this.rtPids[1]:
        this.online = ChannelHelper.convertChannelStateToOnline(value);
        break;
    }

    return this.getCurrentValue();
  }

};

class RaiseCommandChannelData extends StatusData 
{
  public block = "Output";
  public onColorStatic = 62 - 15; // green
  public offColorStatic = 148 - 15; // grey
  public pidList = [
    "OutputLcdRaiseLabel",
    "OutputLcdRaiseLabel",
    "",
    "",
  ];
  public rtPids = [
    "OutputRelayState",
    "OutputState",
  ];

  /**
   * 
   * @param pid 
   * @param value 
   */
  public Update(pid: string, value: string): StatusValue
  {
    switch (pid)
    {
      case this.rtPids[0]:
        {
          switch (+value)
          {
            default: this.status = false; break;
            case 1: this.status = true; break;
          }
        }
        break;
      case this.rtPids[1]:
        {
          this.online = !!+value;
        }
        break;
    }

    return this.getCurrentValue();
  }
};

class LowerCommandChannelData extends StatusData 
{
  public block = "Output";
  public onColorStatic = 62 - 15; // green
  public offColorStatic = 148 - 15; // grey
  public pidList = [
    "OutputLcdLowerLabel",
    "OutputLcdLowerLabel",
    "",
    "",
  ];
  public rtPids = [
    "OutputRelayState",
    "OutputState",
  ];
  /**
   * 
   * @param pid 
   * @param value 
   */
  public Update(pid: string, value: string): StatusValue
  {
    switch (pid)
    {
      case this.rtPids[0]:
        {
          switch (+value)
          {
            default: this.status = false; break;
            case 2: this.status = true; break;
          }
        }
        break;
      case this.rtPids[1]:
        {
          this.online = !!+value;
        }
        break;
    }

    return this.getCurrentValue();
  }
};

class MacroChannelData extends StatusData 
{
  public block = "Macro";
  public onColorStatic = 62 - 15; // green
  public offColorStatic = 132 - 15; // red
  public pidList = ["MacroName",
    "MacroName",
    "",
    "",
  ];
  public rtPids = [
    "MacroCurrentState",
  ];

  public Update(pid: string, value: string): StatusValue
  {
    if (pid == this.rtPids[0])
    {
      this.online = true;
      let pipe = new MacroStatusPipe();

      this.status = pipe.transform(+value) == "Running";
    }

    return this.getCurrentValue();
  }

};

class MeterLCChannelData extends StatusData 
{
  public block = "Analog";
  public onColorStatic = 0;
  public offColorStatic = 0;
  public pidList = [
    "AnalogName",
    "AnalogName",
    "AnalogLowCriticalColor",
    "AnalogNormalColor",
    "AnalogLowCriticalStatus",
  ];
  public rtPids = [
    "AnalogLowCriticalStatus",
  ];
};

class MeterLWChannelData extends StatusData 
{
  public block = "Analog";
  public onColorStatic = 0;
  public offColorStatic = 0;
  public pidList = [
    "AnalogName",
    "AnalogName",
    "AnalogLowWarningColor",
    "AnalogNormalColor",
  ];
  public rtPids = [
    "AnalogLowWarningStatus",
  ];
  // Pipe: null,
};

class MeterHWChannelData extends StatusData 
{
  public block = "Analog";
  public onColorStatic = 0;
  public offColorStatic = 0;
  public pidList = [
    "AnalogName",
    "AnalogName",
    "AnalogHighWarningColor",
    "AnalogNormalColor",
  ];
  public rtPids = [
    "AnalogHighWarningStatus",
  ];
};

class MeterHCChannelData extends StatusData 
{
  public block = "Analog";
  public onColorStatic = 0;
  public offColorStatic = 0;
  public pidList = [
    "AnalogName",
    "AnalogName",
    "AnalogHighCriticalColor",
    "AnalogNormalColor",
  ];
  public rtPids = [
    "AnalogHighCriticalStatus",
  ];
};

class MeterChannelData extends MeterOutOfToleranceData 
{
  public block = "Analog";
  public onColorStatic = 132 - 15; // red.  The colors look reverse because On means out of tolerance.
  public offColorStatic = 62 - 15; // green
  public pidList = [
    "AnalogName",
    "AnalogName",
    "",
    "",
  ];
  public rtPids = [
    "AnalogLowCriticalStatus",
    "AnalogLowWarningStatus",
    "AnalogHighWarningStatus",
    "AnalogHighCriticalStatus",
  ];
};

class SiteAlarmData extends StatusData 
{
  public block = "Site";
  public onColorStatic = 132 - 15; // red (>0 alarms)
  public offColorStatic = 62 - 15; // green (no alarms)
  public pidList = [
    "SiteName",
    "SiteName",
    "",
    "",
  ];
  public rtBlock = "AlarmStats";
  public rtPids = [
    "TotalAlarms",
  ];
  public Update(pid: string, value: string): StatusValue
  {
    switch (pid)
    {
      case this.rtPids[0]:
        {
          this.status = !!+value;
        }
        break;
    }

    return (this.status) ? StatusValue.STATUS_ON : StatusValue.STATUS_OFF;
  }
};

class SiteConnectedData extends StatusData 
{
  public block = "Site";
  public onColorStatic = 62 - 15; // green
  public offColorStatic = 132 - 15; // red
  public pidList = [
    "SiteName",
    "SiteName",
    "",
    "",
  ];
  public rtPids = [
    "SiteConnectionState",
  ];
};

class MaintChannelData extends StatusData 
{
  public block = "Site";
  public onColorStatic = 132 - 15; // red.  The colors look reverse because On in Maintenance Mode
  public offColorStatic = 62 - 15; // green
  public onMessage = "Maintenance Mode";
  public offMessage = "Remote Mode";
  public pidList = [
    "",
    "",
    "",
    "",
  ];
  public rtPids = [
    "SiteMaintenanceMode",
  ];
};

@Injectable({
  providedIn: 'root'
})
export class StatusConditionTypesService
{
  private status: ConditionTypes[] = [
    { value: 0, label: "Status Channel", channelType: ChannelType.Status, data: new StatusChannelData() },
    { value: 1, label: "Raise Relay Active", channelType: ChannelType.Command, data: new RaiseCommandChannelData() },
    { value: 2, label: "Lower Relay Active", channelType: ChannelType.Command, data: new LowerCommandChannelData() },
    { value: 3, label: "Macro Running", channelType: ChannelType.Macro, data: new MacroChannelData() },
    { value: 4, label: "Meter Below Low Critical", channelType: ChannelType.Meter, data: new MeterLCChannelData() },
    { value: 5, label: "Meter Below Low Warning", channelType: ChannelType.Meter, data: new MeterLWChannelData() },
    { value: 6, label: "Meter Above High Warning", channelType: ChannelType.Meter, data: new MeterHWChannelData() },
    { value: 7, label: "Meter Above High Critical", channelType: ChannelType.Meter, data: new MeterHCChannelData() },
    { value: 8, label: "Meter Out of Tolerance", channelType: ChannelType.Meter, data: new MeterChannelData() },
    { value: 9, label: "Site in Alarm", channelType: ChannelType.Site, data: new SiteAlarmData() },
    { value: 10, label: "Site Connected", channelType: ChannelType.Site, data: new SiteConnectedData() },
    { value: 11, label: "Site in Maintenance Mode", channelType: ChannelType.Site, data: new MaintChannelData() },
  ];

  constructor() { }

  get(): ConditionTypes[]
  {
    return this.status;
  }

  getStatusData(i: number): StatusData
  {
    switch (i)
    {
      default:
      case 0: return new StatusChannelData();
      case 1: return new RaiseCommandChannelData();
      case 2: return new LowerCommandChannelData();
      case 3: return new MacroChannelData();
      case 4: return new MeterLCChannelData();
      case 5: return new MeterLWChannelData();
      case 6: return new MeterHWChannelData();
      case 7: return new MeterHCChannelData();
      case 8: return new MeterChannelData();
      case 9: return new SiteAlarmData();
      case 10: return new SiteConnectedData();
      case 11: return new MaintChannelData();
    }
  }
}
