import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UniqueIdService
{
  private count = 0;

  constructor() { }

  /////////////////////////////////////////////////////////
  // get
  //
  // Get a unique id with the format "id_xxx", where xxx
  // is a monotomicly increasing number.
  /////////////////////////////////////////////////////////
  get(): string
  {
    this.count++;
    return "id_" + this.count;
  }
}
