import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, EventEmitter, Output, QueryList, ViewChildren, ViewChild, ElementRef, OnInit } from '@angular/core';
import { CustomViewWidgetData } from '../../custom-view-widget/custom-view-widget.component';
import { ClipboardService } from '../../services/clipboard.service';
import { EditWidgetComponent, ClickOnWidgetData } from '../edit-widget/edit-widget.component';

@Component({
  selector: 'app-live-grid',
  templateUrl: './live-grid.component.html',
  styleUrls: ['./live-grid.component.css'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ]),
  ]
})
export class LiveGridComponent implements OnInit
{
  @Input() Name: string;
  @Input() widgets: CustomViewWidgetData[] = [];
  @Input() MaxHeight: number;
  @Input() MaxWidth: number;
  @Input() canWrite: boolean;
  @Input() addInitialTile: boolean;

  @Output() maxHeightEmitter = new EventEmitter();
  @Output() maxWidthEmitter = new EventEmitter();
  @Output() saveEmitter = new EventEmitter();

  // this is a link into the HTML to access dynamic components.
  @ViewChildren(EditWidgetComponent) widgetComponents: QueryList<EditWidgetComponent>;
  @ViewChild('maxHeight', { static: false }) maxHeightElement: ElementRef;

  multiSelect: number[] = [];
  showMultiEditButton: boolean;

  showBackgroundForm: boolean = false;
  showEditTile: boolean = false;
  showAddTile: boolean = false;
  private showMultiEditDialog = false;
  private configurationList: CustomViewWidgetData[];

  editEvent: ClickOnWidgetData;
  editData: CustomViewWidgetData;

  canPaste: boolean = false;

  constructor(
    private clipboard: ClipboardService,
  ) { }

  ngOnInit(): void
  {
    this.clipboard.getClipboardType().subscribe(str =>
    {
      // we can paste if the clipboard has a TileArray.
      this.canPaste = str == "TileArray";
    });
  }

  /**
   * A tile was clicked on.
   * @param event 
   * @param i 
   */
  receiveWidgetClick(event: ClickOnWidgetData, i)
  {
    this.editEvent = event;
    this.onAddToSelectedItems(i);
  }

  IsMultiSelectSameType(): boolean
  {
    let allSameType = true;

    if (this.multiSelect.length < 2) return false;

    let type = this.widgets[this.multiSelect[0]].type;

    this.multiSelect.forEach(value =>
    {
      if (type != this.widgets[value].type)
      {
        allSameType = false;
      }
    });

    return allSameType;
  }

  onEdit(event)
  {
    this.editData = this.editEvent.data;
    this.showEditTile = true;

    event.stopPropagation();
  }

  onEditSave(data: CustomViewWidgetData)
  {
    this.widgets[this.multiSelect[0]] = data;
    this.onEditCancel();

    return false;
  }

  onEditCancel()
  {
    this.editData = null;
    this.showEditTile = false;

    this.multiSelect = []; // clear the multi-selection
    this.showMultiEditButton = false;

    return false;
  }

  //////////////////////////////////////////////////////////////////////////
  // onMultiEdit
  //
  // 
  //////////////////////////////////////////////////////////////////////////
  onMultiEdit(event)
  {
    // gather the list of configurations to edit.
    this.configurationList = [];
    this.multiSelect.forEach((value) =>
    {
      this.configurationList.push(this.widgets[value]);
    });

    // show the modal dialog.
    this.showMultiEditDialog = true;

    event.stopPropagation();
  }

  private showCutText = false;
  private showCopyText = false;
  private showPasteText = false;

  /**
   * Cut a tile to the clipboard.
   */
  onCutTile(event: Event)
  {
    this.onCut(this.multiSelect);

    if (event)
    {
      event.stopPropagation();
    }
  }

  /**
   * Make a copy of a widget to the clipboard
   */
  onCopyTile(event: Event)
  {
    this.onCopy(this.multiSelect);
    if (event)
    {
      event.stopPropagation();
    }
  }

  /**
   * Insert the tile in the clipboard into the widget array
   */
  onPasteTile(event)
  {
    this.onPaste(this.multiSelect[0]);

    if (event)
    {
      event.stopPropagation();
    }
  }

  onMultiEditDialogClose()
  {
    this.showMultiEditDialog = false;

    // modify the multiselect array so it redraws.
    this.multiSelect = [];
    this.multiSelect = this.multiSelect.concat(this.multiSelect);
  }

  // private MaxHeightStr;
  // private MaxWidthStr;
  // onMaxHeightChanged()
  // {
  //   sdf
  //   this.maxHeightEmitter.emit(this.MaxHeight);
  // }
  // onMaxWidthChanged()
  // {
  //   this.maxWidthEmitter.emit(this.MaxWidth);
  // }

  /**
   * Shift a tile to the left.
   */
  shiftLeft(event)
  {
    // remove the item
    let shiftedItem = this.widgets.splice(this.multiSelect[0], 1);
    // change the index of where it goes
    this.multiSelect[0]--;
    // put the item back in
    this.widgets.splice(this.multiSelect[0], 0, shiftedItem[0]);

    event.stopPropagation();
  }

  /**
   * Shift a tile to the right.
   */
  shiftRight(event)
  {
    let shiftedItem = this.widgets.splice(this.multiSelect[0], 1);
    this.multiSelect[0]++;
    this.widgets.splice(this.multiSelect[0], 0, shiftedItem[0]);

    event.stopPropagation();
  }

  /**
   * The Add New Tile Dialog emitted a set of new tiles.  Insert it into the widgets.
   * @param event { index: number, tiles: [configuration]}
   */
  onAddTiles(event)
  {
    this.widgets.splice(event.index, 0, ...event.tiles);
  }

  /**
   * The Add Tile at end emitted a set of tiles.  Add these at the end of the widgets array.
   * @param event {tiles: [configuration]}
   */
  onAddTilesAtEnd(event: any)
  {
    event.index = this.widgets.length;
    this.onAddTiles(event);
  }


  onMaxWidthHeightClose(event)
  {
    this.showBackgroundForm = false;
  }
  onNewWidth(event)
  {
    this.MaxWidth = event;
    this.maxWidthEmitter.emit(this.MaxWidth);
  }
  onNewHeight(event)
  {
    this.MaxHeight = event;
    this.maxHeightEmitter.emit(this.MaxHeight);
  }

  onClearSelection(event)
  {
    this.clearSelection();
    event.stopPropagation();
  }

  /**
   * Click outside the grid clears the selection
   */
  clearSelection()
  {
    this.multiSelect = []; // clear the multi-selection
    this.showMultiEditButton = false;

    return false;
  }

  /**
   * The user click the Add Tile button.  Show the Add Tile Dialog.
   */
  onAddTile()
  {
    this.showAddTile = true;
  }

  /**
   * The Add Tile Dialog wants to close.  Hide it.
   */
  onCloseAddTile()
  {
    this.showAddTile = false;
  }

  deleteTile(event)
  {
    this.removeTiles(this.multiSelect);

    this.multiSelect = [];

    event.stopPropagation();
  }

  removeTiles(i: number[])
  {
    i.sort((a, b) => a - b).reverse();

    i.forEach((v) =>
    {
      this.widgets.splice(v, 1);
    });
  }

  onSave(): void
  {
    this.saveEmitter.emit(true);
  }

  onCut(i: number[])
  {
    this.copyToClipboard(i);

    // remove the items
    this.removeTiles(i);

    this.clearSelection();

    this.showCutText = true;
    setTimeout(() => { this.showCutText = false; }, 1000);
  }

  onCopy(i: number[])
  {
    // get the list of items to save.
    this.copyToClipboard(i);

    this.showCopyText = true;
    setTimeout(() => { this.showCopyText = false; }, 1000);
  }

  copyToClipboard(i: number[])
  {
    const tilesToSave = [];
    i.forEach((v) =>
    {
      tilesToSave.push(this.widgets[v]);
    });

    // save the item to the clipboard
    this.clipboard.Save("TileArray", tilesToSave);
  }

  /**
   * Paste after the last tile.
   */
  onPasteLast()
  {
    this.onPaste(this.widgets.length);
  }

  /**
   * Paste the clipboard to the index
   * @param i index of where to insert the tiles.
   */
  onPaste(i: number)
  {
    // get the item from the clipboard
    let item = this.clipboard.Restore();

    switch (item.type)
    {
      case "TileArray":
        // if this is a Tile object, insert it into the widget array.
        item.obj.forEach((element, n) =>
        {
          this.widgets.splice(i + n, 0, element);
        });

        this.showPasteText = true;
        setTimeout(() => { this.showPasteText = false; }, 1000);
        break;
    }
  }

  onAddToSelectedItems(i: number)
  {
    let index = this.multiSelect.indexOf(i);
    // if this index is not in the list...
    if (index < 0)
    {
      // add it to the list
      this.multiSelect.push(i);
    }
    else
    {
      // remove the item
      this.multiSelect.splice(index, 1);
    }

    // sort the multiselect so the indexes are always in order.
    this.multiSelect.sort((a, b) => a - b);

    this.showMultiEditButton = this.IsMultiSelectSameType();
  }
}
