import { Component } from '@angular/core';
import { CustomViewWidgetComponent, CustomViewWidgetData, RealTimeUpdate } from '../custom-view-widget.component';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { DataOneShotService } from '../../services/data-one-shot.service';
import { SiteConfiguration, SiteWidgetComponent } from '../site-widget/site-widget.component';

export class Configuration extends SiteConfiguration
{
  channel: number;
}

@Component({
  selector: 'app-timer-widget',
  templateUrl: './timer-widget.component.html',
  styleUrls: ['./timer-widget.component.css']
})
export class TimerWidgetComponent extends SiteWidgetComponent implements RealTimeUpdate
{
  private channel: number;
  private name: string;
  private value: number;
  private isAllowed: boolean;

  /**
   * 
   * @param ds 
   * @param oneshot 
   */
  constructor(protected ds?: DataSubscriptionService,
    protected oneshot?: DataOneShotService) 
  {
    super(ds, oneshot);
  }
  static GetType(): string { return "Timer"; }

  /**
   * Configure the tile
   * 
   * @param obj the configuration
   */
  Configure(obj: Configuration): CustomViewWidgetComponent
  {
    super.Configure(obj);

    this.channel = obj.channel;

    this.GetData();

    return this;
  }

  /**
   * Get the One-Shot Data
   */
  GetData()
  {
    this.oneshot.getPermission(this.site, "Timer", this.channel).subscribe((b) =>
    {
      this.isAllowed = b;
      if (!this.isAllowed)
      {
        return;
      }

      this.oneshot.getPidParameters(this.site, "Timer", this.channel,
        [
          "TimerName",
        ])
        .subscribe((res) =>
        {
          for (let p of res)
          {
            switch (p.parameter)
            {
              case "TimerName":
                if (p.value != null)
                {
                  p.value = p.value.replace(/\0/g, '').trim();
                }
                this.name = p.value;
                break;
            }
          }
        });
    });
  }

  /**
   * Subscribe to the timer data.
   */
  protected Subscribe()
  {
    super.Subscribe();

    this.ds.Add(this, [
      {
        "site": this.site,
        "block": "Timer",
        "channel": this.channel,
        "pid": "TimerTotalSeconds"
      }]);
  }

  /**
   * 
   * @param site 
   * @param pid 
   * @param channel 
   * @param value 
   */
  Update(site: string, pid: string, channel: number, value: string)
  {
    super.Update(site, pid, channel, value);

    if (pid == "TimerTotalSeconds")
    {
      this.value = +value;
    }
  }

}
