import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relayActive'
})
export class RelayActivePipe implements PipeTransform
{
  /////////////////////////////////////////////////////////////////
  // transform
  //
  // Take in 0, 1, or 2 and 'Raise' or 'Lower', output 'bold' or 'normal'.
  /////////////////////////////////////////////////////////////////
  transform(value: number, args: string): string
  {
    switch (args)
    {
      // if we are looking for raise and the relay state is Raise (1), then bold.
      case 'Raise':
        return (value == 1) ? 'bold' : 'normal';
      // if we are looking for lower and the relay state is Lower (2), then bold.
      case 'Lower':
        return (value == 2) ? 'bold' : 'normal';
      default:
        return 'normal';
    }
  }
}