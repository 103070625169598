import { Component, Input, OnChanges, Output, SimpleChanges, ElementRef } from '@angular/core';
import { DataOneShotService, PidOneShot, PidUpdate } from '../services/data-one-shot.service';
import { Subject } from 'rxjs';
import { MonotonicService } from '../services/monotonic.service';

class PidData
{
  pid: string;
  label: string;
}
class ChannelData
{
  channel: number;
  pids: PidData[];
  visible: boolean;
}

class BlockParam
{
  block: string;
  param: string;
  constructor(b: string, p: string)
  {
    this.block = b;
    this.param = p;
  }
}

// TODO: ChannelSelectorComponent should probably be broken into the N different classes.
// TODO: e.g. MeterSelectorComponent, StatusSelectorComponent, etc.
// TODO: each one would implement the abstract getChannelPids().
@Component({
  selector: 'app-channel-selector',
  templateUrl: './channel-selector.component.html',
  styleUrls: ['./channel-selector.component.css']
})
export class ChannelSelectorComponent implements OnChanges
{
  @Input() site: string;
  @Input() channelType: string;
  @Input() multiselect: boolean;
  @Input() channelSelect: number;

  @Output() selectedChannels: Subject<number[]> = new Subject<number[]>();

  private internalSelectedChannels: number[] = [];
  private isChecked: boolean[] = [];
  private channels: ChannelData[];
  private series: number;

  ////////////////////////////////////////////////////////
  // constructor
  ////////////////////////////////////////////////////////
  constructor(
    private oneShot: DataOneShotService,
    monotonic: MonotonicService,
    private elRef: ElementRef)
  {
    this.series = monotonic.get();
  }

  ////////////////////////////////////////////////////////
  // ngOnChanges
  //
  // if either the site or channelType changes, load the 
  // channels.
  ////////////////////////////////////////////////////////
  ngOnChanges(changes: SimpleChanges)
  {
    if (changes.site != null ||
      changes.channelType != null)
    {
      this.LoadChannels();
    }
    // 02/13/2023 DJC - NOTE: multiselect is a property of the base ChannelAdaptorComponent;
    // this property is set in channel-selector.component.html and is used to control the
    // channel list control type:
    // multiselect == true -> checkbox
    // multiselect == false -> radio
    // The following code should NOT be executed in the multiselect/checkbox usage scenario;
    // Strip Chart tile type channel selection = radio / !multiselect
    // All other instances (?) of channel selection = checkbox / multiselect
    if (!this.multiselect)
    {
      if (changes.channelSelect != null &&
        this.channels != null)
      {
        for (let chan of this.channels)
        {
          if (chan.channel == this.channelSelect)
          {
            this.onRadioChanged(chan);
          }
        }
      }
    }
  }

  ////////////////////////////////////////////////////////
  // LoadChannels
  //
  // Based on the site and channelType, get the PIDs to
  // describe the channels.
  ////////////////////////////////////////////////////////
  private LoadChannels()
  {
    // if we don't have the site, return.
    if (this.site == null) return;

    // clear out the list of channels from a previous load
    this.channels = [];

    // pids is the list of PIDs for the channels.
    // some channels will need multiple PIDs.
    let pids: PidOneShot[] = [];

    this.oneShot.getNumberOfChannels(this.site, this.channelType).subscribe((maxChannels) =>
    {

      // loop through all the channels...
      for (let i = 0; i < maxChannels; i++)
      {
        let channelData = new ChannelData();
        channelData.channel = i;
        channelData.pids = [];

        // get a list of the PIDs needed for each channel
        // TODO: this call to getChannelPids can be factored out.  The list parameters does not change for each channel
        for (let blockParam of this.getChannelPids())
        {
          let pid = new PidOneShot();

          pid.site = this.site;
          pid.channel = i;
          pid.block = blockParam.block;
          pid.parameter = blockParam.param;

          pids.push(pid);

          let data = new PidData();
          data.pid = blockParam.param;

          channelData.pids.push(data)
        }

        this.channels.push(channelData)
      }

      const slice_size = 16;
      for (let i = 0; i < pids.length; i += slice_size)
      {
        let slice = pids.slice(i, i + slice_size);

        // every 16 pids, send the request.  If we do all of them, there is an overflow error:
        // "The request filtering module is configured to deny a request where the query string is too long."
        this.oneShot.getPidsArray(slice).subscribe((obs) =>
        {
          // loop for the pid values returned...
          for (let p of obs)
          {
            this.UpdateChannelData(p);
          }
          // if we have a current channel, scroll it into view.
          let el = this.elRef.nativeElement.querySelector('#currentChannel');
          if (el != null)
          {
            el.scrollIntoView();
          }
        });
      }
    });
  }

  ////////////////////////////////////////////////////////
  // UpdateChannelData
  //
  // Find the channel that this PidUpdate belongs to and
  // update the parameter of that channel.
  ////////////////////////////////////////////////////////
  private UpdateChannelData(p: PidUpdate)
  {
    // find the channel this goes to...
    for (let chan of this.channels)
    {
      if (chan.channel == p.channel)
      {
        // find the pid data set that this pid value goes to...
        for (let pid of chan.pids)
        {
          if (pid.pid == p.parameter)
          {
            pid.label = p.value; // found it.

            // if one of the pids returns a valid string, then the channel is visible.
            if (p.value.length > 0)
            {
              chan.visible = true;
            }
            return;
          }
        }
      }
    }
  }

  ////////////////////////////////////////////////////////
  // getChannelPids
  //
  // Return an array of {Block, Param} that defines the 
  // data that is needed for each type of channel.
  ////////////////////////////////////////////////////////
  private getChannelPids(): BlockParam[]
  {
    let result: BlockParam[] = [];
    switch (this.channelType)
    {
      case "Meter":
        result.push(new BlockParam("Analog", "AnalogName"));
        break;

      case "Status":
        result.push(new BlockParam("Status", "StatusOnMessage"));
        result.push(new BlockParam("Status", "StatusOffMessage"));
        break;

      case "Command":
        result.push(new BlockParam("Output", "OutputLcdLowerLabel"));
        result.push(new BlockParam("Output", "OutputLcdRaiseLabel"));
        break;

      case "Raise":
        result.push(new BlockParam("Output", "OutputLcdRaiseLabel"));
        break;

      case "Lower":
        result.push(new BlockParam("Output", "OutputLcdLowerLabel"));
        break;

      case "Macro":
        result.push(new BlockParam("Macro", "MacroName"));
        break;

      case "Timer":
        result.push(new BlockParam("Timer", "TimerName"));
        break;

      case "MeterStatusCommand":
        result.push(new BlockParam("Analog", "AnalogName"));
        result.push(new BlockParam("Status", "StatusOnMessage"));
        result.push(new BlockParam("Status", "StatusOffMessage"));
        result.push(new BlockParam("Output", "OutputLcdLowerLabel"));
        result.push(new BlockParam("Output", "OutputLcdRaiseLabel"));
        break;
    }

    return result;
  }

  ////////////////////////////////////////////////////////
  // onCheckboxChanged
  //
  // Update selectedChannels with the new set of selected channels.
  ////////////////////////////////////////////////////////
  onCheckboxChanged(pid, onOff)
  {
    // if the checkbox is now checked...
    if (onOff)
    {
      // add this channel to the sorted list.
      this.internalSelectedChannels.push(pid.channel);
      this.internalSelectedChannels.sort((a, b) => { return a - b; });
    }
    else // the checkbox is now unchecked...
    {
      // https://stackoverflow.com/a/5767357/9516
      // Find the channel in the sorted list.
      let index = this.internalSelectedChannels.indexOf(pid.channel);
      // if it is found...
      if (index !== -1)
      {
        // splice out this channel
        this.internalSelectedChannels.splice(index, 1);
      }
    }

    // Output the sorted list of selected channels.
    this.selectedChannels.next(this.internalSelectedChannels);
  }

  ////////////////////////////////////////////////////////
  // onRadioChanged
  ////////////////////////////////////////////////////////
  onRadioChanged(pid) {
    // 02/13/2023 DJC - NOTE: multiselect is a property of the base ChannelAdaptorComponent;
    // this property is set in channel-selector.component.html and is used to control the
    // channel list control type:
    // multiselect == true -> checkbox
    // multiselect == false -> radio
    // The following code should NOT be executed in the multiselect/checkbox usage scenario;
    // Strip Chart tile type channel selection = radio / !multiselect
    // All other instances (?) of channel selection = checkbox / multiselect
    if (!this.multiselect)
    {
      // Output the list of 1 channel.
      this.selectedChannels.next([pid.channel]);
    }
  }
}
