import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatrixData } from '../services/group-panel.service';

@Component({
  selector: 'app-edit-custom-view-modal',
  templateUrl: './edit-custom-view-modal.component.html',
  styleUrls: ['./edit-custom-view-modal.component.css']
})
export class EditCustomViewModalComponent implements OnInit, OnChanges
{

  @Input() inputNode: MatrixData;

  @Output() save = new EventEmitter<MatrixData>();
  @Output() close = new EventEmitter();

  newNode: MatrixData;

  constructor() { }

  ngOnInit()
  {
    // make a deep clone of the inputNode
    if (this.inputNode != null)
    {
      this.newNode = JSON.parse(JSON.stringify(this.inputNode));
    }
    else
    {
      this.newNode = new MatrixData();
    }
  }

  ngOnChanges()
  {
  }

  onMaxHeightChanged(newMaxHeight) 
  {
    this.newNode.view.maxheight = newMaxHeight;
  }

  onMaxWidthChanged(newMaxWidth) 
  {
    this.newNode.view.maxwidth = newMaxWidth;
  }

  onClose(ok: boolean)
  {
    // if we click the OK button...
    if (ok || confirm("Do you want to save before leaving?"))
    {
      if (this.newNode.name.length == 0)
      {
        alert("The name cannot be empty.");
        return;
      }

      this.save.emit(this.newNode);
    }

    this.close.emit();
  }

}
