import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class SiteData
{
  alias: number;
  name: string;
}


export class CommandConfirmData
{
  commandConfirm: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class SitesService
{

  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string) { }

  ///////////////////////////////////////////////////////////////////
  // getSites
  //
  // get all sites
  ///////////////////////////////////////////////////////////////////
  getSites(): Observable<SiteData[]>
  {
    // need to implement a caching service:
    // https://fullstack-developer.academy/caching-http-requests-with-angular/
    return this.http.get<SiteData[]>(this.baseUrl + 'api/AllSites').pipe(map((value: SiteData[], index: number): SiteData[] =>
    {
      // clean the values so they don't have null characters
      for (let p of value)
      {
        if (p.name != null)
        {
          p.name = p.name.replace(/\0/g, '');
        }
      }
      return value;
    }),
      // sort the units so they are always in alpha order
      map(arr => arr.sort((a, b) => a.name.localeCompare(b.name))));
  }

  /**
   * Get the command confirmation flag for the All Sites tab for this user.
   * @returns 
   */
  getAllSiteCommandConfirm(): Observable<CommandConfirmData>
  {
    return this.http.get<CommandConfirmData>(this.baseUrl + 'api/AllSites/CommandConfirm');
  }

  /**
   * Set teh command confirmation flag for the All Sites tab for this user.
   * @param b 
   * @returns 
   */
  setAllSiteCommandConfirm(b: boolean)
  {
    let obj: any = {};

    obj.commandConfirm = b;

    return this.http.post<CommandConfirmData>(this.baseUrl + 'api/AllSites/CommandConfirm', obj);
  }
}
