import { Component, OnInit, Inject, ComponentFactoryResolver, ViewChild, ViewContainerRef, Type, ComponentRef, OnDestroy } from '@angular/core';
import { CustomViewWidgetComponent, CustomViewWidgetData } from './custom-view-widget.component';
import { TileSize, FullWidth_DoubleHeight } from '../size-selector/size-selector.component';
import { AfterViewInit } from '@angular/core';

/**
 * Base class for all Property Components.  Pass in the type and the Factory Resolver.
 * This will handle creating the component and handling any changes.
 */
@Component({
  template: ''
})
export class CustomViewPropertyComponent<T extends CustomViewWidgetComponent> implements OnInit, AfterViewInit, OnDestroy
{
  protected myConfiguration: any;
  public multiselect: boolean = false; // select multiple channels to add
  public multiEdit: boolean = false; // select multiple tiles; only show common parameters
  protected currentTileIndex: number;
  private initialFontSize: number;

  @ViewChild('tile', {
    read: ViewContainerRef,
    static: true
  }) viewContainerRef: ViewContainerRef;
  private component: ComponentRef<T>;

  constructor(
    private componentType: new () => T,
    private factoryResolver: ComponentFactoryResolver,
  ) { }

  /////////////////////////////////////////////////
  // Configure
  // 
  // Configure this property grid.  Save a copy of the configuration
  /////////////////////////////////////////////////
  Configure(obj: CustomViewWidgetData)
  {
    this.myConfiguration = obj;

    if (obj.width == null)
    {
      this.myConfiguration.width = this.sizes[this.GetDefaultSize()].width;
    }

    if (obj.height == null)
    {
      this.myConfiguration.height = this.sizes[this.GetDefaultSize()].height;
    }
    this.getCurrentTileSize();

    this.myConfiguration.backgroundColor = obj.backgroundColor;
    this.myConfiguration.fullWidth = obj.fullWidth;

    this.initialFontSize = this.myConfiguration.fontSize;
  }

  /**
   * After the property component is constructed, add the component .
   */
  ngOnInit()
  {
    this.createComponent();
  }

  ngAfterViewInit()
  {
    // The template calculates the font size based on the tile size.  When editing an existing tile, keep the initial font size.
    if (this.initialFontSize != null)
    {
      this.myConfiguration.fontSize = this.initialFontSize;

      // this.cdRef.detectChanges();
      this.onChange();
    }
  }
  /**
   * When this component is destroyed, call this.
   */
  ngOnDestroy()
  {
  }

  //////////////////////////////////////////////////////////////////////////////
  // getConfiguration
  //
  // Get an array of the configuration of each tile. If a component can be 
  // configured for multiple channels at once, then the specific property grid
  // will overrride this to expand the myConfiguration to an array with different
  // channels.
  /////////////////////////////////////////////////
  getConfigurationArray(): CustomViewWidgetData[]
  {
    return [this.myConfiguration];
  }

  //////////////////////////////////////////////////////////////////////////
  // createComponent
  //////////////////////////////////////////////////////////////////////////
  createComponent(): void
  {
    // create a factory to create the component
    const factory = this.factoryResolver.resolveComponentFactory<T>(this.componentType);

    // create the component.
    this.component = factory.create(this.viewContainerRef.parentInjector);
    //this.component = this.viewContainerRef.createComponent(this.componentType); // for angular 13+
    // https://stackoverflow.com/a/70947152/9516

    // clear the view container
    this.viewContainerRef.clear();

    // insert the new componenet.
    this.viewContainerRef.insert(this.component.hostView);

    // Configure the componenet.
    this.component.instance.Configure(this.myConfiguration);

    // we are editing the component.  Don't allow clicks to do actions.
    this.component.instance.PreventClicks();
  }

  GetComponent(): any
  {
    return this.componentType;
  }

  /**
   * A property changed.  Reconfigure the component.
   */
  onChange(): void
  {
    this.component.instance.Configure(this.myConfiguration);

    this.component.instance.StartSubscription();
  }

  /**
   * given the configured width and height, get the tile size index that matches. 
   */
  private getCurrentTileSize(): void
  {
    let tileSizes = this.sizes;
    tileSizes.forEach((d, i) =>
    {
      if (d.width == this.myConfiguration.width &&
        d.height == this.myConfiguration.height)
      {
        this.currentTileIndex = i;
      }
    });
  }

  /**
   * Get a list of valid sizes.
   */
  readonly sizes: TileSize[] = [FullWidth_DoubleHeight];
  GetDefaultSize(): number { return 0; }

  /**
   * a new size has been selected.
   * @param $event 
   */
  onNewSizeSelected($event: TileSize)
  {
    this.myConfiguration.width = $event.width;
    this.myConfiguration.height = $event.height;
    this.getCurrentTileSize();

    if (this.myConfiguration.height >= 310)
    {
      this.myConfiguration.fontSize = 65;
    }
    else if (this.myConfiguration.height >= 150)
    {
      this.myConfiguration.fontSize = 40;
    }
    else if (this.myConfiguration.height >= 70)
    {
      this.myConfiguration.fontSize = 20;
    }
    else
    {
      this.myConfiguration.fontSize = 14;
    }

    this.onChange();
  }

}
