import { Component, OnInit } from '@angular/core';
import { EditModeService } from '../services/edit-mode.service';
import { AccountService } from '../services/account.service';
import { DataSubscriptionService } from '../services/data-subscription.service';
import { CheckConnectionsService } from '../services/check-connections.service';
import { TabChangedService } from '../services/tab-changed.service';
import { auditTime } from 'rxjs/operators';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit
{
  private username: string = "";
  private loggedIn: boolean = false;;
  private wsState: string = "initializing";
  private webServerState: boolean = true;
  private arcadiaServerState: boolean = true;
  private tabChanged: boolean = false;

  editMode: boolean = false;

  constructor(
    private editModeService: EditModeService,
    private ds: DataSubscriptionService,
    private ccs: CheckConnectionsService,
    private tabService: TabChangedService,
  )
  {
    this.ds.AccountService.user.subscribe(user =>
    {
      this.loggedIn = (user && user.username) ? true : false;
      this.username = (user) ? user.username : "";
    });

    // debounce the web socket events by 1 second so a fast reconnect from the refresh token does not show.
    ds.WebSocketEvents.pipe(auditTime(1000)).subscribe((str) =>
    {
      this.wsState = str;
      // console.log("Nav: ws state: " + str);
    });

    tabService.globalChanged.subscribe(() => this.showTabsChanged());
    tabService.userChanged.subscribe(() => this.showTabsChanged());
  }

  showTabsChanged()
  {
    this.tabChanged = true;
  }

  onClickTabsChanged()
  {
    window.location.reload();
  }

  ngOnInit(): void
  {
    // create an interval to refresh the token every 5 minutes.
    // If the 5 minutes changes, update LoginController.AccessTokenLifetime.
    setInterval(() => this.ds.AccountService.refreshToken().subscribe(), 5 * 60 * 1000);

    // every 30 seconds, check for the Web Server connected to Redis.
    setInterval(() =>
    {
      this.ccs.isWebServerConnected().subscribe(
        (b) =>
        {
          this.webServerState = b;
        },
        (err) =>
        {
          this.webServerState = false;
        });

      this.ccs.IsArcadiaServerConnected().subscribe(
        (b) =>
        {
          this.arcadiaServerState = b;
        },
        (err) =>
        {
          this.arcadiaServerState = false;
        });
    }, 30 * 1000);

    this.editModeService.get().subscribe((value) =>
    {
      this.editMode = value;
    });
  }

  ToggleEditMode()
  {
    let b = this.editModeService.get().getValue();
    this.editModeService.get().next(!b);
  }

  LogOut()
  {
    this.ds.AccountService.logout().subscribe(() =>
    {
      document.location.href = "/login";
    });
  }
}
