import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EditModeService
{

  private editMode = new BehaviorSubject(false);

  constructor() { }

  get()
  {
    return this.editMode;
  }
}
