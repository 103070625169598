import { Component, OnInit, Inject, ComponentFactoryResolver } from '@angular/core';
import { CustomViewPropertyComponent } from '../../custom-view-property.component';
import { MeterStatusCommandFullRowHeaderComponent } from '../meter-status-command-full-row-header.component';
import { CustomViewWidgetData } from '../../custom-view-widget.component';

@Component({
  selector: 'app-meter-status-command-full-row-header-property',
  templateUrl: './meter-status-command-full-row-header-property.component.html',
  styleUrls: ['./meter-status-command-full-row-header-property.component.css']
})
export class MeterStatusCommandFullRowHeaderPropertyComponent extends CustomViewPropertyComponent<MeterStatusCommandFullRowHeaderComponent>
{
  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(MeterStatusCommandFullRowHeaderComponent, factoryResolver);

    let cfg = new CustomViewWidgetData();

    cfg.type = MeterStatusCommandFullRowHeaderComponent.GetType();
    cfg.fullWidth = true;

    this.Configure(cfg);
  }

}