import { Component, Inject, ComponentFactoryResolver } from '@angular/core';
import { CustomViewPropertyComponent } from '../../custom-view-property.component';
import { AngularMeterWidgetComponent } from '../angular-meter-widget.component';
import { Configuration } from '../angular-meter-widget.component';
import { TileSize, DoubleWide_SingleHeight, FourxWide_DoubleHeight, EightxWide_FourxHeight, SingleWide_HalfHeight } from '../../../size-selector/size-selector.component';
import { SitePropertyComponent } from '../../site-property/site-property.component';
import { ChannelPropertyComponent } from '../../channel-property/channel-property.component';
import { SizeSelectorComponent } from '../../../size-selector/size-selector.component';

@Component({
  selector: 'app-angular-meter-property',
  templateUrl: './angular-meter-property.component.html',
  styleUrls: ['./angular-meter-property.component.css']
})
export class AngularMeterPropertyComponent extends ChannelPropertyComponent<AngularMeterWidgetComponent>
{
  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
  )
  {
    super(AngularMeterWidgetComponent, factoryResolver);

    let cfg = new Configuration();

    cfg.type = AngularMeterWidgetComponent.GetType();

    this.Configure(cfg);
  }

  Configure(obj: Configuration)
  {
    super.Configure(obj);

    if (this.multiselect)
    {
      this.multiselect = this.myConfiguration.channel == null;
    }
  }

  /**
   * Return the valid sizes of this type of Tile
   */
  readonly sizes: TileSize[] =
    [
      SingleWide_HalfHeight,
      DoubleWide_SingleHeight,
      FourxWide_DoubleHeight,
      EightxWide_FourxHeight
    ];


  /**
   * Return the index of the array of sizes returned from GetSizes() that is 
   * the default size.
   */
  GetDefaultSize(): number { return 1; }

  /**
   * Override the onNewSizeSelected to set specific sizes.
   * @param $event 
   */
  onNewSizeSelected($event: TileSize)
  {
    super.onNewSizeSelected($event);

    if (this.myConfiguration.height >= 310)
    {
      this.myConfiguration.fontSize = 45;
    }
    else if (this.myConfiguration.height >= 150)
    {
      this.myConfiguration.fontSize = 24;
    }
    else if (this.myConfiguration.height >= 70)
    {
      this.myConfiguration.fontSize = 12;
    }
    else
    {
      this.myConfiguration.fontSize = 5;
    }

    this.onChange();
  }

}
