import { CustomViewWidgetData } from "../custom-view-widget.component";

export class Configuration extends CustomViewWidgetData
{

	label: string;
	fontSize: number;

	public timePeriod: number; // number of seconds

	public gridLineColor: string = "black";
	public gridDisplay: boolean = true;
	public maxTicksLimit: number = 5;

	public lines: LineConfiguration[] = [];
}

export class LineConfiguration
{
	// public enable = true;
	public unitId: string;
	public channelType: string = "Meter";
	public channel: number = 0;
	public color: string = "red";
	public label: string = "Label";

	// y-axis Config
	public yAxisPosition: string = "left";
	public autoScaleYAxis: boolean = true;
	public yAxisMin: number;
	public yAxisMax: number;

	// y-axis label
	public labelString: string = "";

	constructor(enable: boolean)
	{
		// this.enable = enable;
	}
}
