import { Component, OnInit, Input, Inject, OnChanges } from '@angular/core';
import { SiteData } from '../services/sites.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * This is a div that will live in a modal dialog.
 * It takes a list of site IDs and booleans and shows 
 * a list of checkboxes.
 * If the user clicks OK, the site IDs and checked values
 * are returned as the modal results.
 * If the user clicks Cancel, the dialog closes without results.
 */

export class SiteCheckbox
{
  public data: SiteData;
  public checked: boolean;
}

class ModalData
{
  allSites: SiteData[];
  checkedSites: SiteData[];
}

@Component({
  selector: 'app-site-selector-dialog',
  templateUrl: './site-selector-dialog.component.html',
  styleUrls: ['./site-selector-dialog.component.css']
})
export class SiteSelectorDialogComponent
{
  allSites: SiteData[] = [];
  checkedSites: SiteData[] = [];

  checkedData: SiteCheckbox[] = [];

  constructor(
    public dialogRef: MatDialogRef<SiteSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  )
  {
    this.allSites = data.allSites;
    this.checkedSites = data.checkedSites;
    this.BuildCheckedData();
  }

  BuildCheckedData()
  {
    if (this.allSites == null ||
      this.checkedSites == null)
      return;

    // loop through the sites
    this.allSites.forEach((element) =>
    {
      let c = new SiteCheckbox();
      c.data = element;

      // loop through the checked sites...
      this.checkedSites.forEach(element =>
      {
        if (element.alias == c.data.alias)
        {
          c.checked = true;
        }
      });

      this.checkedData.push(c);
    });
  }

  /////////////////////////////////////////////////////
  // onCancel
  /////////////////////////////////////////////////////
  onCancel()
  {
    this.dialogRef.close();
  }

}
