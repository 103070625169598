import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckConnectionsService
{
  constructor(private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string)
  {
  }

  /**
   * Try to get the state of the web server connected.
   * Timeout after 2 seconds, which will cause an error in the observable.
   * @returns 
   */
  isWebServerConnected(): Observable<boolean>
  {
    return this.http.get<boolean>(this.baseUrl + 'api/CheckConnections/IsWebServerConnected').pipe(timeout(2000));
  }

  IsArcadiaServerConnected(): Observable<boolean>
  {
    return this.http.get<boolean>(this.baseUrl + 'api/CheckConnections/IsArcadiaServerConnected');
  }
}
