import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-multi-edit-modal',
  templateUrl: './multi-edit-modal.component.html',
  styleUrls: [
    './multi-edit-modal.component.css',
    '../LightBox.css',
  ]
})
export class MultiEditModalComponent implements OnInit
{
  @Input() configurationList;
  @Output() close = new EventEmitter();

  constructor() { }

  ngOnInit()
  {
  }

  onSaveAndClose()
  {

  }

  onClose()
  {
    this.close.emit();
  }

}
