import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { AccountService } from '../services/account.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit
{
  form: UntypedFormGroup;
  loading = false; // true will disable the submit button so it can only be pressed once.
  submitted = false; // true will show any invalid form fields.
  returnUrl: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private accountService: AccountService,
  ) { }

  ngOnInit()
  {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    // if the route has a query param of returnUrl, then remember it.  Otherwise set returnUrl to '/'.
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() { return this.form.controls; }

  onSubmit()
  {
    this.submitted = true;

    this.alertService.clear();

    // if the form is invalid, stop here.
    if (this.form.invalid)
    {
      return;
    }

    this.loading = true; // signal the submission is being sent.
    this.accountService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data =>
        {
          // if the login is successful, navigate to the URL
          this.router.navigate([this.returnUrl]);
        },
        error =>
        {
          // send the error to the alert service to show to the user.
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
