import { Component, OnInit } from '@angular/core';
import { CustomViewWidgetComponent, CustomViewWidgetData, RealTimeUpdate } from '../custom-view-widget.component';
import { DataSubscriptionService } from '../../services/data-subscription.service';
import { DataOneShotService } from '../../services/data-one-shot.service';

export class SiteConfiguration extends CustomViewWidgetData
{
  site: string;
}

/**
 * Partial Component to handle getting the SiteConnectionState.
 */
@Component({
  selector: 'app-site-widget',
  template: '',
})
export class SiteWidgetComponent extends CustomViewWidgetComponent implements RealTimeUpdate
{
  protected site: string;
  protected siteName: string;
  protected online: boolean;
  private tcpConnected: boolean;
  private warpConnected: boolean;

  constructor(protected ds?: DataSubscriptionService,
    protected oneshot?: DataOneShotService)
  {
    super(ds);
  }

  Configure(obj: SiteConfiguration): CustomViewWidgetComponent
  {
    super.Configure(obj);

    this.site = obj.site;

    this.GetLocalData();
    return this;
  }

  private GetLocalData(): void
  {
    this.oneshot.getPidParameters(this.site, "Site", 0,
      [
        "SiteName",
      ])
      .subscribe((res) =>
      {
        for (let p of res)
        {
          //console.log(p.parameter + ": " + p.value);
          switch (p.parameter)
          {
            case "SiteName":
              this.siteName = p.value;
              break;
          }
        }
      });

  }
  /**
   * Subscribe to the SiteConnectionState
   */
  protected Subscribe()
  {
    super.Subscribe();

    this.ds.Add(this, [
      {
        "site": this.site,
        "block": "Expires",
        "channel": 0,
        "pid": "SiteState"
      },
      // need the warp engine state otherwise it goes offline when downloading alarms on warp engine.
      {
        "site": this.site,
        "block": "WarpEngine",
        "channel": 0,
        "pid": "WarpEngineGood"
      },
    ]);
  }

  Update(site: string, pid: string, channel: number, value: string)
  {
    super.Update(site, pid, channel, value);

    if (this.site == site)
    {
      switch (pid)
      {
        case "SiteState":
          //console.log("SiteState: " + this.site + ": " + value);
          this.tcpConnected = (+value > 0);
          break;

        case "WarpEngineGood":
          this.warpConnected = (+value > 0);
          break;
      }

      // online if either tcp connected or warp engine connected.
      this.online = this.tcpConnected || this.warpConnected;
    }
  }

  /**
   * Get the channel offline message for a status channel.
   * @param a status on label
   * @param b status off label
   * @param channel channel number
   * @returns "Channel Offline: {sitename}: {a}" OR "Channel Offline: {sitename}: {b}" OR "Channel Offline: {sitename}: Status Channel {channel}"" 
   */
  protected calcStatusChannelOfflineMsg(a: string, b: string, channel: number): string
  {
    if (typeof a != 'undefined' && a.trim())
    {
      return "Channel Offline: " + this.siteName + ': ' + a;
    }
    else
    {
      if (typeof b != 'undefined' && b.trim())
      {
        // both off string is valid
        return "Channel Offline: " + this.siteName + ': ' + b;
      }
      else
      {
        // neither are valid
        return "Channel Offline: " + this.siteName + ': Status Channel ' + (channel + 1);
      }
    }

  }

  /**
   * Get the channel offline message for a status channel.
   * @param a raise label
   * @param b lower label
   * @param channel channel number
   * @returns "Channel Offline: {sitename}: {a}" OR "Channel Offline: {sitename}: {b}" OR "Channel Offline: {sitename}: Command Channel {channel}"" 
   */
  protected calcCommandChannelOfflineMsg(a: string, b: string, channel: number): string
  {
    if (typeof a != 'undefined' && a.trim())
    {
      return "Channel Offline: " + this.siteName + ': ' + a;
    }
    else
    {
      if (typeof b != 'undefined' && b.trim())
      {
        // both off string is valid
        return "Channel Offline: " + this.siteName + ': ' + b;
      }
      else
      {
        // neither are valid
        return "Channel Offline: " + this.siteName + ': Command Channel ' + (channel + 1);
      }
    }

  }

}