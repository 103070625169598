import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'validDate'
})
export class ValidDatePipe implements PipeTransform
{

  transform(value: string): Date
  {
    if (new Date(value) < new Date("2000-01-01"))
    {
      return null;
    }
    if (new Date(value) > new Date("2100-01-01"))
    {
      return null;
    }
    return new Date(value);
  }

}
